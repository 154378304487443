import React, { useState, useContext, useEffect } from "react";
import { View, Text, RadioGroupField, Radio } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { ProfileDataContext } from "../../contexts/contexts";
import { Icons } from "../../assets/icons";

export function LocationSettings() {
  const navigate = useNavigate();

  const { setProfileSettingsValues, profileSettingsValues } =
    useContext(ProfileDataContext);

  const [currentLocation, setCurrentLocation] = useState(
    profileSettingsValues.location
  );

  function handleApplyLocation(value) {
    setCurrentLocation(value);
    setProfileSettingsValues((prevData) => ({
      ...prevData,
      location: value,
    }));
    navigate("/profil/innstillinger");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <View height="100vh">
      {/* header */}
      <View
        height="32px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        width="100%"
      />
      <View
        position="fixed"
        top="32px"
        width="100%"
        display="flex"
        direction="row"
      >
        <View
          height="48px"
          backgroundColor="#F3F7F7"
          width="100%"
          direction="row"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <Text textAlign={"center"} color={"#29666A"} fontSize="16px">
            Velg ditt bosted
          </Text>

          <img
            onClick={() => navigate("/profil/innstillinger")}
            style={{ position: "absolute", right: "16px", bottom: "5px" }}
            src={Icons.cancel}
            alt="cancel"
          />
        </View>
      </View>

      <View
        direction="column"
        display="flex"
        alignItems="center"
        paddingTop="107px"
      >
        <img src={Icons.info} alt="info" />

        <Text>Velg fylket du bor i.</Text>
      </View>
      <View
        margin="0 16px 0 16px"
        display="flex"
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <RadioGroupField
          marginTop={"40px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Agder")}
            value={""}
            width={"100%"}
            checked={currentLocation === "Agder" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Agder
            </Text>
          </Radio>
        </RadioGroupField>
        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Innlandet")}
            value=""
            width={"100%"}
            checked={currentLocation === "Innlandet" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Innlandet
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Møre og Romsdal")}
            value=""
            width={"100%"}
            checked={currentLocation === "Møre og Romsdal" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Møre og Romsdal
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Nordland")}
            value=""
            width={"100%"}
            checked={currentLocation === "Nordland" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Nordland
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Oslo")}
            value=""
            width={"100%"}
            checked={currentLocation === "Oslo" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Oslo
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Rogaland")}
            value=""
            width={"100%"}
            checked={currentLocation === "Rogaland" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Rogaland
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Vestfold og Telemark")}
            value=""
            width={"100%"}
            checked={currentLocation === "Vestfold og Telemark" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Vestfold og Telemark
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Troms og Finnmark")}
            value=""
            width={"100%"}
            checked={currentLocation === "Troms og Finnmark" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Troms og Finnmark
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Trøndelag")}
            value=""
            width={"100%"}
            checked={currentLocation === "Trøndelag" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Trøndelag
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleApplyLocation("Vestland")}
            value=""
            width={"100%"}
            checked={currentLocation === "Vestland" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Vestland
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
          marginBottom={"53px"}
        >
          <Radio
            onClick={() => handleApplyLocation("Viken")}
            value={null}
            width={"100%"}
            checked={currentLocation === "Viken" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Viken
            </Text>
          </Radio>
        </RadioGroupField>
      </View>
    </View>
  );
}
