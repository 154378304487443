import React, { useState, useContext, useEffect } from 'react';
import { Flex, Text, TextField, TextAreaField, View } from '@aws-amplify/ui-react';
import { PrimaryButton } from '../../ui-components';
import { Layout } from '../../components/Layout';
import { Spacer } from '../../components/UI/Spacer';
import { SelectFieldButton } from '../../components/UI/SelectFieldButton';
import { TopNavigationBar } from '../../components/UI/TopNavigationBar';
import { useNavigate } from 'react-router';
import { updateUserProfile } from '../../graphql/mutations';
import { ProfileDataContext } from '../../contexts/contexts';
import { yupSchema } from '../../consts/yupSchema';
import { API, Auth } from 'aws-amplify';
import { Icons } from '../../assets/icons';

export function CreateProfileCard() {
	const [error, setErrors] = useState({
		display_name: '',
		location: '',
	});

	const navigate = useNavigate();

	// The object is located in App.js and is passed by Provider
	const { profileSettingsValues, setProfileSettingsValues } = useContext(ProfileDataContext);

	function handleChangeProfileData(name, value) {
		setProfileSettingsValues(prevData => ({
			...prevData,
			[name]: value,
		}));
	}

	function handleErrors(name, value) {
		setErrors(prevData => ({
			...prevData,
			[name]: value,
		}));
	}

	// update profile data
	async function handleCreateUserProfile() {
		setErrors({ display_name: '', userProfileLocationId: '' });

		const uuid = await Auth.currentUserInfo();

		// validation
		await yupSchema.profileName
			.validate(profileSettingsValues.display_name)
			.catch(error => handleErrors('display_name', error.message));

		const isValidDisplayName = await yupSchema.profileName.isValid(
			profileSettingsValues.display_name,
		);

		await yupSchema.location
			.validate(profileSettingsValues.location)
			.catch(error => handleErrors('location', error.message));

		const isValidLocation = await yupSchema.location.isValid(profileSettingsValues.location);

		//Include && isValidLocation in if sentence below
		if (isValidDisplayName && isValidLocation && uuid) {
			console.log('here!');
			await API.graphql({
				query: updateUserProfile,
				variables: {
					input: {
						cognito_uuid: uuid.attributes.sub,
						display_name: profileSettingsValues.display_name,
						gender_identity: profileSettingsValues.gender_identity,
						sexual_orientation: profileSettingsValues.sexual_orientation,
						about_me: profileSettingsValues.about_me,
						location: profileSettingsValues.location,
						marital_status: profileSettingsValues.marital_status,
					},
				},
			})
				.then(response => {
					Auth.currentSession();
					console.log('success');
					console.log('response', response);
				})
				.catch(err => {
					console.log('failed');
					console.log(err);
				});

			navigate('/konto');
		} else {
			return;
		}
	}

	function handleScrollUp() {
		window.scrollTo({ top: 0, left: 0 });
	}

	useEffect(() => {
		handleScrollUp();
	}, []);

	return (
		<>
			<TopNavigationBar header="Opprett profilkort" showIcon={false} navigateBackTo="" />

			<View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
				<View
					height="82px"
					backgroundColor="#F3F7F7"
					width="100%"
					alignItems="center"
					display="flex"
					justifyContent="center"
					position="relative"
				>
					<View>
						<div
							onClick={() => navigate('/bankid-kontoinformasjon')}
							style={{ position: 'absolute', left: '16px', top: '32px' }}
						>
							<img src={Icons.arrowBackBig} alt="Tilbake" />
						</div>
					</View>

					<Text
						textAlign={'center'}
						color={'#29666A'}
						fontSize="16px"
						fontWeight={'500'}
						style={{ position: 'absolute', top: '50%' }}
					>
						Opprett profilkort
					</Text>
				</View>
			</View>
			<Layout>
				<Flex marginTop={40} direction={'column'} alignItems={'center'}>
					<View
						width="100%"
						display="flex"
						direction="column"
						justifyContent="center"
						alignItems="center"
					>
						<Text
							fontSize={'16px'}
							textAlign="center"
							marginTop={'56px'}
							marginBottom="27px"
							color="#6B9A9E"
						>
							Profilinformasjon
						</Text>

						<img src={Icons.info} alt="info" />

						<Text textAlign={'center'} color="#5D7679" fontSize={'14px'} marginTop="7px">
							Her legger du inn informasjon som andre medlemmer kan se på ditt profilkort. Du
							må minimum velge deg et profilnavn og legge inn hvilket fylke du bor i. Resten kan du
							enten legge til nå, eller senere under "Min profil".
						</Text>
					</View>

					<View width="100%" marginTop="32px">
						<TextField
							fontSize={'20px'}
							errorMessage={error.display_name}
							style={error.display_name ? { borderBottom: '1px solid #FF6B6B' } : ''}
							height={'70px'}
							width={'100%'}
							variation="quiet"
							label={
								<Text color="#5B8386">
									Profilnavn <span style={{ color: '#F6B6C4' }}>*</span>
								</Text>
							}
							placeholder={'Skriv inn ønsket profilnavn / visningsnavn'}
							color={profileSettingsValues.display_name ? '#29666A' : '#53A0A6'}
							onChange={e => handleChangeProfileData('display_name', e.target.value)}
							value={profileSettingsValues.display_name && profileSettingsValues.display_name}
						/>
					</View>

					<Spacer height={5} />

					<View onClick={() => navigate('/velg-lokasjon')} width="100%">
						<SelectFieldButton
							error={error.location}
							label="Bosted"
							secondLabel="*"
							placeholder="Velg din lokasjon"
							value={profileSettingsValues.location ? profileSettingsValues.location : ''}
						/>
					</View>

					<Spacer height={32} />
					<View onClick={() => navigate('/velg-kjonn')} width="100%">
						<SelectFieldButton
							label="Kjønnsidentitet"
							placeholder="Velg din kjønnsidentitet"
							value={
								profileSettingsValues.gender_identity && profileSettingsValues.gender_identity
							}
						/>
					</View>

					<View onClick={() => navigate('/velg-seksuell-orientering')} width="100%">
						<SelectFieldButton
							label="Seksuell orientering"
							placeholder="Velg din seksuell orientering"
							value={
								profileSettingsValues.sexual_orientation &&
								profileSettingsValues.sexual_orientation
							}
						/>
					</View>

					<View onClick={() => navigate('/velg-sivilstatus')} width="100%">
						<SelectFieldButton
							label="Samlivsform"
							placeholder="Velg samlivsform"
							value={
								profileSettingsValues.marital_status && profileSettingsValues.marital_status
							}
						/>
					</View>

					<View width="100%" marginTop="20px">
						<TextAreaField
							fontSize={'20px'}
							label="En kort beskrivelse av deg selv"
							value={profileSettingsValues.about_me ? profileSettingsValues.about_me : ''}
							onChange={e => handleChangeProfileData('about_me', e.target.value)}
							border="1px solid #A2C7CB"
							style={{ color: '#29666A' }}
							color="#29666A"
							width={'100%'}
							placeholder="Skriv en kort tekst om deg selv så andre medlemmer lettere kan få et inntrykk av hvem du er og hva du leter etter."
						/>
					</View>

					<Text
						color="#5B8386"
						fontSize={'14px'}
						lineHeight="21px"
						fontWeight={'400px'}
						textAlign={'center'}
					>
						Alle felter merket med <span style={{ color: '#A82E30' }}>*</span> er obligatoriske.
					</Text>

					{error.display_name || error.location ? (
						<View
							marginTop="24px"
							height="66px"
							width="100%"
							backgroundColor="#FCE9E9"
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Text
								color={'#9B1717'}
								fontSize="14px"
								textAlign={'center'}
								padding="12px 16px 12px 16px"
							>
								Vennligst fyll i alle obligatoriske felter for å fortsette.
							</Text>
						</View>
					) : (
						<View />
					)}

					<PrimaryButton
						onClick={() => handleCreateUserProfile()}
						overrides={{
							label: {
								children: 'Lagre',
							},
						}}
					/>

					<Spacer height={38} />
				</Flex>
			</Layout>
		</>
	);
}
