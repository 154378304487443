import React, { useState, useEffect, useContext } from "react";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { Text, View } from "@aws-amplify/ui-react";
import { PrimaryButton, SecondaryButton } from "../../ui-components";
import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { FilteredCard } from "../../components/UI/FilteredCard";
import {
  FiltersApplyContext,
  FiltersCohabitationContext,
  FiltersGenderContext,
  FiltersLocationsContext,
  FiltersOrientationContext,
} from "../../contexts/contexts";

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export function FilterMembers() {
  const navigate = useNavigate();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedOrientationboxes, setSelectedOrientationboxes] = useState([]);
  const [selectedGenderboxes, setSelectedGenderboxes] = useState([]);
  const [selectedCohabitationboxes, setSelectedCohabitationboxes] = useState(
    []
  );
  const [filtersInitialData, setFiltersInitialData] = useState();
  const [unsavedModal, setUnsavedModal] = useState(false);
  const [numberOfChosenFilters, setNumberOfChosenFilters] = useState(0);

  const { filtersCohabitation, setFiltersCohabitation } = useContext(
    FiltersCohabitationContext
  );

  const { genderState, setGenderState } = useContext(FiltersGenderContext);

  const { filtersLocationData, setFiltersLocationData } = useContext(
    FiltersLocationsContext
  );
  const { filtersOrientation, setFiltersOrientation } = useContext(
    FiltersOrientationContext
  );

  const {
    applyFiltersGender,
    setApplyFiltersGender,

    setApplyFiltersLocations,
    applyFiltersLocations,

    setApplyFiltersOrientation,
    applyFiltersOrientation,

    setApplyFiltersCohabitation,
    applyFiltersCohabitation,
  } = useContext(FiltersApplyContext);

  const [windowHeight, setWindowHeight] = useState(getWindowSize().innerHeight);

  useEffect(() => {
    setFiltersInitialData([
      ...applyFiltersGender,
      ...applyFiltersLocations,
      ...applyFiltersCohabitation,
      ...applyFiltersOrientation,
    ]);

    function handleWindowResize() {
      setWindowHeight(getWindowSize().innerHeight);
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    let locationsAsArray = Object.entries(filtersLocationData);
    let newLocationsObject;

    let checkedLocation = locationsAsArray.filter(([key, data]) => {
      return data === true;
    });
    newLocationsObject = Object.fromEntries(checkedLocation);

    setSelectedCheckboxes(newLocationsObject);

    let orientationAsArray = Object.entries(filtersOrientation);
    let newOrientationObject;

    let checkedOrientation = orientationAsArray.filter(([key, data]) => {
      return data === true;
    });
    newOrientationObject = Object.fromEntries(checkedOrientation);

    setSelectedOrientationboxes(newOrientationObject);

    let genderAsArray = Object.entries(genderState);
    let newGenderObject;

    let checkedGender = genderAsArray.filter(([key, data]) => {
      return data === true;
    });
    newGenderObject = Object.fromEntries(checkedGender);

    setSelectedGenderboxes(newGenderObject);

    let cohabitationAsArray = Object.entries(filtersCohabitation);
    let newCohabitationObject;

    let checkedCohabitation = cohabitationAsArray.filter(([key, data]) => {
      return data === true;
    });
    newCohabitationObject = Object.fromEntries(checkedCohabitation);

    setSelectedCohabitationboxes(newCohabitationObject);
  }, [
    filtersLocationData,
    filtersCohabitation,
    filtersOrientation,
    genderState,
  ]);

  function handleDeteleFilters(functionObject, key) {
    functionObject((prevData) => ({
      ...prevData,
      [key]: false,
    }));
  }

  function handleApplyFilter() {
    setApplyFiltersGender(Object.keys(selectedGenderboxes));
    setApplyFiltersLocations(Object.keys(selectedCheckboxes));
    setApplyFiltersOrientation(Object.keys(selectedOrientationboxes));
    setApplyFiltersCohabitation(Object.keys(selectedCohabitationboxes));

    navigate("/medlemmer");
  }

  function handleArrowBackClick() {
    let changedData = [
      ...Object.keys(selectedCheckboxes),
      ...Object.keys(selectedCohabitationboxes),
      ...Object.keys(selectedGenderboxes),
      ...Object.keys(selectedOrientationboxes),
    ];

    if (filtersInitialData.sort().join() !== changedData.sort().join()) {
      setUnsavedModal(true);
    } else {
      navigate("/medlemmer");
    }
  }

  useEffect(() => {
    let allChosenFilter = 0;
    const numberCohabitationChosenFilter = Object.keys(
      selectedCohabitationboxes
    ).length;
    const numberGenderChosenFilter = Object.keys(selectedGenderboxes).length;
    const numberLocationChosenFilter = Object.keys(selectedCheckboxes).length;
    const numberOrientationChosenFilter = Object.keys(
      selectedOrientationboxes
    ).length;

    allChosenFilter =
      numberCohabitationChosenFilter +
      numberGenderChosenFilter +
      numberLocationChosenFilter +
      numberOrientationChosenFilter;

    setNumberOfChosenFilters(allChosenFilter);

    return () => {};
  }, [
    selectedCheckboxes,
    selectedCohabitationboxes,
    selectedGenderboxes,
    selectedOrientationboxes,
  ]);

  useEffect(() => {
    function handleScrollUp() {
      window.scrollTo({ top: 0, left: 0 });
    }
    handleScrollUp();
  }, []);

  function handleCancelChangedData() {
    setUnsavedModal(false);
  }

  function handleClearAllFilters() {
    setFiltersLocationData((prevData) => ({
      ...prevData,
      Agder: false,
      Innlandet: false,
      "Møre og Romsdal": false,
      Nordland: false,
      Oslo: false,
      Rogaland: false,
      "Vestfold og Telemark": false,
      "Troms og Finnmark": false,
      Trøndelag: false,
      Vestland: false,
      Viken: false,
    }));

    setGenderState((prevData) => ({
      ...prevData,
      Mann: false,
      Kvinne: false,
      "Ikke-binær": false,
    }));

    setFiltersOrientation((prevData) => ({
      ...prevData,
      Heterofil: false,
      Homofil: false,
      Lesbisk: false,
      Bifil: false,
      Annet: false,
    }));

    setFiltersCohabitation((prevData) => ({
      ...prevData,
      Singel: false,
      "Parforhold / romantisk partner": false,
      Gift: false,
      Annet: false,
    }));
  }

  return (
    <>
      {unsavedModal ? (
        <View
          style={{
            width: "100%",
            height: "95vh",
            zIndex: "10",
            display: "flex",
            justifyContent: "space-between",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <View paddingTop="104px">
            <Text
              textAlign={"center"}
              fontSize={"20px"}
              color="#5C7578"
              fontWeight={"500"}
              marginBottom="24px"
            >
              Dine endringer er ikke lagret
            </Text>

            <Text
              textAlign={"center"}
              fontSize={"14px"}
              color="#5D7679"
              fontWeight={"400"}
            >
              Vil du lagre endringene før du avbryter?
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <PrimaryButton
              style={{ marginBottom: "32px" }}
              onClick={() => handleApplyFilter()}
              overrides={{
                label: {
                  children: "Lagre",
                },
              }}
            />

            <SecondaryButton
              onClick={() => handleCancelChangedData()}
              overrides={{
                label: {
                  children: "Avbryt",
                },
              }}
            />
          </View>
        </View>
      ) : (
        <>
          <TopNavigationBar
            onArrowBackClick={handleArrowBackClick}
            noIconBack
            isMembersFilterPage
            onFilterClick={() => navigate("/medlemmer")}
            header="Filtrer blant medlemmer"
          />

          <Layout>
            <View
              display="flex"
              direction="column"
              justifyContent="space-between"
              minHeight={
                numberOfChosenFilters > 10
                  ? windowHeight + 56
                  : windowHeight - 56
              }
              marginBottom="32px"
            >
              <View>
                <View marginTop="114px">
                  {numberOfChosenFilters !== 0 && (
                    <Text
                      fontSize={"16px"}
                      color="#4E888D"
                      fontWeight={"700"}
                      textAlign="right"
                      marginBottom="32px"
                      marginRight={"16px"}
                      onClick={() => handleClearAllFilters()}
                    >
                      Fjern alle filtre
                    </Text>
                  )}

                  {numberOfChosenFilters !== 0 && (
                    <Text color="#869798" marginBottom={"26px"}>
                      {numberOfChosenFilters !== 0 ? numberOfChosenFilters : ""}{" "}
                      aktive filtre
                    </Text>
                  )}

                  <Text
                    onClick={() => navigate("/filter-lokasjon")}
                    color="#48676A"
                    fontSize={"16px"}
                    marginBottom="20px"
                  >
                    Velg fylke(r) du vil søke i:
                  </Text>

                  <View display="flex" direction="row" wrap="wrap">
                    {Object.keys(selectedCheckboxes).map((value, index) => {
                      return (
                        <FilteredCard
                          key={index}
                          title={value}
                          onDeleteFilter={() =>
                            handleDeteleFilters(setFiltersLocationData, value)
                          }
                        />
                      );
                    })}
                  </View>

                  <View width="100%" height="1px" backgroundColor="#9CBCBF" />
                </View>

                <View marginTop="74px">
                  <Text
                    color="#48676A"
                    fontSize={"16px"}
                    marginBottom="20px"
                    onClick={() => navigate("/filter-kjonn")}
                  >
                    Kjønnsidentitet:
                  </Text>
                  <View display="flex" direction="row" wrap="wrap">
                    {Object.keys(selectedGenderboxes).map((value, index) => {
                      return (
                        <FilteredCard
                          key={index}
                          title={value}
                          onDeleteFilter={() =>
                            handleDeteleFilters(setGenderState, value)
                          }
                        />
                      );
                    })}
                  </View>
                  <View width="100%" height="1px" backgroundColor="#9CBCBF" />
                </View>

                <View marginTop="34px">
                  <Text
                    color="#48676A"
                    fontSize={"16px"}
                    marginBottom="20px"
                    onClick={() => navigate("/filter-seksuell-orientering")}
                  >
                    Seksuell orientering:
                  </Text>
                  <View display="flex" direction="row" wrap="wrap">
                    {Object.keys(selectedOrientationboxes).map(
                      (value, index) => {
                        return (
                          <FilteredCard
                            key={index}
                            title={value}
                            onDeleteFilter={() =>
                              handleDeteleFilters(setFiltersOrientation, value)
                            }
                          />
                        );
                      }
                    )}
                  </View>

                  <View width="100%" height="1px" backgroundColor="#9CBCBF" />
                </View>

                <View marginTop="34px">
                  <Text
                    color="#48676A"
                    fontSize={"16px"}
                    marginBottom="20px"
                    onClick={() => navigate("/filter-samlivsform")}
                  >
                    Samlivsform:
                  </Text>
                  <View display="flex" direction="row" wrap="wrap">
                    {Object.keys(selectedCohabitationboxes).map(
                      (value, index) => {
                        console.log("value", value);
                        return (
                          <FilteredCard
                            key={index}
                            title={value}
                            onDeleteFilter={() =>
                              handleDeteleFilters(setFiltersCohabitation, value)
                            }
                          />
                        );
                      }
                    )}
                  </View>

                  <View width="100%" height="1px" backgroundColor="#9CBCBF" />
                </View>
              </View>
              <View display="flex" justifyContent="center">
                <SecondaryButton
                  onClick={() => handleApplyFilter()}
                  overrides={{
                    label: {
                      children: "Legg til filter",
                    },
                    SecondaryButton: {
                      marginTop: "32px",
                      marginBottom: "32px",
                    },
                  }}
                />
              </View>
            </View>
          </Layout>
        </>
      )}
    </>
  );
}
