import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ConversationsContext } from "../../contexts/conversations";
import { Messages } from "./Messages";

export function MessagesRoute() {
  const conversations = useContext(ConversationsContext);
  const urlParams = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedConvo, setSelectedConvo] = useState(null);

  useEffect(() => {
    if (!conversations.isLoading) {
      const result = conversations.items?.filter(
        (convo) => convo.partner?.cognito_uuid === urlParams.id
      );
      setSelectedConvo(result[0]);
    }
  }, [conversations, urlParams]);

  useEffect(() => {
    if (selectedConvo?.partner?.cognito_uuid === urlParams.id) {
      setIsLoading(false);
    }
  }, [selectedConvo, urlParams.id]);

  if (!isLoading) {
    return <Messages conversation={selectedConvo} />;
  }

  return <div></div>;
}
