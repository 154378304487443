/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      from_username
      to_username
      server_timestamp
      participants_usernames
      message_text
      conversationID
      createdAt
      isRead
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      from_username
      to_username
      server_timestamp
      participants_usernames
      message_text
      conversationID
      createdAt
      isRead
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      from_username
      to_username
      server_timestamp
      participants_usernames
      message_text
      conversationID
      createdAt
      isRead
      updatedAt
      __typename
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      initiator_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      invited_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      participants_usernames
      Messages {
        items {
          id
          from_username
          to_username
          server_timestamp
          participants_usernames
          message_text
          conversationID
          createdAt
          isRead
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      conversationInitiator_usernameId
      conversationInvited_usernameId
      __typename
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      initiator_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      invited_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      participants_usernames
      Messages {
        items {
          id
          from_username
          to_username
          server_timestamp
          participants_usernames
          message_text
          conversationID
          createdAt
          isRead
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      conversationInitiator_usernameId
      conversationInvited_usernameId
      __typename
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      initiator_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      invited_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      participants_usernames
      Messages {
        items {
          id
          from_username
          to_username
          server_timestamp
          participants_usernames
          message_text
          conversationID
          createdAt
          isRead
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      conversationInitiator_usernameId
      conversationInvited_usernameId
      __typename
    }
  }
`;
export const createUserProfile = /* GraphQL */ `
  mutation CreateUserProfile(
    $input: CreateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    createUserProfile(input: $input, condition: $condition) {
      cognito_uuid
      display_name
      age
      location
      gender_identity
      sexual_orientation
      marital_status
      about_me
      avatarImage
      imagesList
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserProfile = /* GraphQL */ `
  mutation UpdateUserProfile(
    $input: UpdateUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    updateUserProfile(input: $input, condition: $condition) {
      cognito_uuid
      display_name
      age
      location
      gender_identity
      sexual_orientation
      marital_status
      about_me
      avatarImage
      imagesList
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserProfile = /* GraphQL */ `
  mutation DeleteUserProfile(
    $input: DeleteUserProfileInput!
    $condition: ModelUserProfileConditionInput
  ) {
    deleteUserProfile(input: $input, condition: $condition) {
      cognito_uuid
      display_name
      age
      location
      gender_identity
      sexual_orientation
      marital_status
      about_me
      avatarImage
      imagesList
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      s3_bucket_link
      config_json
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      s3_bucket_link
      config_json
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      s3_bucket_link
      config_json
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUserAccount = /* GraphQL */ `
  mutation CreateUserAccount(
    $input: CreateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    createUserAccount(input: $input, condition: $condition) {
      cognito_uuid
      membership_level
      signicat_session_status
      first_name
      last_name
      date_of_birth
      signicat_auth_date
      notification_preference
      my_conversations
      favorites
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserAccount = /* GraphQL */ `
  mutation UpdateUserAccount(
    $input: UpdateUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    updateUserAccount(input: $input, condition: $condition) {
      cognito_uuid
      membership_level
      signicat_session_status
      first_name
      last_name
      date_of_birth
      signicat_auth_date
      notification_preference
      my_conversations
      favorites
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserAccount = /* GraphQL */ `
  mutation DeleteUserAccount(
    $input: DeleteUserAccountInput!
    $condition: ModelUserAccountConditionInput
  ) {
    deleteUserAccount(input: $input, condition: $condition) {
      cognito_uuid
      membership_level
      signicat_session_status
      first_name
      last_name
      date_of_birth
      signicat_auth_date
      notification_preference
      my_conversations
      favorites
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
