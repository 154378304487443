import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Outlet } from "react-router-dom";

export function RequireAuth() {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log(user)
    } catch (error) {
      setUser("Can't set user");
    }
  }
  useEffect(() => {
    if (user === "Can't set user") {
      navigate("/logg-inn");
    }
  }, [user, navigate]);

  return <Outlet />;
}
