import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text, View, useAuthenticator } from '@aws-amplify/ui-react';
import { BottomNavigationBar } from '../../components/UI/BottomNavigationBar';
import { API } from 'aws-amplify';
import { ConversationsCard } from '../../components/UI/ConversationsCard';
import { ConversationsContext } from '../../contexts/conversations';
import { updateMessage } from '../../graphql/mutations';
import { useNavigate } from 'react-router-dom';
import { Loading as LoadingAnimation } from '../loading';
import { Icons } from '../../assets/icons';

export function Conversations() {
	console.log('Conversations in Conversations.js')
	const conversations = useContext(ConversationsContext);
	const [displayConversations, setDisplayConversations] = useState([]);
	
	const { user, route } = useAuthenticator((context) => [context.user, context.route]);
		
	const navigate = useNavigate();

	function handleScrollUp() {
		window.scrollTo({ top: 0, left: 0 });
	}

	useEffect(() => {
		handleScrollUp();
	}, []);
	console.log("before useeffect: conversations.isLoading: " + conversations.isLoading)
	console.log("useAuthenticator: route, user: "+route + ", "+user)
	useEffect(() => {
		if (conversations && !conversations.isLoading && conversations.items?.length > 0) {
			const sortedConversations = conversations.items
				.filter(conv => conv.Messages.items?.length > 0)
				.sort(function (a, b) {
					return (
						new Date(b.Messages.items.at(-1).createdAt) -
						new Date(a.Messages.items.at(-1).createdAt)
					);
				});

			setDisplayConversations([...sortedConversations]);
		}
	}, [conversations]);

	async function openConversation(conversation) {
		const lastMessage = conversation.Messages.items.at(-1);
		if (
			lastMessage.from_username !== conversation.you.cognito_uuid &&
			lastMessage.isRead !== 'true'
		) {
			console.log('Someone else sent and it is not read');

			try {
				const responseSettingConversationToRead = await API.graphql({
					query: updateMessage,
					variables: {
						input: {
							id: lastMessage.id,
							isRead: 'true',
						},
					},
				});
				console.log('response update message to read', responseSettingConversationToRead);
				//To reload the list
				setDisplayConversations([...conversations.items]);
			} catch (err) {
				console.log(err);
			}
		}
		navigate(`/samtaler/${conversation.partner.cognito_uuid}`);
	}
	console.log("before return: conversations.isLoading: " + conversations.isLoading)
	return (
		<>
			<View
				height="34px"
				backgroundColor="#F3F7F7"
				position="fixed"
				top="0px"
				width="100%"
				style={{ zIndex: 10 }}
			/>
			<View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
				<View
					height="82px"
					backgroundColor="#F3F7F7"
					width="100%"
					alignItems="center"
					display="flex"
					justifyContent="center"
					position="relative"
				>
					<Text
						textAlign={'center'}
						color={'#29666A'}
						fontSize="16px"
						style={{ position: 'absolute', top: '50%' }}
					>
						Meldinger
					</Text>
				</View>
			</View>

			{conversations.isLoading ? (
				<LoadingAnimation />
			) : (
				<>
					{conversations.isLoading ? (
						<Flex
							paddingTop="104px"
							direction="column"
							textAlign="center"
							marginBottom="4rem"
							alignItems="center"
							gap="0.5rem"
						>
							<Text color="#899D9F">Henter dine samtaler... </Text>
						</Flex>
					) : (
						<>
							{
								<Flex
									paddingTop="104px"
									direction="column"
									gap="medium"
									margin="0px 16px 60px"
									paddingBottom="20px"
								>
									<>
										{displayConversations.length === 0 ? (
											<>
												<View
													display="flex"
													direction="column"
													textAlign="center"
													marginBottom="4rem"
													alignItems="center"
													gap="0.5rem"
												>
													<img src={Icons.info} alt="info" />

													<Text color="#5D7679" fontSize={'14px'} fontWeight="400">
														Du har ikke startet noen samtaler enda
													</Text>
												</View>
											</>
										) : (
											displayConversations.map(conversation => (
												<ConversationsCard
													key={conversation.id}
													conversation={conversation}
													onClickConversation={openConversation}
												/>
											))
										)}
									</>
								</Flex>
							}
						</>
					)}
				</>
			)}

			<BottomNavigationBar
				type={5}
				buttonState="active"
				unreadMessages={conversations.newMessage}
			/>
		</>
	);
}
