const imgUrl = {
  decode: (imagepack) => ({
    filename: JSON.parse(imagepack)[0],
    id: JSON.parse(imagepack)[1],
  }),
  encode: (filename, id) => JSON.stringify([filename, id]),
  filename: (imagepack) => JSON.parse(imagepack)[0],
  id: (imagepack) => JSON.parse(imagepack)[1],
};
export default imgUrl;
