import React, { useContext } from "react";
import {
  View,
  Flex,
  Heading,
  Text,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { SecondaryButton, PrimaryButton } from "../../ui-components";
import { useNavigate } from "react-router";
import {
  FiltersApplyContext,
  FiltersCohabitationContext,
  FiltersGenderContext,
  FiltersLocationsContext,
  FiltersOrientationContext,
  ImagesDataContext,
  ProfileDataContext,
} from "../../contexts/contexts";
import { Auth } from 'aws-amplify';

export function LogOut() {
  const navigate = useNavigate();
  const { setProfileSettingsValues } = useContext(ProfileDataContext);

  const { setFiltersCohabitation } = useContext(FiltersCohabitationContext);
  const {
    setApplyFiltersGender,
    setApplyFiltersLocations,
    setApplyFiltersOrientation,
    setApplyFiltersCohabitation,
  } = useContext(FiltersApplyContext);

  const { setGenderState } = useContext(FiltersGenderContext);
  const { setFiltersLocationData } = useContext(FiltersLocationsContext);
  const { setFiltersOrientation } = useContext(FiltersOrientationContext);

  const { setImagesData, setImageUrlProfile } = useContext(ImagesDataContext);

  async function logOut() {
    try {
      await Auth.signOut({ global: true });
    } catch (error) {
      console.log('error signing out: ', error);
    }
    setProfileSettingsValues({
      cognito_uuid: "",
      display_name: "",
      gender_identity: "",
      sexual_orientation: "",
      about_me: "",
      userProfileLocationId: "",
      marital_status: "",
    });

    setApplyFiltersCohabitation([]);
    setApplyFiltersGender([]);
    setApplyFiltersLocations([]);
    setApplyFiltersOrientation([]);

    setFiltersCohabitation({});
    setFiltersLocationData({});
    setFiltersOrientation({});
    setGenderState({});
    setImageUrlProfile("");
    setImagesData("");

    setTimeout(() => {
      navigate("/");
    }, 1000);
  }

  // async function handleCheckUserToken() {
  //   const userLoggedIn = await Auth.currentAuthenticatedUser();

  //   if (userLoggedIn.signInUserSession.accessToken.jwtToken) {
  //     // setUserToken(true);
  //     console.log("in", userLoggedIn.signInUserSession.accessToken.jwtToken);
  //   } else {
  //     // setUserToken(false);
  //     console.log("out", userLoggedIn.signInUserSession.accessToken.jwtToken);
  //   }
  // }

  return (
    <View padding="40px 16px 10px">
      <Flex direction="column" textAlign="center">
        <Heading
          fontWeight="normal"
          level={4}
          marginBottom="medium"
          paddingTop="50px"
        >
          Logg ut
        </Heading>
        <Text fontSize="medium">
          Er du sikker på at du vil logge av applikasjonen?
        </Text>
        <Flex
          direction="column"
          alignItems="center"
          position={"absolute"}
          bottom="56px"
          right={"0"}
          left="0"
        >
          <PrimaryButton
            onClick={() => logOut()}
            overrides={{
              label: { children: "Logg ut" },
            }}
          />
          <SecondaryButton
            onClick={() => navigate("/min-profil")}
            overrides={{
              label: {
                children: "Avbryt",
              },
            }}
          />
        </Flex>
      </Flex>
    </View>
  );
}
