import React, { useContext } from "react";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import {
  Text,
  View,
  RadioGroupField,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { FiltersOrientationContext } from "../../contexts/contexts";
import { useNavigate } from "react-router-dom";

export function FilterOrientation() {
  const navigate = useNavigate();
  const { filtersOrientation, setFiltersOrientation } = useContext(
    FiltersOrientationContext
  );

  function handleFilterData(name, value) {
    setFiltersOrientation((prevData) => ({
      ...prevData,
      [name]: !value,
    }));
  }

  return (
    <>
      <TopNavigationBar
        noIconBack
        isMembersFilterPage
        onArrowBackClick={() => navigate("/filter-medlemmer")}
        header="Velg din seksuelle orientering"
        onFilterClick={() => navigate("/filter-medlemmer")}
      />

      <Layout>
        <View
          paddingTop="104px"
          display="flex"
          justifyContent="center"
          direction="column"
        >
          <RadioGroupField
            marginTop={0}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Heterofil", filtersOrientation.Heterofil)
              }
              value={""}
              width={"100%"}
              checked={filtersOrientation.Heterofil}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Heterofil
            </Text>
            {/* </Radio> */}
          </RadioGroupField>
          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Homofil", filtersOrientation.Homofil)
              }
              value=""
              width={"100%"}
              checked={filtersOrientation.Homofil}
              readOnly={true}
            />

            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Homofil
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Lesbisk", filtersOrientation.Lesbisk)
              }
              value=""
              width={"100%"}
              checked={filtersOrientation.Lesbisk}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Lesbisk
            </Text>
          </RadioGroupField>
          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Bifil", filtersOrientation.Bifil)
              }
              value=""
              width={"100%"}
              checked={filtersOrientation.Bifil}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Bifil
            </Text>
          </RadioGroupField>
          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Annet", filtersOrientation.Annet)
              }
              value=""
              width={"100%"}
              checked={filtersOrientation.Annet}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Annet
            </Text>
          </RadioGroupField>
        </View>
      </Layout>
    </>
  );
}
