import React from "react";
import { Flex, View, Text } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { Icons } from "../../assets/icons";

export function TopNavigationBar({
  header,
  showIcon,
  navigateBackTo,
  accountDetailsPage,
  noIconBack,
  onFilterClick,
  isMemberListPage,
  isMembersFilterPage,
  activeFilters,
  onArrowBackClick,
  isMedforeldrePage,
  isMedforeldreMenuPages,
}) {
  const navigate = useNavigate();
  return (
    <>
      <View
        height="33px"
        backgroundColor="#F2F6F6"
        width="100%"
        position="fixed"
        top={0}
        right={0}
        style={{ zIndex: 100 }}
      />
      <Flex
        width={"100%"}
        display="flex"
        justifyContent="space-between"
        alignItems={"center"}
        direction={"row"}
        position="fixed"
        top={32}
        right={0}
        height={48}
        backgroundColor="#F2F6F6"
        style={{ zIndex: 100 }}
      >
        {!noIconBack ? (
          <div
            onClick={() => navigate(navigateBackTo)}
            style={{ paddingLeft: "16px" }}
          >
            <img src={Icons.arrowBackBig} alt="arrow-left" />
          </div>
        ) : (
          <View />
        )}

        <Text
          textAlign={"center"}
          color="#29666A"
          fontSize={"16px"}
          marginBottom={"5px"}
        >
          {header}
        </Text>
        {!accountDetailsPage ? (
          <>
            {showIcon ? (
              <div
                onClick={() => navigate("/forhandsvis-profil")}
                style={{ paddingRight: "26px" }}
              >
                <img src={Icons.eye} alt="eye" />
              </div>
            ) : (
              <View style={isMemberListPage ? { paddingRight: "0px" } : {}} />
            )}
          </>
        ) : (
          <View />
        )}

        {isMemberListPage && (
          <>
            {activeFilters !== 0 && (
              <View
                onClick={() => onFilterClick()}
                position="absolute"
                right="16px"
                top={4}
                backgroundColor="#364D4F"
                borderRadius="10px"
                height="20px"
                minWidth="20px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ zIndex: 2 }}
              >
                <Text
                  fontSize={"12px"}
                  color="#ffffff"
                  fontWeight={"500"}
                  padding="5px"
                >
                  {activeFilters !== 0 ? activeFilters : ""}
                </Text>
              </View>
            )}
            <View
              style={{ position: "absolute", right: "16px" }}
              onClick={() => onFilterClick()}
            >
              <img src={Icons.filter} alt="filter" />
            </View>
          </>
        )}

        {isMembersFilterPage && (
          <>
            <View
              style={{ position: "absolute", left: "16px", bottom: 0 }}
              onClick={() => onArrowBackClick()}
            >
              <img src={Icons.arrowBackBig} alt="arrow-back" />
            </View>
          </>
        )}
        {isMedforeldrePage && (
          <>
            <View
              style={{ position: "absolute", left: "16px", bottom: 0 }}
              onClick={() => onArrowBackClick()}
            >
              <img src={Icons.arrowBackBig} alt="arrow-back" />
            </View>
          </>
        )}
        {isMedforeldreMenuPages && (
          <>
            <View
              style={{ position: "absolute", left: "16px", bottom: 0 }}
              onClick={() => onArrowBackClick()}
            >
              <img src={Icons.arrowBackBig} alt="arrow-back" />
            </View>
          </>
        )}
      </Flex>
    </>
  );
}
