import { useState, useEffect } from "react";
import { View, Flex, Text } from "@aws-amplify/ui-react";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { API, Auth, Storage } from "aws-amplify";
import imgUrl from "../../helpers/imageHelper";
import { getUserAccount, getUserProfile } from "../../graphql/queries";
import { MembersCard } from "../../components/UI/MembersCard";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets/icons";
import { Loading as LoadingAnimation } from "../loading";

export function Favourites() {
  const [loading, setLoading] = useState(true);
  const [favouritesMembersList, setFavouritesMembersList] = useState([]);

  const navigate = useNavigate();

  async function getMembers() {
    setLoading(true);

    const uuid = await Auth.currentUserInfo();
    try {
      const responseProfile = await API.graphql({
        query: getUserAccount,
        variables: {
          cognito_uuid: uuid.attributes.sub,
        },
      });
      const favoritesAsIds = responseProfile.data.getUserAccount.favorites;

      if (favoritesAsIds) {
        let favorites = await Promise.all(
          favoritesAsIds.map(
            async (id) =>
              await API.graphql({
                query: getUserProfile,
                variables: {
                  cognito_uuid: id,
                },
              })
          )
        );
        favorites = favorites.map((favorite) => favorite.data.getUserProfile);

        const favoritesWithAvatars = await Promise.all(
          favorites.map(async (favorite) => ({
            ...favorite,
            userAvatarImage: favorite.avatarImage
              ? await Storage.get(imgUrl.filename(favorite.avatarImage), {
                  identityId: imgUrl.id(favorite.avatarImage),
                  level: "protected",
                })
              : null,
          }))
        );
        setFavouritesMembersList(favoritesWithAvatars);
      }
    } catch (err) {
      console.log("error:", err);
    }

    setLoading(false);
  }

  useEffect(() => {
    getMembers();
    function handleScrollUp() {
      window.scrollTo({ top: 0, left: 0 });
    }
    handleScrollUp();
  }, []);

  function handleRedirectMemberProfile(data) {
    navigate(`/medlem/${data.cognito_uuid}`);
  }
  return (
    <>
      <TopNavigationBar header={"Favoritter"} noIconBack />

      {loading ? (
        <LoadingAnimation />
      ) : (
        <Layout>
          <Flex
            direction="column"
            paddingTop="100px"
            marginBottom="82px"
            gap="medium"
          >
            <>
              {favouritesMembersList.length !== 0 &&
                favouritesMembersList.map((favourite, index) => {
                  return (
                    <View key={index}>
                      <MembersCard
                        isFavourite={true}
                        onClickMember={() =>
                          handleRedirectMemberProfile(favourite)
                        }
                        key={favourite.id}
                        membersData={favourite}
                      />
                    </View>
                  );
                })}
            </>
            {!loading && favouritesMembersList.length === 0 && (
              <>
                <View
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  direction="column"
                >
                  <img src={Icons.info} alt="info" />
                  <Text
                    textAlign={"center"}
                    fontSize="14px"
                    color="#5D7679"
                    marginTop={"7px"}
                  >
                    Ingen medlemmer lagt til i favoritter ennå
                  </Text>
                </View>
              </>
            )}
          </Flex>
        </Layout>
      )}
      <BottomNavigationBar type={4} buttonState="active" />
    </>
  );
}
