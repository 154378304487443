import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "@aws-amplify/ui-react/styles.css";
import { AmplifyProvider, ThemeProvider } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import { studioTheme } from "./ui-components";

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AmplifyProvider>
      <Authenticator.Provider>
        <ThemeProvider theme={studioTheme}>
          <App />
        </ThemeProvider>
      </Authenticator.Provider>
    </AmplifyProvider>
  </React.StrictMode>
);
