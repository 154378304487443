import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from '../../ui-components';
import { View, Heading, Flex } from '@aws-amplify/ui-react';
import { Icons } from '../../assets/icons';

export default function BankIDSuccess() {
	const navigate = useNavigate();
	useEffect(() => {
		const example = async () => {
			try {
				const cognitoUser = await Auth.currentAuthenticatedUser();
				const currentSession = await Auth.currentSession();
				cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
					console.log('session', session);
					const { idToken, refreshToken, accessToken } = session;
					console.log('idToken', idToken, refreshToken, accessToken);
				});

				// refreshSession() {
				//   return new Promise((resolve, reject) => {
				//   this.props.authenticatedUser.refreshSession(this.props.authenticatedUser.signInUserSession.refreshToken, () => {
				//     Auth.currentUserCredentials().then(() => {
				//       Auth.currentAuthenticatedUser().then(updatedAuthUser => {
				//         this.props.onUserAuthenticated(updatedAuthUser);
				//         resolve();
				//       })
				//       .catch(err => {
				//           console.log(err);
				//           reject();
				//       });
				//     });
				//   });
				// });
			} catch (e) {
				console.log('Unable to refresh Token', e);
			}
			console.log('I RAN');
		};
		example();
	}, []);

	return (
		<View padding="30px 16px 10px">
			<Flex
				direction="column"
				textAlign="center"
				marginTop="4rem"
				marginBottom="large"
				alignItems="center"
			>
				<img src={Icons.verifiedPassword} alt="success" />
				<Heading fontSize="20px" fontWeight="500" color="#6B9A9E">
					Kontoen din ble bekreftet med BankID
				</Heading>
				<View position="absolute" bottom="56px">
					<SecondaryButton
						onClick={() => {
							window.location.href = '/bankid-kontoinformasjon';
						}}
						overrides={{
							label: {
								children: 'Neste',
							},
						}}
					/>
				</View>
			</Flex>
		</View>
	);
}
