import React from "react";
import { SecondaryButton } from "../ui-components";
import {
  View,
  Text,
  Flex,
  Heading,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";

export default function Intro() {
  const navigate = useNavigate();

  function setNotShowAgain() {
    localStorage.setItem("dontShowAgain", JSON.stringify(true));
  }

  return (
    <View padding="40px 16px 10px">
      <Flex direction="column" textAlign="center">
        <Heading fontWeight="normal" level={4} marginBottom="medium">
          Velkommen til Medforeldres Medlemssider!
        </Heading>
        <Text fontSize="medium">
          Våre medlemssider er helt ferske og fortsatt under utvikling. 
          Likevel vil vi gjerne tilby våre medlemmer den funksjonaliteten vi har - så fort
          vi har den. Derfor setter vi ekstra stor pris på
          tilbakemeldinger, både om ting ikke funker så bra, og om du
          har forslag til hvordan tjenesten kan bli enda bedre.
        </Text>
        <Text fontSize="medium">
          Vi tar sikkerhet på alvor og vil at våre medlemmer skal føle seg
          trygge når de bruker tjenesten. Derfor må du blant annet bruke BankID 
          første gang du registrerer deg. Medlemssidene fungerer aller best på mobiltelefon.
        </Text>
        <Text fontSize="medium">
          Vi ønsker deg en trygg og varm Medforeldre-reise!
        </Text>
        <Flex direction="column" alignItems="center" marginTop="large">
          <CheckboxField
            label="Ikke vis denne teksten igjen"
            name="checkbox"
            value="checked"
            marginBottom="large"
            onChange={() => setNotShowAgain()}
          />
          <SecondaryButton
            onClick={() => navigate("/logg-inn")}
            overrides={{
              label: {
                children: "Fortsett",
              },
            }}
          />
        </Flex>
      </Flex>
    </View>
  );
}
