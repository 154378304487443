import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import { getUserAccount } from './graphql/queries';
import { Outlet } from 'react-router-dom';

export function RequireBankIDVerification() {
	const navigate = useNavigate();

	useEffect(() => {
		checkIfBankIDVerified();

		async function checkIfBankIDVerified() {
			const uuid = await Auth.currentUserInfo();

			if (uuid) {
				try {
					const response = await API.graphql({
						query: getUserAccount,
						variables: {
							cognito_uuid: uuid.attributes.sub,
						},
					});

					if (!response.data.getUserAccount) {
						navigate('/bankID');
					}
				} catch (err) {
					console.log(err);
				}
			}
		}
	}, [navigate]);

	return <Outlet />;
}
