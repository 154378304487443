import search from "./search.svg";
import searchWhite from "./searchWhite.svg";
import medforeldre from "./medforeldre.svg";
import medforeldreWhite from "./medforeldreWhite.svg";
import messagesWithNotify from "./messages-notify.svg";
import messagesWithNotifyWhite from "./messages-notifyWhite.svg";
import userProfile from "./userProfile.svg";
import userProfileWhite from "./user-profileWhite.svg";
import favourites from "./favourites.svg";
import favoritesWhite from "./favoritesWhite.svg";
import eye from "./eye.svg";
import info from "./info.svg";
import arrowLeft from "./arrow-left.svg";
import arrowRight from "./arrow-right.svg";
import favoritesEmpty from "./favoritesEmpty.svg";
import location from "./location.svg";
import messageEmptyWhite from "./message-emptyWhite.svg";
import messageActive from "./messagesActive.svg";
import locationPreviewProfile from "./location-preview-profile.svg";
import filter from "./filter.svg";
import cancel from "./cancel.svg";
import arrowBackBig from "./arrowBackBig.svg";
import deleteFilter from "./deleteFilter.svg";
import memberChat from "./memberChat.svg";
import favoriteMember from "./favoriteUserDisable.svg";
import searchQuestions from "./searchQuestions.svg";
import arrowUp from "./arrowUp.svg";
import mail from "./mail.svg";
import medforeldreLogo from "./medforeldre-logo.svg";
import favouriteActive from "./favouriteActive.svg";
import addedToFavourites from "./added_to_favourites.svg";
import redDot from "./red_dot.svg";
import logo from "./logo.svg";
import logoMedforeldre from "./logoMedforeldre.svg";
import mailSend from "./mail_sent_large.svg";
import verifiedPassword from "./verified_large.svg";
import bankId from "./bankId.svg";
import errorFailed from "./error_failed.svg";
import slideLeft from "./slideLeft.png";
import slideRight from "./slideRight.png";
import disableMessages from "./disableMessages.svg";
import messageInactiveGreen from "./messageInactiveGreen.svg";
import logOutIcon from "./logOutIcon.svg";
import anbefalIcon from "./anbefalIcon.svg";
import settingsIcon from "./settingsIcon.svg";
import notificationIcon from "./notificationIcon.svg";
import profileSettingsIcon from "./ProfileSettingsIcon.svg";
import about_us from "./about_us.svg";
import guidance from "./guidance.png";
import research from "./research.svg";
import medforeldreMenuIcon from "./AppIconLarge.svg";
import journey from "./journey.svg";
import messageSend from "./messageSend.png";
import setProfileAvatar from "./setProfileAvatar.svg";
import sendMessageDisabled from "./sendMessageDisabled.png";
import cameraIcon from "./cameraIcon.svg";

export const Icons = {
  cameraIcon,
  sendMessageDisabled,
  setProfileAvatar,
  messageSend,
  journey,
  medforeldreMenuIcon,
  about_us,
  guidance,
  research,
  logOutIcon,
  anbefalIcon,
  settingsIcon,
  notificationIcon,
  profileSettingsIcon,
  messageInactiveGreen,
  disableMessages,
  slideLeft,
  slideRight,
  errorFailed,
  bankId,
  verifiedPassword,
  mailSend,
  addedToFavourites,
  search,
  searchWhite,
  medforeldre,
  medforeldreWhite,
  messagesWithNotify,
  messagesWithNotifyWhite,
  userProfile,
  userProfileWhite,
  favourites,
  favoritesWhite,
  eye,
  info,
  arrowLeft,
  arrowRight,
  favoritesEmpty,
  messageActive,
  messageEmptyWhite,
  location,
  locationPreviewProfile,
  filter,
  cancel,
  arrowBackBig,
  deleteFilter,
  memberChat,
  favoriteMember,
  searchQuestions,
  arrowUp,
  mail,
  medforeldreLogo,
  favouriteActive,
  redDot,
  logo,
  logoMedforeldre,
};
