/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateMessage = /* GraphQL */ `
  subscription OnCreateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onCreateMessage(filter: $filter) {
      id
      from_username
      to_username
      server_timestamp
      participants_usernames
      message_text
      conversationID
      createdAt
      isRead
      updatedAt
      __typename
    }
  }
`;
export const onUpdateMessage = /* GraphQL */ `
  subscription OnUpdateMessage($filter: ModelSubscriptionMessageFilterInput) {
    onUpdateMessage(filter: $filter) {
      id
      from_username
      to_username
      server_timestamp
      participants_usernames
      message_text
      conversationID
      createdAt
      isRead
      updatedAt
      __typename
    }
  }
`;
export const onDeleteMessage = /* GraphQL */ `
  subscription OnDeleteMessage($filter: ModelSubscriptionMessageFilterInput) {
    onDeleteMessage(filter: $filter) {
      id
      from_username
      to_username
      server_timestamp
      participants_usernames
      message_text
      conversationID
      createdAt
      isRead
      updatedAt
      __typename
    }
  }
`;
export const onCreateConversation = /* GraphQL */ `
  subscription OnCreateConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onCreateConversation(filter: $filter) {
      id
      initiator_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      invited_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      participants_usernames
      Messages {
        items {
          id
          from_username
          to_username
          server_timestamp
          participants_usernames
          message_text
          conversationID
          createdAt
          isRead
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      conversationInitiator_usernameId
      conversationInvited_usernameId
      __typename
    }
  }
`;
export const onUpdateConversation = /* GraphQL */ `
  subscription OnUpdateConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onUpdateConversation(filter: $filter) {
      id
      initiator_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      invited_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      participants_usernames
      Messages {
        items {
          id
          from_username
          to_username
          server_timestamp
          participants_usernames
          message_text
          conversationID
          createdAt
          isRead
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      conversationInitiator_usernameId
      conversationInvited_usernameId
      __typename
    }
  }
`;
export const onDeleteConversation = /* GraphQL */ `
  subscription OnDeleteConversation(
    $filter: ModelSubscriptionConversationFilterInput
  ) {
    onDeleteConversation(filter: $filter) {
      id
      initiator_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      invited_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      participants_usernames
      Messages {
        items {
          id
          from_username
          to_username
          server_timestamp
          participants_usernames
          message_text
          conversationID
          createdAt
          isRead
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      conversationInitiator_usernameId
      conversationInvited_usernameId
      __typename
    }
  }
`;
export const onCreateUserProfile = /* GraphQL */ `
  subscription OnCreateUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
    $owner: String
  ) {
    onCreateUserProfile(filter: $filter, owner: $owner) {
      cognito_uuid
      display_name
      age
      location
      gender_identity
      sexual_orientation
      marital_status
      about_me
      avatarImage
      imagesList
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserProfile = /* GraphQL */ `
  subscription OnUpdateUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
    $owner: String
  ) {
    onUpdateUserProfile(filter: $filter, owner: $owner) {
      cognito_uuid
      display_name
      age
      location
      gender_identity
      sexual_orientation
      marital_status
      about_me
      avatarImage
      imagesList
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserProfile = /* GraphQL */ `
  subscription OnDeleteUserProfile(
    $filter: ModelSubscriptionUserProfileFilterInput
    $owner: String
  ) {
    onDeleteUserProfile(filter: $filter, owner: $owner) {
      cognito_uuid
      display_name
      age
      location
      gender_identity
      sexual_orientation
      marital_status
      about_me
      avatarImage
      imagesList
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage(
    $filter: ModelSubscriptionImageFilterInput
    $owner: String
  ) {
    onCreateImage(filter: $filter, owner: $owner) {
      s3_bucket_link
      config_json
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage(
    $filter: ModelSubscriptionImageFilterInput
    $owner: String
  ) {
    onUpdateImage(filter: $filter, owner: $owner) {
      s3_bucket_link
      config_json
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage(
    $filter: ModelSubscriptionImageFilterInput
    $owner: String
  ) {
    onDeleteImage(filter: $filter, owner: $owner) {
      s3_bucket_link
      config_json
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onCreateUserAccount = /* GraphQL */ `
  subscription OnCreateUserAccount(
    $filter: ModelSubscriptionUserAccountFilterInput
    $owner: String
  ) {
    onCreateUserAccount(filter: $filter, owner: $owner) {
      cognito_uuid
      membership_level
      signicat_session_status
      first_name
      last_name
      date_of_birth
      signicat_auth_date
      notification_preference
      my_conversations
      favorites
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateUserAccount = /* GraphQL */ `
  subscription OnUpdateUserAccount(
    $filter: ModelSubscriptionUserAccountFilterInput
    $owner: String
  ) {
    onUpdateUserAccount(filter: $filter, owner: $owner) {
      cognito_uuid
      membership_level
      signicat_session_status
      first_name
      last_name
      date_of_birth
      signicat_auth_date
      notification_preference
      my_conversations
      favorites
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteUserAccount = /* GraphQL */ `
  subscription OnDeleteUserAccount(
    $filter: ModelSubscriptionUserAccountFilterInput
    $owner: String
  ) {
    onDeleteUserAccount(filter: $filter, owner: $owner) {
      cognito_uuid
      membership_level
      signicat_session_status
      first_name
      last_name
      date_of_birth
      signicat_auth_date
      notification_preference
      my_conversations
      favorites
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
