import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 14px;

  background-color: ${({ buttonState }) => (buttonState ? "#84ACAE" : "")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img``;
