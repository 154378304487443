import React from "react";
import { View } from "@aws-amplify/ui-react";
import { localImages } from "../../images";
import styled from "styled-components";

const Container = styled.div`
  border: 4px solid rgba(243, 246, 246, 0.8);
  border-radius: 16px;
  width: 108px;
  height: 108px;
  position: absolute;
`;

export function GalleryPicture({
  pictureData,
  onDeleteImage,
  onSetProfileImage,
  avatarImage,
  isPersonalGuidancePage,
}) {
  return (
    <View width={"108px"} height={"108px"} as="div" position="relative">
      <>
        <Container />
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "16px",
            objectFit: "cover",
          }}
          src={pictureData ? pictureData : ""}
          alt="profile"
        />
        {!isPersonalGuidancePage && (
          <>
            <View
              height={"32px"}
              width={"32px"}
              borderRadius={"10px"}
              position="absolute"
              bottom="5px"
              right="5px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => onDeleteImage()}
            >
              <img src={localImages.deletePhotoButton} alt="Slett" />
            </View>
            <View
              height={"40px"}
              width={"40px"}
              borderRadius={"10px"}
              position="absolute"
              bottom="1px"
              left="1px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              onClick={() => onSetProfileImage()}
            >
              {avatarImage ? (
                <img src={localImages.saveProfileImage} alt="Lagre" />
              ) : (
                <img src={localImages.setProfileImage} alt="Legg til" />
              )}
            </View>
          </>
        )}
      </>
    </View>
  );
}
