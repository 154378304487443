/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      from_username
      to_username
      server_timestamp
      participants_usernames
      message_text
      conversationID
      createdAt
      isRead
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        from_username
        to_username
        server_timestamp
        participants_usernames
        message_text
        conversationID
        createdAt
        isRead
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByDate = /* GraphQL */ `
  query MessagesByDate(
    $conversationID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByDate(
      conversationID: $conversationID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        from_username
        to_username
        server_timestamp
        participants_usernames
        message_text
        conversationID
        createdAt
        isRead
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      initiator_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      invited_username {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      participants_usernames
      Messages {
        items {
          id
          from_username
          to_username
          server_timestamp
          participants_usernames
          message_text
          conversationID
          createdAt
          isRead
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      conversationInitiator_usernameId
      conversationInvited_usernameId
      __typename
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        initiator_username {
          cognito_uuid
          display_name
          age
          location
          gender_identity
          sexual_orientation
          marital_status
          about_me
          avatarImage
          imagesList
          createdAt
          updatedAt
          owner
          __typename
        }
        invited_username {
          cognito_uuid
          display_name
          age
          location
          gender_identity
          sexual_orientation
          marital_status
          about_me
          avatarImage
          imagesList
          createdAt
          updatedAt
          owner
          __typename
        }
        participants_usernames
        Messages {
          items {
            id
            from_username
            to_username
            server_timestamp
            participants_usernames
            message_text
            conversationID
            createdAt
            isRead
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        conversationInitiator_usernameId
        conversationInvited_usernameId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($cognito_uuid: String!) {
    getUserProfile(cognito_uuid: $cognito_uuid) {
      cognito_uuid
      display_name
      age
      location
      gender_identity
      sexual_orientation
      marital_status
      about_me
      avatarImage
      imagesList
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserProfiles = /* GraphQL */ `
  query ListUserProfiles(
    $cognito_uuid: String
    $filter: ModelUserProfileFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserProfiles(
      cognito_uuid: $cognito_uuid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognito_uuid
        display_name
        age
        location
        gender_identity
        sexual_orientation
        marital_status
        about_me
        avatarImage
        imagesList
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      s3_bucket_link
      config_json
      id
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        s3_bucket_link
        config_json
        id
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserAccount = /* GraphQL */ `
  query GetUserAccount($cognito_uuid: String!) {
    getUserAccount(cognito_uuid: $cognito_uuid) {
      cognito_uuid
      membership_level
      signicat_session_status
      first_name
      last_name
      date_of_birth
      signicat_auth_date
      notification_preference
      my_conversations
      favorites
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserAccounts = /* GraphQL */ `
  query ListUserAccounts(
    $cognito_uuid: String
    $filter: ModelUserAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserAccounts(
      cognito_uuid: $cognito_uuid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cognito_uuid
        membership_level
        signicat_session_status
        first_name
        last_name
        date_of_birth
        signicat_auth_date
        notification_preference
        my_conversations
        favorites
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
