import { useEffect, useState } from "react";
import { Flex, View, Text } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";
import { Icons } from "../../assets/icons";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export const MyProfile = () => {
  const [windowHeight, setWindowHeight] = useState(getWindowSize().innerHeight);

  const navigate = useNavigate();

  useEffect(() => {
    function handleWindowResize() {
      setWindowHeight(getWindowSize().innerHeight);
    }

    window.addEventListener("resize", handleWindowResize);

    function handleScrollUp() {
      setTimeout(window.scrollTo({ top: 0, left: 0 }), 1000);
    }
    handleScrollUp();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <View padding="44px 16px 0px 16px" height="100vh">
      <Text
        textAlign={"center"}
        color="#48676A"
        fontWeight="500"
        fontSize={"16px"}
      >
        Min profil
      </Text>

      <Flex
        direction="column"
        justifyContent="space-between"
        alignItems={"center"}
        height={windowHeight - 100}
        paddingTop={"44px"}
      >
        <Flex
          width={"100%"}
          height={"280px"}
          direction="column"
          justifyContent={"space-between"}
        >
          <View
            display="flex"
            direction="row"
            width="100%"
            alignItems="center"
            onClick={() => navigate("/profil/innstillinger")}
            height="46px"
          >
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Icons.profileSettingsIcon} alt="Profilinnstillinger" />
            </div>
            <Text fontSize={"20px"} color="#5B8386" fontWeight={"300"}>
              Profilinnstillinger
            </Text>
          </View>

          <View
            display="flex"
            direction="row"
            width="100%"
            alignItems="center"
            onClick={() => navigate("/varsler")}
            height="46px"
          >
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Icons.notificationIcon} alt="notifications" />
            </div>
            <Text fontSize={"20px"} color="#5B8386" fontWeight={"300"}>
              Varselinnstillinger
            </Text>
          </View>

          <View
            height="46px"
            display="flex"
            direction="row"
            width="100%"
            alignItems="center"
            onClick={() => navigate("/konto/innstillinger")}
          >
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Icons.settingsIcon} alt="settings" />
            </div>
            <Text fontSize={"20px"} color="#5B8386" fontWeight={"300"}>
              Kontoinnstillinger
            </Text>
          </View>

          <View
            height="46px"
            display="flex"
            direction="row"
            width="100%"
            alignItems="center"
            onClick={() => navigate("/anbefalinger")}
          >
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Icons.anbefalIcon} alt="anbefal" />
            </div>
            <Text fontSize={"20px"} color="#5B8386" fontWeight={"300"}>
              Anbefal Medforeldre til andre
            </Text>
          </View>
        </Flex>

        <View
          height="46px"
          width="100%"
          marginBottom={"53px"}
          onClick={() => navigate("/logg-ut")}
        >
          <View display="flex" direction="row" alignItems="center" width="100%">
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Icons.logOutIcon} alt="log out" />
            </div>

            <Text fontSize={"20px"} color="#5B8386" fontWeight={"300"}>
              Logg ut
            </Text>
          </View>
        </View>
      </Flex>
      <BottomNavigationBar buttonState="active" type={1} />
    </View>
  );
};
