import React, { useEffect, useState, useContext } from 'react';
import { View, Text, Flex } from '@aws-amplify/ui-react';
import { Layout } from '../../components/Layout';
import { API, Auth, Storage } from 'aws-amplify';
import imgUrl from '../../helpers/imageHelper';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { Icons } from '../../assets/icons';
import { getUserProfile, getUserAccount } from '../../graphql/queries';
import { createConversation, updateUserAccount } from '../../graphql/mutations';
import { Messages } from '../Messages/Messages';
import { useNavigate, useParams } from 'react-router-dom';
import { Loading as LoadingAnimation } from '../loading';
import { ConversationsContext } from '../../contexts/conversations';
import { BottomNavigationBar } from '../../components/UI/BottomNavigationBar';
import styled from 'styled-components';

const initialState = {
	conversationID: '',
	messageArray: [],
	profileData: [],
	userData: [],
};

export function MemberProfile() {
	const navigate = useNavigate();
	const urlParams = useParams();

	const [displayConversation, setDisplayConversation] = useState(initialState);
	const conversations = useContext(ConversationsContext);

	const [userProfileData, setUserProfileData] = useState({
		cognito_uuid: '',
		display_name: '',
		gender_identity: '',
		sexual_orientation: '',
		about_me: '',
		marital_status: '',
		favorites: [],
		imagesList: [],
		avatarImage: '',
		location: '',
		age: '',
	});

	const [loading, setLoading] = useState(true);

	const [imagesLists, setImagesLists] = useState([]);

	const [favouritesList, setFavouritesList] = useState([]);
	const [favouritesActive, setFavouritesActive] = useState(false);
	const [isFavourite, setIsFavourite] = useState('');

	// initial Images
	const images = ['', '', '', '', '', '', '', '', '', ''];

	const buttonStyle = {
		width: '40px',
		height: '160px',
		background: 'none',
		border: '0px',
		display: 'flex',
		justifyContent: 'center',
	};

	const slideShowProprietes = {
		duration: 5000,
		transitionDuration: 500,
		infinite: false,
		indicators: true,
		arrows: true,
		autoplay: false,

		prevArrow: (
			<button style={{ ...buttonStyle }}>
				<img
					style={{ height: '160px', width: '40px', zIndex: 1 }}
					src={Icons.slideLeft}
					alt="left"
				/>
			</button>
		),
		nextArrow: (
			<button style={{ ...buttonStyle }}>
				<img
					style={{ height: '160px', width: '40px', zIndex: 1 }}
					src={Icons.slideRight}
					alt="right"
				/>
			</button>
		),
	};

	async function getProfileData() {
		setLoading(true);

		try {
			const uuid = await Auth.currentUserInfo();

			const otherUserResponse = await API.graphql({
				query: getUserProfile,
				variables: {
					cognito_uuid: urlParams.id,
				},
			});

			if (otherUserResponse) {
				setUserProfileData({
					cognito_uuid: otherUserResponse?.data?.getUserProfile?.cognito_uuid,
					display_name: otherUserResponse?.data?.getUserProfile?.display_name,
					gender_identity: otherUserResponse?.data?.getUserProfile?.gender_identity,
					sexual_orientation: otherUserResponse?.data?.getUserProfile?.sexual_orientation,
					about_me: otherUserResponse?.data?.getUserProfile?.about_me,
					location: otherUserResponse?.data?.getUserProfile?.location,
					marital_status: otherUserResponse?.data?.getUserProfile?.marital_status,
					age: otherUserResponse?.data?.getUserProfile?.age,
					favorites: otherUserResponse?.data?.getUserProfile?.favorites,
					imagesList: otherUserResponse?.data?.getUserProfile?.imagesList,
					avatarImage: otherUserResponse?.data?.getUserProfile?.avatarImage,
				});
			}

			const thisUserResponse = await API.graphql({
				query: getUserAccount,
				variables: {
					cognito_uuid: uuid.attributes.sub,
				},
			});

			if (thisUserResponse) {
				setFavouritesList(thisUserResponse.data.getUserAccount.favorites);
			}

			let arrayImages = [];

			otherUserResponse?.data?.getUserProfile?.imagesList !== null &&
				otherUserResponse?.data?.getUserProfile?.imagesList.length > 0 &&
				(await Promise.all(
					otherUserResponse?.data?.getUserProfile?.imagesList.map(async data => {
						const signedUrl = await Storage.get(imgUrl.filename(data), {
							identityId: imgUrl.id(data),
							level: 'protected',
						});

						arrayImages.push({ name: data, url: signedUrl });
					}),
				));

			setImagesLists(arrayImages);
		} catch (err) {
			console.log(err);
		}

		setLoading(false);
	}

	async function openChat() {
		try {
			const uuid = await Auth.currentUserInfo();

			let wantedConversation = conversations.items?.find(
				conv =>
					conv.initiator_username.cognito_uuid === userProfileData.cognito_uuid ||
					conv.invited_username.cognito_uuid === userProfileData.cognito_uuid,
			);

			if (wantedConversation) {
				navigate(`/samtaler/${urlParams.id}`);
			} else {
				const input = {
					conversationInitiator_usernameId: uuid.attributes.sub,
					conversationInvited_usernameId: urlParams.id,
					participants_usernames: [uuid.attributes.sub, urlParams.id],
				};
				await API.graphql({
					query: createConversation,
					variables: {
						input: input,
					},
				});
				navigate(`/samtaler/${urlParams.id}`);
			}
		} catch (err) {
			console.log(err);
		}
	}

	useEffect(() => {
		getProfileData();
	}, []);

	async function handleFavorites() {
		setFavouritesActive(true);

		const uuid = await Auth.currentUserInfo();

		let copyFavouritesList = favouritesList;

		if (copyFavouritesList) {
			if (copyFavouritesList && copyFavouritesList.includes(userProfileData.cognito_uuid)) {
				copyFavouritesList.splice(copyFavouritesList.indexOf(userProfileData.cognito_uuid), 1);
			} else {
				copyFavouritesList && copyFavouritesList.push(userProfileData.cognito_uuid);
			}
		} else {
			copyFavouritesList = [userProfileData.cognito_uuid];
		}

		await API.graphql({
			query: updateUserAccount,
			variables: {
				input: {
					cognito_uuid: uuid.attributes.sub,
					favorites: copyFavouritesList,
				},
			},
		})
			.then(response => {
				setFavouritesList(response.data.updateUserAccount.favorites);
			})
			.catch(err => {
				console.log('error', err);
			});

		setFavouritesList(copyFavouritesList);
		setFavouritesActive(false);
	}

	//to trigger on update favourites
	useEffect(() => {
		const filteredFavourites =
			favouritesList &&
			favouritesList.length > 0 &&
			favouritesList.filter(data => {
				return data === userProfileData.cognito_uuid ? true : false;
			});

		setIsFavourite(filteredFavourites);
	}, [!favouritesActive]);

	//to display on rendering if favourites already exist
	useEffect(() => {
		const filteredFavourites =
			favouritesList &&
			favouritesList.length > 0 &&
			favouritesList.filter(data => {
				return data === userProfileData.cognito_uuid ? true : false;
			});

		setIsFavourite(filteredFavourites);
	}, [loading]);

	function backButton() {
		window.history.back();
	}

	function handleRedirectMemberProfile(data) {
		navigate(`/medlem/${urlParams.id}`);
	}

	useEffect(() => {
		function handleScrollUp() {
			window.scrollTo({ top: 0, left: 0 });
		}
		handleScrollUp();
	}, []);

	function onClickClose() {
		setDisplayConversation(initialState);
	}

	return (
		<>
			{displayConversation.conversationID === '' ? (
				<>
					<View
						height="34px"
						backgroundColor="#F3F7F7"
						position="fixed"
						top="0px"
						width="100%"
						style={{ zIndex: 10 }}
					/>
					<View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
						<View
							height="82px"
							backgroundColor="#F3F7F7"
							width="100%"
							alignItems="center"
							display="flex"
							justifyContent="center"
							position="relative"
						>
							<View>
								<div
									onClick={() => backButton()}
									style={{ position: 'absolute', left: '16px', top: '32px' }}
								>
									<img src={Icons.arrowBackBig} alt="Tilbake" />
								</div>
							</View>

							<Text
								textAlign={'center'}
								color={'#29666A'}
								fontSize="16px"
								style={{ position: 'absolute', top: '50%' }}
							>
								Medlemsprofil
							</Text>
						</View>
					</View>

					{loading ? (
						<LoadingAnimation />
					) : (
						<>
							<View height="100vh">
								<View
									paddingTop="60px"
									display="flex"
									direction="column"
									height={'100vh'}
									width={'100vw'}
								>
									<View marginTop="36px" width="100vw">
										{imagesLists.length > 0 && (
											<>
												{loading ? (
													<Slide {...slideShowProprietes}>
														{images.map(index => {
															return (
																<div
																	style={{
																		height: '293px',
																		margin: 'auto',
																		width: '100%',
																	}}
																	key={index}
																>
																	<div
																		style={{
																			width: '100%',
																			height: '293px',
																			backgroundSize: 'cover',
																			backgroundRepeat: 'no-repeat',
																		}}
																	/>
																</div>
															);
														})}
													</Slide>
												) : (
													<Slide {...slideShowProprietes}>
														{imagesLists.map((image, index) => {
															return (
																<div
																	style={{
																		height: '293px',
																		margin: 'auto',
																		width: '100%',
																	}}
																	key={index}
																>
																	<Image src={image.url} />
																</div>
															);
														})}
													</Slide>
												)}
											</>
										)}
									</View>
									<Layout>
										<View
											direction="row"
											justifyContent="space-between"
											display="flex"
											marginTop="18px"
											alignItems="center"
										>
											<Text color={'#4D6F6D'} fontSize="24px" fontWeight={'400'}>
												{!loading &&
													userProfileData.display_name !== '' &&
													`${userProfileData.display_name}, `}
												{!loading && userProfileData.age !== '' && userProfileData.age}
											</Text>

											<View onClick={() => openChat()}>
												<img src={Icons.memberChat} alt="message" />
											</View>
										</View>

										<View
											direction="row"
											justifyContent="flex-start"
											display="flex"
											marginTop="18px"
											alignItems="center"
										>
											<img src={Icons.locationPreviewProfile} alt="location" />

											<Text
												color="#869798"
												fontSize={'20px'}
												fontWeight={'500'}
												marginLeft="10px"
											>
												{!loading && userProfileData.location ? userProfileData.location : ''}
											</Text>
										</View>

										<Text fontSize={'16px'} color="#324846" marginTop={'21px'}>
											Om meg:
										</Text>

										<Text fontSize={'16px'} color="#869798">
											{!loading && userProfileData.about_me ? userProfileData.about_me : ''}
										</Text>

										<Text marginTop={'16px'} color="#324846">
											Kjønnsidentitet:
										</Text>

										<Text fontSize={'16px'} color="#869798">
											{!loading && userProfileData.gender_identity
												? userProfileData.gender_identity
												: ''}
										</Text>

										<Text marginTop={'16px'} color="#324846">
											Seksuelle orientering:
										</Text>

										<Text fontSize={'16px'} color="#869798">
											{!loading && userProfileData.sexual_orientation
												? userProfileData.sexual_orientation
												: ''}
										</Text>

										{/*  */}
										<Text marginTop={'16px'} color="#324846">
											Samlivsform:
										</Text>

										<Text fontSize={'16px'} color="#869798">
											{!loading && userProfileData.marital_status
												? userProfileData.marital_status
												: ''}
										</Text>

										<View
											marginTop="16px"
											width="100%"
											backgroundColor="#C6DFE1"
											height="2px"
										/>
										<View
											display="flex"
											direction="row"
											alignItems="center"
											marginTop="24px"
											marginBottom="88px"
										>
											{isFavourite && isFavourite.length > 0 ? (
												<>
													<img
														onClick={() => handleFavorites()}
														alt="favorites-active"
														src={Icons.favouriteActive}
														style={{ width: '64px', height: '64px' }}
													/>
												</>
											) : (
												<FavouritesContainer onClick={() => handleFavorites()}>
													<img
														alt="favorites-inactive"
														src={Icons.favoriteMember}
														style={{ width: '32px', height: '32px' }}
													/>
												</FavouritesContainer>
											)}

											<Text fontSize={'16px'} color="#869798" marginLeft={'16px'}>
												{isFavourite && isFavourite.length > 0
													? 'Medlem lagt til i favoritter'
													: 'Legg medlem til favoritter'}
											</Text>
										</View>
										<BottomNavigationBar type={3} buttonState="active" />
									</Layout>
								</View>
							</View>
						</>
					)}
				</>
			) : (
				<Flex direction="column" backgroundColor={'#FDFCFC'} height="100vh">
					<Messages
						conversationID={displayConversation.conversationID}
						messageArray={displayConversation.messageArray}
						close={() => onClickClose()}
						profileData={displayConversation.profileData}
						userData={displayConversation.userData}
						onClickMember={() => handleRedirectMemberProfile(displayConversation.profileData)}
					/>
				</Flex>
			)}
		</>
	);
}

const Image = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
`;

const FavouritesContainer = styled.div`
	border-radius: 18px;
	border: 1px solid #ecc63c;
	width: 64px;
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 4px 16px rgba(236, 197, 61, 0.3);
`;
