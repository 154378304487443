import React, { useEffect, useState } from "react";
import { View } from "@aws-amplify/ui-react";
import { localImages } from "../../images";
import styled from "styled-components";

const Container = styled.div`
  border: ${({ membersCard }) =>
    membersCard
      ? "2px solid rgba(243, 246, 246, 0.8)"
      : "4px solid rgba(243, 246, 246, 0.8)"};
  border-radius: 160px;
  width: ${({ membersCard }) => (membersCard ? "46px" : "160px")};
  height: ${({ membersCard }) => (membersCard ? "46px" : "160px")};
  position: absolute;
`;

export function UserAvatar({ userImage, gender, onClickImage, membersCard }) {
  const [defaultImage, setDefaultImage] = useState(localImages.other);

  useEffect(() => {
    switch (gender) {
      case "Mann":
        setDefaultImage(localImages.male);
        break;
      case "Kvinne":
        setDefaultImage(localImages.female);
        break;
      case "Ikke-binær":
        setDefaultImage(localImages.nonBinary);
        break;
      case "Annet":
        setDefaultImage(localImages.other);
        break;

      default:
        setDefaultImage(localImages.other);
        break;
    }
  }, [gender]);

  return (
    <View
      width={membersCard ? "46px" : "160px"}
      height={membersCard ? "46px" : "160px"}
      onClick={() => onClickImage()}
    >
      <View
        position="relative"
        width={membersCard ? "46px" : "160px"}
        height={membersCard ? "46px" : "160px"}
      >
        <Container membersCard={membersCard} />
        <img
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "160px",
            objectFit: "cover",
          }}
          src={userImage ? userImage : defaultImage}
          alt={"avatar"}
        />
      </View>
    </View>
  );
}
