import { useEffect, useState } from "react";
import { View, Flex, Text, TextField } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { getUserAccount } from "../../graphql/queries";
import { Layout } from "../../components/Layout";
import { Icons } from "../../assets/icons";
import { Spacer } from "../../components/UI/Spacer";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function AccountSettings() {
  const navigate = useNavigate();
  const [birthDate, setBirthDate] = useState("");
  const [userAccountData, setUserAccountData] = useState({
    cognito_uuid: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phone_number: "",
    email: "",
  });

  useEffect(() => {
    getAccountData();
  }, []);

  async function getAccountData() {
    const uuid = await Auth.currentUserInfo();
    setUserAccountData({
      cognito_uuid: uuid.attributes.sub,
      phone_number: uuid.attributes.phone_number,
      email: uuid.attributes.email,
    });

    await API.graphql({
      query: getUserAccount,
      variables: {
        cognito_uuid: uuid.attributes.sub,
      },
    })
      .then((response) => {
        setUserAccountData({
          cognito_uuid: response.data.getUserAccount.cognito_uuid,
          first_name: response.data.getUserAccount.first_name,
          last_name: response.data.getUserAccount.last_name,
          date_of_birth: response.data.getUserAccount.date_of_birth,
        });
      })
      .catch((err) => {
        console.log("failed...");
        console.log(err);
      });
  }

  useEffect(() => {
    if (userAccountData.date_of_birth && userAccountData.date_of_birth !== "") {
      const dateList = userAccountData.date_of_birth.split("-");
      const newString =
        dateList[2].substring(0, dateList[2].indexOf("T")) +
        "." +
        dateList[1] +
        "." +
        dateList[0];
      setBirthDate(newString);
    }
  }, [userAccountData]);

  return (
    <>
      <View
        height="34px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        width="100%"
        style={{ zIndex: 10 }}
      />
      <View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
        <View
          height="82px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <View>
            <div
              onClick={() => navigate("/min-profil")}
              style={{ position: "absolute", left: "16px", top: "32px" }}
            >
              <img src={Icons.arrowBackBig} alt="Tilbake" />
            </div>
          </View>

          <Text
            textAlign={"center"}
            color={"#29666A"}
            fontSize="16px"
            style={{ position: "absolute", top: "50%" }}
          >
            Din registrerte kontoinformasjon
          </Text>
        </View>
      </View>

      <Layout>
        <Flex marginTop={106} direction={"column"} alignItems={"center"}>
          <Text
            fontSize={16}
            fontWeight="400"
            textAlign={"center"}
            width="100%"
            color="#4A888C"
          >
            Dine kontoopplysninger
          </Text>
        </Flex>
        <Flex
          direction="column"
          textAlign="center"
          marginTop="large"
          marginBottom="xl"
          alignItems="center"
          gap="0.5rem"
        >
          <img src={Icons.info} alt="info" />
          <Text color="#899D9F">
            Dette er kontoinformasjonen vi har registrert på deg (disse
            opplysningene er ikke synlige for andre medlemmer). Informasjonen er
            hentet fra din registreringsprosess, blant annet via BankID. Dersom
            du har behov for å endre denne informasjonen, må du ta kontakt med
            oss.
          </Text>
        </Flex>
        <Flex as="form" direction="column">
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#869798",
            }}
            name="first_name"
            label="Fornavn"
            isReadOnly={true}
            defaultValue={userAccountData.first_name}
          />
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#869798",
            }}
            name="last_name"
            label="Etternavn"
            isReadOnly={true}
            defaultValue={userAccountData.last_name}
          />
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#869798",
            }}
            name="birth_date"
            label="Fødselsdato"
            isReadOnly={true}
            defaultValue={birthDate}
          />
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#869798",
            }}
            name="telephone"
            label="Telefonnummer"
            isReadOnly={true}
            defaultValue={userAccountData.phone_number}
          />
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#869798",
            }}
            name="email"
            label="E-post"
            isReadOnly={true}
            defaultValue={userAccountData.email}
          />
        </Flex>
        <Flex direction="column" alignItems="center" marginTop="large">
          <Text>Sikkerhet</Text>
          <Text fontWeight="bold" onClick={() => navigate("/nytt-passord")}>
            Endre ditt passord
          </Text>
        </Flex>

        <Spacer height={88} />
        <BottomNavigationBar buttonState="active" type={1} />
      </Layout>
    </>
  );
}
