import { Routes, Route } from "react-router-dom";
import Intro from "./pages/intro";
import Splash from "./pages/splash";
import {
  MedforeldreMenu,
  ResearchAndExperience,
  CoParentingJourney,
  ChildrenWithTwoHomes,
  UnconventionalFamily,
  LoveAndSafe,
  ParentHood,
  CoParenting,
  PersonalGuidance,
  ContactForm,
  AboutMedparent,
  AboutUs,
  ContactMedforeldre,
} from "./pages/MedforeldreMenu";
import {
  BankIDVerification,
  BankIDSuccess,
  BankIDFailed,
  SignIn,
  SignUp,
  ForgotPassword,
  PrivacyPolicy,
} from "./pages/Authenticate";
import {
  BankIDAccountDetails,
  CreateProfileCard,
  ChooseLocation,
  ChooseGenderIdentity,
  ChooseMaritalStatus,
  ChooseSexualOrientation,
  ChooseDescription,
} from "./pages/CreateProfile";
import {
  MyProfile,
  AccountSettings,
  NotificationSettings,
  SubscriptionPlans,
  ProfileSettings,
  Recommendation,
  MaritalStatusSettings,
  AccountDetails,
  SexualOrientationSettings,
  LocationSettings,
  GenderIdentitySettings,
  PreviewProfile,
  NewPassword,
  ProfileCreated,
  LogOut,
  DeleteImage,
  CreatedPreviewProfile,
} from "./pages/MyProfile";
import { Conversations } from "./pages/Messages";
import { Favourites } from "./pages/Favourites/Favourites";
import { RequireAuth } from "./RequireAuth";
import { RequireBankIDVerification } from "./RequireBankIDVerification";
import {
  FilterCohabitation,
  FilterOrientation,
  FilterMembers,
  FilterLocations,
  FilterGender,
  MembersList,
} from "./pages/MembersList";
import { MemberProfile } from "./pages/MembersList/MemberProfile";
import { ErrorScreen } from "./pages/ErrorScreen/ErrorScreen";
import { MessagesRoute } from "./pages/Messages/MessagesRoute";

function AllRoutes({ notShowIntro, showSplash }) {
  return (
    <>
      <Routes>
        {/* Page not found route redirecting to SignIn if not authenticated and to BankID if not bankID verified*/}
        <Route path="/logg-inn" element={<SignIn />} />
        <Route path="/registrering" element={<SignUp />} />
        <Route path="/glemt-passord" element={<ForgotPassword />} />
        {/* Not showing the intro site if the user has previously checked the "not show this text again" checkbox */}
        <Route
          path="/"
          element={
            showSplash ? <Splash /> : notShowIntro ? <SignIn /> : <Intro />
          }
        />
        <Route
          path="/vilkar-og-personvernerklaring"
          element={<PrivacyPolicy />}
        />
        <Route path="/barn-med-to-hjem" element={<ChildrenWithTwoHomes />} />
        <Route
          path="/utradisjonelle-familier"
          element={<UnconventionalFamily />}
        />
        <Route path="/elsket-og-trygg" element={<LoveAndSafe />} />
        <Route path="/gode-foreldreskap" element={<ParentHood />} />
        <Route path="/medforeldreskap" element={<CoParenting />} />

        <Route path="/personlig-veiledning" element={<PersonalGuidance />} />

        <Route path="/kontakt-radgiver" element={<ContactForm />} />

        <Route path="/om-medforeldre-as" element={<AboutMedparent />} />

        <Route path="/om-oss" element={<AboutUs />} />
        <Route path="/kontakt-oss" element={<ContactMedforeldre />} />
        <Route path={"*"} element={<ErrorScreen />} />
        <Route element={<RequireAuth />}>
          <Route path="/bankIDFailed" element={<BankIDFailed />} />
          <Route element={<RequireBankIDVerification />}>
            <Route path="/bankID" element={<BankIDVerification />} />
            <Route path="/bankIDSuccess" element={<BankIDSuccess />} />
            <Route
              path="/bankid-kontoinformasjon"
              element={<BankIDAccountDetails />}
            />
            <Route path="/lag-profil" element={<CreateProfileCard />} />
            <Route path="/velg-kjonn" element={<ChooseGenderIdentity />} />
            <Route path="/velg-lokasjon" element={<ChooseLocation />} />
            <Route path="/velg-sivilstatus" element={<ChooseMaritalStatus />} />
            <Route
              path="/velg-seksuell-orientering"
              element={<ChooseSexualOrientation />}
            />
            <Route path="/velg-beskrivelse" element={<ChooseDescription />} />
            <Route path="/konto" element={<AccountDetails />} />
            <Route
              exact
              path="/konto/innstillinger"
              element={<AccountSettings />}
            />
            <Route path="/profil-opprettet" element={<ProfileCreated />} />
            <Route
              exact
              path="/profil/innstillinger"
              element={<ProfileSettings />}
            />
            <Route path="/logg-ut" element={<LogOut />} />
            <Route path="/abonnement" element={<SubscriptionPlans />} />
            <Route path="/varsler" element={<NotificationSettings />} />
            <Route path="/anbefalinger" element={<Recommendation />} />
            <Route
              path="/lokasjonsinnstillinger"
              element={<LocationSettings />}
            />
            <Route
              path="/kjonnsidentitetsinnstillinger"
              element={<GenderIdentitySettings />}
            />
            <Route
              path="/seksuellorienteringsinnstillinger"
              element={<SexualOrientationSettings />}
            />
            <Route
              path="/sivilstatusinnstillinger"
              element={<MaritalStatusSettings />}
            />
            <Route path="/slett-bilde" element={<DeleteImage />} />
            {/* <Route
              path="/accountDetailsDeleteImage"
              element={<AccountDetailsDeleteImage />}
            /> */}
            <Route path="/forhandsvis-profil" element={<PreviewProfile />} />
            <Route
              path="/forhandsvis-ny-profil"
              element={<CreatedPreviewProfile />}
            />
            <Route path="/nytt-passord" element={<NewPassword />} />

            <Route path="/filter-medlemmer" element={<FilterMembers />} />
            <Route
              path="/filter-samlivsform"
              element={<FilterCohabitation />}
            />
            <Route path="/filter-kjonn" element={<FilterGender />} />
            <Route path="/filter-lokasjon" element={<FilterLocations />} />
            <Route
              path="/filter-seksuell-orientering"
              element={<FilterOrientation />}
            />
            <Route path="/medlem/:id" element={<MemberProfile />} />
            <Route path="/min-profil" element={<MyProfile />} />
            <Route path="/medforeldrereisen" element={<CoParentingJourney />} />
            <Route path="/medforeldre" element={<MedforeldreMenu />} />
            <Route
              path="/forskning-og-erfaringer"
              element={<ResearchAndExperience />}
            />
            <Route path="/medlemmer" element={<MembersList />} />
            <Route path="/favoritter" element={<Favourites />} />
            <Route path="/samtaler" element={<Conversations />} />
            <Route path="/samtaler/:id" element={<MessagesRoute />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default AllRoutes;
