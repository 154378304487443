import React, { useEffect, useState, useContext } from "react";
import { Text, View, Flex } from "@aws-amplify/ui-react";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";
import { API, Auth, Storage } from "aws-amplify";
import imgUrl from "../../helpers/imageHelper";
import { listUserProfiles, getUserProfile } from "../../graphql/queries";
import { MembersCard } from "../../components/UI/MembersCard";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { FiltersApplyContext } from "../../contexts/contexts";
import { Loading as LoadingAnimation } from "../loading";

export function MembersList() {
  const [loading, setLoading] = useState(true);
  const [newMembers, setNewMembers] = useState([]);
  const [favouritesList, setFavouritesList] = useState();
  const [allMembers, setAllMembers] = useState([]);

  const [numberOfFilters, setNumberOfFilters] = useState(0);

  const navigate = useNavigate();

  const {
    applyFiltersGender,
    applyFiltersLocations,
    applyFiltersOrientation,
    applyFiltersCohabitation,
  } = useContext(FiltersApplyContext);

  // check filters
  useEffect(() => {
    let allActiveFilter = 0;

    const numberOfCohabitationFiltered = Object.keys(
      applyFiltersCohabitation.length > 0 ? applyFiltersCohabitation : []
    ).length;
    const numberOfLocationsFiltered = Object.keys(
      applyFiltersLocations.length > 0 ? applyFiltersLocations : []
    ).length;
    const numberOfOrientationsFiltered = Object.keys(
      applyFiltersOrientation.length > 0 ? applyFiltersOrientation : []
    ).length;
    const numberOfGenderFiltered = Object.keys(
      applyFiltersGender.length > 0 ? applyFiltersGender : []
    ).length;

    allActiveFilter =
      numberOfCohabitationFiltered +
      numberOfGenderFiltered +
      numberOfLocationsFiltered +
      numberOfOrientationsFiltered;

    setNumberOfFilters(allActiveFilter);
  }, [
    applyFiltersGender,
    applyFiltersCohabitation,
    applyFiltersLocations,
    applyFiltersOrientation,
  ]);

  async function getMembers() {
    setLoading(true);
    const uuid = await Auth.currentUserInfo();
    const session = await Auth.currentSession()
    console.log("getMembers. uuid.username: ", uuid?.username)
    console.log("getMembers. session.idtoken.cognito:username: ", session.idToken.payload["cognito:username"])

    try {
      const favouritesData = await API.graphql({
        query: getUserProfile,
        variables: {
          cognito_uuid: uuid.attributes.sub,
        },
      });

      setFavouritesList(favouritesData.data.getUserProfile.favorites);

      const genderFilters = { or: [] };
      const orientationFilters = { or: [] };
      const locationsFilters = { or: [] };
      const maritalStatusFilters = { or: [] };

      if (applyFiltersOrientation.length > 0) {
        applyFiltersOrientation.forEach((orientation) => {
          orientationFilters.or.push({
            sexual_orientation: {
              eq: orientation,
            },
          });
        });
      }
      if (applyFiltersGender.length > 0) {
        applyFiltersGender.forEach((gender) => {
          genderFilters.or.push({
            gender_identity: {
              eq: gender,
            },
          });
        });
      }
      if (applyFiltersCohabitation.length > 0) {
        applyFiltersCohabitation.forEach((maritalStatus) => {
          maritalStatusFilters.or.push({
            marital_status: {
              eq: maritalStatus,
            },
          });
        });
      }
      if (applyFiltersLocations.length > 0) {
        applyFiltersLocations.forEach((location) => {
          locationsFilters.or.push({
            location: {
              eq: location,
            },
          });
        });
      }

      let allChosenFilters = [
        genderFilters,
        orientationFilters,
        locationsFilters,
        maritalStatusFilters,
      ];

      allChosenFilters = allChosenFilters.filter((item) => {
        return item.or.length !== 0;
      });

      const response = await API.graphql({
        query: listUserProfiles,

        variables: {
          limit: 10000,
          filter: {
            and: allChosenFilters,
          },
        },
      });

      const membersListWithoutOwner =
        response.data.listUserProfiles.items.filter((data) => {
          return data.cognito_uuid !== uuid.attributes.sub;
        });

      const listOfMembersAndAvatars = await Promise.all(
        membersListWithoutOwner.map(async (member) => {
          if (member.avatarImage) {
            const imageUrl = await Storage.get(
              imgUrl.filename(member.avatarImage),
              {
                identityId: imgUrl.id(member.avatarImage),
                level: "protected",
              }
            );

            return {
              ...member,
              userAvatarImage: imageUrl,
            };
          } else {
            return member;
          }
        })
      );
      // Edit the variable below to set number of days to count new members.
      const daysCountedAsNew = 3;

      const recentlyAddedMembers = listOfMembersAndAvatars.filter(
        (member) =>
          new Date() - new Date(member.createdAt) <
          1000 * 60 * 60 * 24 * daysCountedAsNew
      );
      const establishedMembers = listOfMembersAndAvatars.filter(
        (member) =>
          new Date() - new Date(member.createdAt) >
          1000 * 60 * 60 * 24 * daysCountedAsNew
      );

      establishedMembers.sort(
        (a, b) => new Date(a.createdAt) < new Date(b.createdAt)
      );
      recentlyAddedMembers.sort(
        (a, b) => new Date(a.createdAt) < new Date(b.createdAt)
      );

      setAllMembers(establishedMembers);
      setNewMembers(recentlyAddedMembers);
    } catch (err) {
      console.log("error:", err);
    }

    setLoading(false);
  }

  useEffect(() => {
    getMembers();
    function handleScrollUp() {
      window.scrollTo({ top: 0, left: 0 });
    }
    handleScrollUp();
  }, []);

  function handleRedirectMemberProfile(data) {
    navigate(`/medlem/${data.cognito_uuid}`);
  }

  return (
    <>
      {loading ? (
        <>
          <TopNavigationBar
            activeFilters={numberOfFilters}
            noIconBack={true}
            header={"Medlemsliste"}
            onFilterClick={() => navigate("/filter-medlemmer")}
            isMemberListPage
          />
          <LoadingAnimation />
        </>
      ) : (
        <Layout>
          <TopNavigationBar
            activeFilters={numberOfFilters}
            noIconBack={true}
            header={"Medlemsliste"}
            onFilterClick={() => navigate("/filter-medlemmer")}
            isMemberListPage
          />
          <View>
            <Flex direction="column" paddingTop="100px" marginBottom="82px">
              {!loading && newMembers.length > 0 && (
                <>
                  <Text color="#869798" fontSize={"14px"}>
                    Nye medlemmer lagt til
                  </Text>
                  {newMembers &&
                    newMembers.map((memberData, index) => {
                      let isFavourite =
                        favouritesList &&
                        favouritesList.includes(memberData.cognito_uuid)
                          ? true
                          : false;

                      return (
                        <View key={index}>
                          <MembersCard
                            isFavourite={isFavourite}
                            onClickMember={() =>
                              handleRedirectMemberProfile(memberData)
                            }
                            key={memberData.id}
                            membersData={memberData}
                            areNewMembers
                          />
                        </View>
                      );
                    })}
                </>
              )}

              {!loading && allMembers.length > 0 && (
                <>
                  <Text color="#869798" fontSize={"14px"} marginTop="24px">
                    Medlemmer
                  </Text>

                  {allMembers.map((memberData, index) => {
                    let isFavourite =
                      favouritesList &&
                      favouritesList.includes(memberData.cognito_uuid)
                        ? true
                        : false;

                    return (
                      <View key={index}>
                        <MembersCard
                          isFavourite={isFavourite}
                          onClickMember={() =>
                            handleRedirectMemberProfile(memberData)
                          }
                          key={memberData.id}
                          membersData={memberData}
                        />
                      </View>
                    );
                  })}
                </>
              )}
              {!loading && allMembers.length < 1 && newMembers.length < 1 && (
                <Text color="#869798" fontSize={"14px"} marginTop="24px">
                  Ingen medlemmer funnet
                </Text>
              )}
            </Flex>
          </View>
        </Layout>
      )}
      <BottomNavigationBar type={3} buttonState="active" />
    </>
  );
}
