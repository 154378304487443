import { useEffect, useState } from "react";
import { Text, View } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate } from "react-router-dom";
import { localImages } from "../../images";
import { Icons } from "../../assets/icons";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function UnconventionalFamily() {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0);

  const handleNavigation = (e) => {
    setScroll(e.currentTarget.scrollY);
  };

  useEffect(() => {
    setScroll(window.scrollY);

    window.addEventListener("scroll", (e) => handleNavigation(e));
  }, []);

  function handleScrollUp() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  return (
    <View>
      <TopNavigationBar
        noIconBack
        header={"Forskning og erfaringer"}
        isMedforeldreMenuPages
        onArrowBackClick={() => navigate("/forskning-og-erfaringer")}
      />
      <Layout>
        <Text
          textAlign={"center"}
          paddingTop="112px"
          fontSize={"20px"}
          fontWeight="500"
          color="#4D6F6D"
        >
          Barn i utradisjonelle familier
        </Text>

        <View padding="0px 16px 0px 16px">
          <Text
            textAlign={"left"}
            marginTop="24px"
            fontSize={"16px"}
            fontWeight="400"
            color="#4D6F6D"
            marginBottom={"32px"}
          >
            Under arbeid.
          </Text>
        </View>

        <View
          width="100%"
          height="1px"
          backgroundColor="#9CBCBF"
          marginBottom="32px"
        />

        <Text
          textAlign={"center"}
          color="#869798"
          fontSize={"14px"}
          marginBottom="24px"
        >
          Flere ressurser
        </Text>

        <View marginBottom="32px">
          <Text
            textAlign={"center"}
            color="#4E888D"
            fontSize={"14px"}
            fontWeight="700"
            marginBottom={"22px"}
          >
            Linker kommer
          </Text>

          <Text
            textAlign={"center"}
            color="#4E888D"
            fontSize={"14px"}
            fontWeight="700"
            marginBottom={"22px"}
          >
            Linker kommer
          </Text>

          <Text
            textAlign={"center"}
            color="#4E888D"
            fontSize={"14px"}
            fontWeight="700"
            marginBottom={"22px"}
          >
            Linker kommer
          </Text>
        </View>
        <View
          width="100%"
          height="1px"
          backgroundColor="#9CBCBF"
          marginBottom="32px"
        />

        <Text color="#869798" fontSize={"14px"} textAlign="center">
          Våre ambassadørers mening / erfaring på dette området
        </Text>

        <View
          display="flex"
          direction="column"
          alignItems="center"
          marginBottom="93px"
        >
          <View width="46px" height="46px" borderRadius="23px" marginTop="24px">
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "23px",
                objectFit: "fill",
              }}
              src={localImages.userFeedback}
              alt="user-avatar"
            />
          </View>

          <Text
            fontSize={"16px"}
            color="#4D6F6D"
            lineHeight={"24px"}
            marginBottom="4px"
          >
            Morten, Jostein & Ylva
          </Text>

          <Text fontSize={"12px"} color="#5D7679" textAlign={"center"}>
            “under arbeid”
          </Text>

          <View width="46px" height="46px" borderRadius="23px" marginTop="24px">
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "23px",
                objectFit: "fill",
              }}
              src={localImages.userFeedback}
              alt="user-avatar"
            />
          </View>

          <Text
            fontSize={"16px"}
            color="#4D6F6D"
            lineHeight={"24px"}
            marginBottom="4px"
          >
            Anne & Thomas
          </Text>

          <Text fontSize={"12px"} color="#5D7679" textAlign={"center"}>
            “under arbeid”
          </Text>

          <View width="46px" height="46px" borderRadius="23px" marginTop="24px">
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "23px",
                objectFit: "fill",
              }}
              src={localImages.userFeedback}
              alt="user-avatar"
            />
          </View>

          <Text
            fontSize={"16px"}
            color="#4D6F6D"
            lineHeight={"24px"}
            marginBottom="4px"
          >
            Medforeldre-ambassadører
          </Text>

          <Text fontSize={"12px"} color="#5D7679" textAlign={"center"}>
            “under arbeid”
          </Text>

          {scroll > 400 && (
            <View
              onClick={() => handleScrollUp()}
              width="40px"
              height="40px"
              backgroundColor="#E0EAEB"
              borderRadius="8px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="fixed"
              bottom={"80px"}
              right={"16px"}
            >
              <img src={Icons.arrowUp} alt="arrow-up" />
            </View>
          )}
        </View>
      </Layout>
      <BottomNavigationBar buttonState="active" type={2} />
    </View>
  );
}
