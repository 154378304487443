import React from "react";
import styled from "styled-components";

const Container = styled.div`
  background: #f2f6f6;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export function Loading() {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <div className="loadingio-spinner-rolling-zctqyswbwvg">
          <div className="ldio-d5ovkz6p4dk">
            <div></div>
          </div>
        </div>
      </div>
    </Container>
  );
}
