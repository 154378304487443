import { Text, View } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate } from "react-router-dom";
import { localImages } from "../../images";
import { Icons } from "../../assets/icons";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function AboutUs() {
  const navigate = useNavigate();

  return (
    <>
      <TopNavigationBar
        header="Om oss"
        isMedforeldreMenuPages
        onArrowBackClick={() => navigate("/om-medforeldre-as")}
        noIconBack
        onCancelClick={() => navigate("/om-medforeldre-as")}
      />
      <Layout>
        <View padding="104px 19px 0px 19px">
          <View display="flex" justifyContent="center" marginBottom="32px">
            <img src={Icons.medforeldreLogo} alt="logo" />
          </View>
          <Text fontSize={"16px"} color="#4D6F6D">
            Vi startet Medforeldre AS fordi vi selv er, eller har ønske om å
            bli, nettopp medforeldre. Derfor har vi førstehåndskunnskap om både
            det fine og vanskelige i prosesser knyttet til barneønske og
            alternative familiekonstellasjoner
          </Text>

          <Text fontSize={"16px"} color="#4D6F6D" marginTop="16px">
            I teamet er vi nå en medforeldre-guide, familieterapeut, psykologer,
            advokat og medforeldre-ambassadører. De fleste av oss holder til i
            Oslo og omegn til daglig.
          </Text>

          <View marginTop="16px">
            <img
              width={"100%"}
              src={localImages.aboutUsImage}
              alt="landscape"
              style={{ borderRadius: "16px" }}
            />
          </View>

          <Text
            textAlign={"left"}
            fontSize={"16px"}
            color="#4D6F6D"
            width={"100%"}
            marginTop="8px"
          >
            Startskuddet for Medforeldre AS gikk i juni 2022. Da mottok vi tilskudd 
            fra Innovasjon Norge til å finne ut om det faktisk kunne være et marked 
            for en slik tjeneste; altså om vi var mange nok som kunne tenke seg å bli 
            medforeldre i Norge. Responsen var svært positiv. I mars 2023 lanserte vi 
            tjenesten og vi jobber nå kontinuerlig med å bygge opp en robust tjeneste 
            som tilbyr foreldrerådgiving og medforeldrematching, slik at vi på best 
            mulig måte kan rådgi og rettlede mennesker som søker foreldreliv i trygge 
            og stabile foreldreskap.
          </Text>
          <Text
            textAlign={"left"}
            fontSize={"16px"}
            color="#4D6F6D"
            width={"100%"}
            marginTop="16px"
          >
            I alt vi gjør er ledetråden vår å bidra til at våre medlemmer kan ta gode 
            og informerte valg, og at vi bidrar til økt livskvalitet for barn og 
            foreldre gjennom trygge og stabile foreldreskap..
          </Text>

          <Text
            textAlign={"left"}
            fontSize={"16px"}
            color="#4D6F6D"
            width={"100%"}
            marginTop="16px"
            marginBottom="99px"
          >
            Vi ønsker deg lykke til på din Medforeldre-reise!
          </Text>
        </View>
      </Layout>
      <BottomNavigationBar buttonState="active" type={2} />
    </>
  );
}
