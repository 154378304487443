import React, { useState, useContext, useEffect } from "react";
import { View, Text, RadioGroupField, Radio } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { ProfileDataContext } from "../../contexts/contexts";
import { Icons } from "../../assets/icons";

export function ChooseLocation() {
  const { setProfileSettingsValues, profileSettingsValues } =
    useContext(ProfileDataContext);

  const [location, setLocation] = useState(profileSettingsValues.location);

  const navigate = useNavigate();

  function handleLocation(value) {
    setLocation(value);
    setProfileSettingsValues((prevData) => ({
      ...prevData,
      location: value,
    }));
    navigate("/lag-profil");
  }

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <View height="100vh">
      <View
        height="32px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        left="0px"
        right="0px"
        width="100%"
      />
      <View position="fixed" top="32px" left="0px" right="0px" width="100%">
        <View
          height="48px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <Text textAlign={"center"} color={"#29666A"} fontSize="16px">
            Velg ditt bosted
          </Text>

          <img
            onClick={() => navigate("/lag-profil")}
            style={{ position: "absolute", right: "16px", bottom: "5px" }}
            src={Icons.cancel}
            alt="cancel"
          />
        </View>
      </View>

      <View
        direction="column"
        display="flex"
        alignItems="center"
        paddingTop="107px"
      >
        <img src={Icons.info} alt="info" />

        <Text>Velg fylket du bor i.</Text>
      </View>
      <View
        margin="0 16px 0 16px"
        display="flex"
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <RadioGroupField
          marginTop={"40px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Agder")}
            value={""}
            width={"100%"}
            checked={location === "Agder" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Agder
            </Text>
          </Radio>
        </RadioGroupField>
        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Innlandet")}
            value=""
            width={"100%"}
            checked={location === "Innlandet" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Innlandet
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Møre og Romsdal")}
            value=""
            width={"100%"}
            checked={location === "Møre og Romsdal" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Møre og Romsdal
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Nordland")}
            value=""
            width={"100%"}
            checked={location === "Nordland" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Nordland
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Oslo")}
            value=""
            width={"100%"}
            checked={location === "Oslo" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Oslo
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Rogaland")}
            value=""
            width={"100%"}
            checked={location === "Rogaland" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Rogaland
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Vestfold og Telemark")}
            value=""
            width={"100%"}
            checked={location === "Vestfold og Telemark" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Vestfold og Telemark
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Troms og Finnmark")}
            value=""
            width={"100%"}
            checked={location === "Troms og Finnmark" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Troms og Finnmark
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Trøndelag")}
            value=""
            width={"100%"}
            checked={location === "Trøndelag" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Trøndelag
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
        >
          <Radio
            onClick={() => handleLocation("Vestland")}
            value=""
            width={"100%"}
            checked={location === "Vestland" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Vestland
            </Text>
          </Radio>
        </RadioGroupField>

        <RadioGroupField
          marginTop={"24px"}
          width="100%"
          height={"48px"}
          backgroundColor={"#F9FBFB"}
          borderRadius="4px"
          justifyContent={"flex-start"}
          direction="row"
          alignItems="center"
          marginBottom={"53px"}
        >
          <Radio
            onClick={() => handleLocation("Viken")}
            value={null}
            width={"100%"}
            checked={location === "Viken" ? true : false}
            readOnly={true}
          >
            <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
              Viken
            </Text>
          </Radio>
        </RadioGroupField>
      </View>
    </View>
  );
}
