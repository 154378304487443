/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import IconsNew from "./IconsNew";
export default function AlertN(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        heading: { display: "block" },
        body: {},
        content: { alignItems: "center" },
        frame: {},
        IconsNew: {},
        AlertN: {},
      },
      variantValues: { property1: "with_title" },
    },
    {
      overrides: {
        heading: {},
        body: {},
        content: {},
        frame: {},
        IconsNew: {},
        AlertN: {},
      },
      variantValues: { property1: "default" },
    },
    {
      overrides: {
        heading: { display: "block" },
        body: { textAlign: "left" },
        content: {},
        frame: { justifyContent: "flex-start" },
        IconsNew: { display: "block" },
        AlertN: {},
      },
      variantValues: { property1: "with_title_&_icon_button" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="16px"
      direction="row"
      width="358px"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      borderRadius="4px"
      padding="12px 16px 12px 16px"
      backgroundColor="rgba(250,235,235,1)"
      display="flex"
      {...getOverrideProps(overrides, "AlertN")}
      {...rest}
    >
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "frame")}
      >
        <Flex
          gap="0"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="flex-start"
          grow="1"
          shrink="1"
          basis="0"
          position="relative"
          padding="0px 0px 0px 0px"
          display="flex"
          {...getOverrideProps(overrides, "content")}
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="700"
            color="rgba(168,46,48,1)"
            lineHeight="24px"
            textAlign="left"
            display="none"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Error"
            {...getOverrideProps(overrides, "heading")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="400"
            color="rgba(168,46,48,1)"
            lineHeight="21px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore."
            {...getOverrideProps(overrides, "body")}
          ></Text>
        </Flex>
      </Flex>
      <IconsNew
        width="24px"
        height="24px"
        display="none"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        property1="cancel"
        {...getOverrideProps(overrides, "IconsNew")}
      ></IconsNew>
    </Flex>
  );
}
