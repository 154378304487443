/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon } from "@aws-amplify/ui-react";
export default function Verifiedlarge(props) {
  const { overrides, ...rest } = props;
  return (
    <Icon
      width="128px"
      height="128px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      viewBox={{ minX: 0, minY: 0, width: 128, height: 128 }}
      paths={[
        {
          d: "M95.25 48C95.25 74.0955 74.0955 95.25 48 95.25L48 96.75C74.9239 96.75 96.75 74.9239 96.75 48L95.25 48ZM48 95.25C21.9045 95.25 0.75 74.0955 0.75 48L-0.75 48C-0.75 74.9239 21.0761 96.75 48 96.75L48 95.25ZM0.75 48C0.75 21.9045 21.9045 0.75 48 0.75L48 -0.75C21.0761 -0.75 -0.75 21.0761 -0.75 48L0.75 48ZM48 0.75C74.0955 0.75 95.25 21.9045 95.25 48L96.75 48C96.75 21.0761 74.9239 -0.75 48 -0.75L48 0.75Z",
          stroke: "rgba(156,188,191,1)",
          fillRule: "nonzero",
          strokeLinejoin: "round",
          strokeWidth: 1,
          style: { transform: "translate(12.5%, 12.5%)" },
        },
        {
          d: "M0.53033 10.1363C0.237437 9.84344 -0.237437 9.84344 -0.53033 10.1363C-0.823223 10.4292 -0.823223 10.9041 -0.53033 11.197L0.53033 10.1363ZM10.6667 21.3333L10.1363 21.8637C10.4292 22.1566 10.9041 22.1566 11.197 21.8637L10.6667 21.3333ZM32.5303 0.53033C32.8232 0.237437 32.8232 -0.237437 32.5303 -0.53033C32.2374 -0.823223 31.7626 -0.823223 31.4697 -0.53033L32.5303 0.53033ZM-0.53033 11.197L10.1363 21.8637L11.197 20.803L0.53033 10.1363L-0.53033 11.197ZM11.197 21.8637L32.5303 0.53033L31.4697 -0.53033L10.1363 20.803L11.197 21.8637Z",
          stroke: "rgba(156,188,191,1)",
          fillRule: "nonzero",
          strokeLinejoin: "round",
          strokeWidth: 1,
          style: { transform: "translate(37.5%, 41.67%)" },
        },
      ]}
      {...getOverrideProps(overrides, "Verifiedlarge")}
      {...rest}
    ></Icon>
  );
}
