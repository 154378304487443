import { useState } from "react";
import { Text, Heading, Flex, PasswordField } from "@aws-amplify/ui-react";
import {
  SecondaryButton,
  IconsNew,
  AlertN,
  Verifiedlarge,
} from "../../ui-components";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

export function NewPassword() {
  const [loading, setLoading] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(null);
  const [screenType, setScreenType] = useState("changePassword");
  const navigate = useNavigate();
  const [input, setInput] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function onInputChange(e) {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
    validateInput(e);
  }
  function validateInput(e) {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        //   case "currentPassword":
        //     if (!value) {
        //       stateObj[name] = "Vennligst skriv inn et passord.";
        //     } else if (
        //       !value.match(
        //         /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
        //       )
        //     ) {
        //       stateObj[name] =
        //         "Passordet ditt må ha minst én stor bokstav, minst ett tall, og minst ett spesialtegn(f.eks. !@#%^&*).";
        //     } else if (
        //       input.confirmPassword &&
        //       value !== input.confirmPassword
        //     ) {
        //       stateObj["confirmPassword"] =
        //         "Passordet ditt og passordbekreftelsen er ikke like.";
        //     } else {
        //       stateObj["confirmPassword"] = input.confirmPassword
        //         ? ""
        //         : error.confirmPassword;
        //     }
        //     break;

        case "newPassword":
          if (!value) {
            stateObj[name] = "Vennligst skriv inn et passord.";
          } else if (
            !value.match(
              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/
            )
          ) {
            stateObj[name] =
              "Passordet ditt må ha minst én stor bokstav, minst ett tall, og minst ett spesialtegn(f.eks. !@#%^&*).";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] =
              "Passordet ditt og passordbekreftelsen er ikke like.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Vennligst bekreft passordet ditt.";
          } else if (input.newPassword && value !== input.newPassword) {
            stateObj[name] =
              "Passordet ditt og passordbekreftelsen er ikke like.";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  }

  async function changePassword() {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      let response = await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(
          user,
          input.currentPassword,
          input.newPassword
        );
      });
      console.log(response);
      setScreenType("success");
    } catch (error) {
      console.log(error);
      setChangePasswordError("Du skrev inn feil nåværende pasord");
    }
    setLoading(false);
  }

  return (
    <>
      {screenType === "changePassword" && (
        <>
          <TopNavigationBar
            header="Endre ditt passord"
            showIcon={false}
            navigateBackTo="/konto/innstillinger"
          />
          <Layout>
            <Flex
              direction="column"
              textAlign="center"
              marginTop={106}
              marginBottom="xl"
              alignItems="center"
              gap="0.5rem"
            >
              <IconsNew property1="info" />
              <Text color="#899D9F">
                Du må først legge inn ditt nåværende passord, og deretter skrive
                inn det nye.
              </Text>
            </Flex>
            <Flex direction="column">
              <PasswordField
                label="Passord"
                variation="quiet"
                name="currentPassword"
                placeholder="Skriv inn ditt nåværende passord"
                style={{
                  borderColor: "#76B3B8",
                }}
                autoComplete="current-password"
                onChange={onInputChange}
                onBlur={validateInput}
              />
              <Text
                style={{ textAlign: "right", fontWeight: "bold" }}
                onClick={() => navigate("/glemt-passord")}
                marginTop="medium"
                marginBottom="large"
              >
                Har du glemt passordet ditt?
              </Text>
            </Flex>
            <Flex
              direction="column"
              textAlign="center"
              marginTop="large"
              marginBottom="xl"
              alignItems="center"
              gap="0.5rem"
            >
              <IconsNew property1="info" />
              <Text color="#899D9F">
                Passordet ditt må ha minst én stor bokstav, minst ett tall, og
                minst ett spesialtegn (f.eks. !@#%^&*).
              </Text>
            </Flex>
            <Flex direction="column">
              <PasswordField
                label="Ditt nye passord"
                variation="quiet"
                placeholder="Skriv inn ditt nye passord"
                style={{
                  borderColor: "#76B3B8",
                }}
                name="newPassword"
                onChange={onInputChange}
                onBlur={validateInput}
                autoComplete="current-password"
              />
              {error.newPassword && (
                <Flex as="span" style={{ color: "red" }}>
                  {error.newPassword}
                </Flex>
              )}
              <PasswordField
                label="Bekreft ditt nye passord"
                variation="quiet"
                placeholder="Gjenta ditt nye passord"
                style={{
                  borderColor: "#76B3B8",
                }}
                name="confirmPassword"
                onChange={onInputChange}
                onBlur={validateInput}
                autoComplete="new-password"
              />
              {error.confirmPassword && (
                <Flex as="span" style={{ color: "red" }}>
                  {error.confirmPassword}
                </Flex>
              )}
            </Flex>
            <Flex direction="column" alignItems="center" marginTop="large">
              <SecondaryButton
                onClick={
                  error.newPassword ||
                  error.confirmPassword ||
                  input.newPassword === "" ||
                  input.confirmPassword === ""
                    ? null
                    : () => changePassword()
                }
                property1={
                  error.newPassword ||
                  error.confirmPassword ||
                  input.newPassword === "" ||
                  input.confirmPassword === ""
                    ? "disabled"
                    : "enabled"
                }
                overrides={{
                  label: {
                    children: "Endre passordet ditt",
                  },
                }}
              />
              {changePasswordError && (
                <AlertN
                  overrides={{
                    body: { children: changePasswordError },
                  }}
                />
              )}
            </Flex>
          </Layout>
        </>
      )}
      {screenType === "success" && (
        <Layout>
          <Flex
            direction="column"
            textAlign="center"
            marginTop="4rem"
            marginBottom="large"
            alignItems="center"
            gap="2rem"
          >
            <Verifiedlarge />
            <Heading
              fontSize="x-large"
              fontWeight="light"
              color="#76B3B8"
              marginBottom="medium"
            >
              Ditt nye passord er registrert!
            </Heading>
            <SecondaryButton
              onClick={() => navigate("/konto/innstillinger")}
              overrides={{
                label: {
                  children: "Ferdig",
                },
              }}
            />
          </Flex>
        </Layout>
      )}
    </>
  );
}
