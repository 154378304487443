import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton, AlertN } from '../../ui-components';
import { View, Text, Heading, TextField, Flex, PasswordField } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import { Icons } from '../../assets/icons';

export default function ForgotPassword() {
	const [insertEmailError, setInsertEmailError] = useState(null);
	const [submitPasswordError, setSubmitPasswordError] = useState(null);
	const [forgotPasswordMessage, setForgotPasswordMessage] = useState(
		'Klikk her for å sende på nytt',
	);
	const [loading, setLoading] = useState(false);
	const [logInType, setLogInType] = useState('resetPassword');
	const [confirmationError, setConfirmationError] = useState(null);
	const navigate = useNavigate();
	const [input, setInput] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		mfa_code: '',
		forgotPassword_code: '',
	});
	const [error, setError] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		forgotPassword_code: '',
	});

	function onInputChange(e) {
		setInsertEmailError(null);
		const { name, value } = e.target;
		setInput(prev => ({
			...prev,
			[name]: value,
		}));
		validateInput(e);
	}

	function validateInput(e) {
		let { name, value } = e.target;
		setError(prev => {
			const stateObj = { ...prev, [name]: '' };

			switch (name) {
				case 'email':
					if (!value) {
						stateObj[name] = 'Vennligst fyll ut din e-postadresse.';
					} else if (
						!value
							.toLowerCase()
							.match(
								/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							)
					) {
						stateObj[name] = 'Vennligst bruk en gyldig e-postadresse.';
					}
					break;

				case 'password':
					if (!value) {
						stateObj[name] = 'Vennligst skriv inn et passord.';
					} else if (
						!value.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)
					) {
						stateObj[name] =
							'Passordet ditt må ha minst 8 tegn, minst én stor bokstav, minst ett tall, og minst ett spesialtegn (f.eks. !@#%^&*).';
					} else if (input.confirmPassword && value !== input.confirmPassword) {
						stateObj['confirmPassword'] = 'Passordet ditt og passordbekreftelsen er ikke like.';
					} else {
						stateObj['confirmPassword'] = input.confirmPassword ? '' : error.confirmPassword;
					}
					break;

				case 'confirmPassword':
					if (!value) {
						stateObj[name] = 'Vennligst bekreft passordet ditt.';
					} else if (input.password && value !== input.password) {
						stateObj[name] = 'Passordet ditt og passordbekreftelsen er ikke like.';
					}
					break;

				case 'forgotPassword_code':
					if (!value) {
						stateObj[name] = 'Vennligst bekreft passordet ditt.';
					} else if (!value.match(/^\d{6}$/)) {
						stateObj[name] = 'Dette er ikke en gyldig kode.';
					}
					break;

				default:
					break;
			}
			return stateObj;
		});
	}

	useEffect(() => {
		const listener = data => {
			console.log(data);
			switch (data.payload.event) {
				case 'forgotPassword':
					setInsertEmailError(null);
					console.log('email approved');
					break;
				case 'forgotPassword_failure':
					setInsertEmailError('Denne e-postadressen tilhører ikke en eksisterende bruker.');
					break;
				default:
			}
		};
		Hub.listen('auth', listener);
		return () => Hub.remove('auth', listener);
	}, []);

	async function forgotPassword() {
		if (loading) {
			return;
		}
		setLoading(true);
		setForgotPasswordMessage('E-post er sendt');
		try {
			await Auth.forgotPassword(input.email);
			setLogInType('confirmSent');
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
		setTimeout(() => setForgotPasswordMessage('Klikk her for å sende på nytt'), 3000);
	}
	async function forgotPasswordSubmit() {
		if (loading) {
			return;
		}
		setLoading(true);
		try {
			await Auth.forgotPasswordSubmit(input.email, input.forgotPassword_code, input.password);
			setSubmitPasswordError(null);
			setLogInType('success');
		} catch (error) {
			console.log(error);
			setLogInType('confirmSent');
			setConfirmationError('Du har skrevet inn feil kode. Vennligst prøv igjen.');
		}
		setLoading(false);
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [logInType]);

	return (
		<View padding="40px 16px 10px">
			{logInType === 'resetPassword' && (
				<View>
					<View
						height="33px"
						backgroundColor="#F2F6F6"
						width="100%"
						position="fixed"
						top={0}
						left="0px"
						right="0px"
						style={{ zIndex: 100 }}
					/>

					<Flex
						direction="row"
						alignItems="center"
						margin="0 auto"
						justifyContent="center"
						position="fixed"
						top="32px"
						left="0px"
						right="0px"
						width="100%"
						height="48px"
						style={{ zIndex: 100 }}
						backgroundColor={'#F2F6F6'}
					>
						<View
							paddingLeft="15px"
							marginRight="auto"
							onClick={() => navigate('/logg-inn', { replace: true })}
						>
							<img src={Icons.arrowBackBig} alt="back" />
						</View>
						<View
							display="flex"
							direction="column"
							position="fixed"
							textAlign="center"
							justifyContent="center"
							gap="0.2rem"
						>
							<Text textAlign={'center'} fontSize="16px" fontWeight={'500'} color="#5D8D92">
								Endre passord
							</Text>
						</View>
					</Flex>
					<Flex
						marginTop={'84px'}
						direction="column"
						textAlign="center"
						marginBottom="4rem"
						alignItems="center"
						gap="0.5rem"
					>
						<img src={Icons.info} alt="info" />
						<Text color="#5D7679" fontSize={'14px'}>
							Skriv inn epostadressen du brukte da du registrerte deg. Vi sender deg en epost
							med en link som lar deg endre passordet ditt.
						</Text>
					</Flex>
					<View>
						<TextField
							style={{
								borderLeft: 0,
								borderRight: 0,
								borderTop: 0,
								borderRadius: 0,
								borderColor: '#76B3B8',
							}}
							name="email"
							label="E-post"
							placeholder="Skriv inn e-postadressen din her"
							onChange={onInputChange}
							onBlur={validateInput}
						/>
					</View>
					{error.email && (
						<Flex as="span" style={{ color: 'red' }}>
							{error.email}
						</Flex>
					)}
					<Flex direction="column" alignItems="center" marginTop="large">
						<View position="absolute" bottom="56px">
							<SecondaryButton
								onClick={error.email || input.email === '' ? null : () => forgotPassword()}
								property1={error.email || input.email === '' ? 'disabled' : 'enabled'}
								overrides={{
									label: { children: 'Send e-post' },
								}}
							/>
						</View>
					</Flex>
					{insertEmailError && (
						<Flex direction="column" alignItems="center" gap="large" marginTop="large">
							<AlertN
								overrides={{
									body: { children: insertEmailError },
								}}
							/>
							<SecondaryButton
								onClick={() => navigate('/registrering', { replace: true })}
								overrides={{
									label: {
										children: 'Registrer ny bruker',
									},
								}}
							/>
						</Flex>
					)}
				</View>
			)}
			{logInType === 'confirmSent' && (
				<View>
					<View
						height="33px"
						backgroundColor="#F2F6F6"
						width="100%"
						position="fixed"
						top={0}
						left="0px"
						right="0px"
						style={{ zIndex: 100 }}
					/>

					<Flex
						direction="row"
						alignItems="center"
						margin="0 auto"
						justifyContent="center"
						position="fixed"
						top="32px"
						left="0px"
						right={'0px'}
						width="100%"
						height="48px"
						style={{ zIndex: 100 }}
						backgroundColor={'#F2F6F6'}
					>
						<View
							display="flex"
							direction="column"
							position="fixed"
							textAlign="center"
							justifyContent="center"
							gap="0.2rem"
						>
							<Text textAlign={'center'} fontSize="16px" fontWeight={'500'} color="#5D8D92">
								Reset passord
							</Text>
						</View>
						<View
							paddingRight="15px"
							marginLeft="auto"
							onClick={() => setLogInType('resetPassword')}
						>
							<img src={Icons.cancel} alt="back" />
						</View>
					</Flex>

					<Flex
						marginTop={'84px'}
						direction="column"
						textAlign="center"
						marginBottom="large"
						alignItems="center"
						gap="1rem"
					>
						<img src={Icons.mailSend} alt="mailsend" />
						<Heading fontSize="20px" fontWeight="500" color="#76B3B8" marginBottom="medium">
							E-post for å endre passordet ditt er sendt!
						</Heading>
						<Text fontSize="14px" fontWeight="400" color="#5C7578">
							Vennligst sjekk eposten din for å endre passordet ditt, og fyll inn mottatt kode
							under
						</Text>
					</Flex>
					<TextField
						style={{
							borderLeft: 0,
							borderRight: 0,
							borderTop: 0,
							borderRadius: 0,
							borderColor: '#76B3B8',
							letterSpacing: '1rem',
							textAlign: 'center',
						}}
						name="forgotPassword_code"
						label="Motatt kode"
						placeholder="______"
						onChange={onInputChange}
						onBlur={validateInput}
					/>
					{error.forgotPassword_code && (
						<Flex as="span" style={{ color: 'red' }}>
							{error.forgotPassword_code}
						</Flex>
					)}
					{confirmationError && (
						<Flex
							direction="column"
							textAlign="center"
							marginTop="2rem"
							marginBottom="2rem"
							style={{ alignItems: 'center' }}
						>
							<AlertN
								overrides={{
									body: { children: confirmationError },
								}}
							/>
						</Flex>
					)}
					<Flex direction="column" textAlign="center" marginTop="4rem" marginBottom="56px">
						<Text fontSize="16px" fontWeight="400" color="#5B8386">
							Mottok du ikke koden?
						</Text>
						{/* TO DO: Figure out if I should have another call for sending a new code */}
						<Text
							fontSize="14px"
							fontWeight="700"
							color="#4E888D"
							onClick={() => forgotPassword()}
						>
							{forgotPasswordMessage}
						</Text>
					</Flex>
					<Flex direction="column" alignItems="center" marginTop="large" marginBottom={'56px'}>
						<SecondaryButton
							property1={
								error.forgotPassword_code || input.forgotPassword_code === ''
									? 'disabled'
									: 'enabled'
							}
							onClick={
								error.forgotPassword_code || input.forgotPassword_code === ''
									? null
									: () => setLogInType('setNewPassword')
							}
							overrides={{
								label: { children: 'Sett det nye passordet' },
							}}
						/>
					</Flex>
				</View>
			)}
			{logInType === 'setNewPassword' && (
				<View>
					<View
						height="33px"
						backgroundColor="#F2F6F6"
						width="100%"
						position="fixed"
						top={0}
						left="0px"
						right="0px"
						style={{ zIndex: 100 }}
					/>
					<Flex
						direction="row"
						alignItems="center"
						margin="0 auto"
						justifyContent="center"
						position="fixed"
						top="32px"
						left="0px"
						width="100%"
						height="48px"
						style={{ zIndex: 100 }}
						backgroundColor={'#F2F6F6'}
					>
						<View
							display="flex"
							direction="column"
							position="fixed"
							textAlign="center"
							justifyContent="center"
							gap="0.2rem"
						>
							<Text textAlign={'center'} fontSize="16px" fontWeight={'500'} color="#5D8D92">
								Endre passord
							</Text>
						</View>
						<View
							paddingRight="15px"
							marginLeft="auto"
							onClick={() => setLogInType('resetPassword')}
						>
							<img src={Icons.cancel} alt="back" />
						</View>
					</Flex>
					<Flex
						marginTop="84px"
						direction="column"
						textAlign="center"
						marginBottom="large"
						alignItems="center"
						gap="0.5rem"
					>
						<img src={Icons.info} alt="info" />
						<Text fontSize={'14px'} fontWeight="400" color="#5C7578">
							Passordet ditt må ha minst 8 tegn, minst én stor bokstav, minst ett tall, og minst
							ett spesialtegn (f.eks. !@#%^&*).
						</Text>
					</Flex>
					<Flex as="form" direction="column">
						<PasswordField
							label="Ditt nye passord"
							hideShowPassword="true"
							placeholder="Skriv inn ditt nye passord"
							style={{
								borderLeft: 0,
								borderRight: 0,
								borderTop: 0,
								borderRadius: 0,
								borderColor: '#76B3B8',
								marginBottom: '32px',
							}}
							name="password"
							onChange={onInputChange}
							onBlur={validateInput}
							autoComplete="new-password"
						/>
						{error.password && (
							<Flex as="span" style={{ color: 'red' }}>
								{error.password}
							</Flex>
						)}
						<PasswordField
							label="Bekreft ditt nye passord"
							hideShowPassword="true"
							placeholder="Gjenta ditt nye passord"
							style={{
								borderLeft: 0,
								borderRight: 0,
								borderTop: 0,
								borderRadius: 0,
								borderColor: '#76B3B8',
							}}
							name="confirmPassword"
							onChange={onInputChange}
							onBlur={validateInput}
							autoComplete="new-password"
						/>
						{error.confirmPassword && (
							<Flex as="span" style={{ color: 'red' }}>
								{error.confirmPassword}
							</Flex>
						)}
						<Flex direction="column" alignItems="center" marginTop="large">
							<View position="absolute" bottom={'56px'}>
								<SecondaryButton
									onClick={
										error.password ||
										error.confirmPassword ||
										input.password === '' ||
										input.confirmPassword === ''
											? null
											: () => forgotPasswordSubmit()
									}
									property1={
										error.password ||
										error.confirmPassword ||
										input.password === '' ||
										input.confirmPassword === ''
											? 'disabled'
											: 'enabled'
									}
									overrides={{
										label: {
											children: 'Endre passord',
										},
									}}
								/>
							</View>
							{submitPasswordError && (
								<AlertN
									overrides={{
										body: { children: submitPasswordError },
									}}
								/>
							)}
						</Flex>
					</Flex>
				</View>
			)}
			{logInType === 'success' && (
				<Flex
					direction="column"
					textAlign="center"
					marginTop="4rem"
					marginBottom="26px"
					alignItems="center"
				>
					<img src={Icons.verifiedPassword} alt="verified" />
					<Heading fontSize="20px" fontWeight="500" color="#6B9A9E" marginBottom="medium">
						Passordet ditt er nå endret!
					</Heading>
					<View position="absolute" bottom="56px">
						<SecondaryButton
							onClick={() => navigate('/logg-inn', { replace: true })}
							overrides={{
								label: {
									children: 'Gå til innloggingssiden',
								},
							}}
						/>
					</View>
				</Flex>
			)}
		</View>
	);
}
