import React, { useState, useContext } from "react";
import { View, Text, RadioGroupField, Radio } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { ProfileDataContext } from "../../contexts/contexts";
import { Icons } from "../../assets/icons";

export function ChooseSexualOrientation() {
  const { setProfileSettingsValues, profileSettingsValues } =
    useContext(ProfileDataContext);

  const [sexualOrientation, setSexualOrientation] = useState(
    profileSettingsValues.sexual_orientation
  );

  const navigate = useNavigate();

  function handleSexualOrientation(value) {
    setSexualOrientation(value);

    setProfileSettingsValues((prevData) => ({
      ...prevData,
      sexual_orientation: value,
    }));
    navigate("/lag-profil");
  }

  return (
    <View height="100vh">
      <View
        height="32px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        left="0px"
        right="0px"
        width="100%"
      />
      <View position="fixed" top="32px" left="0px" right="0px" width="100%">
        <View
          height="48px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <Text textAlign={"center"} color={"#29666A"} fontSize="16px">
            Velg din seksuelle orientering
          </Text>

          <img
            onClick={() => navigate("/lag-profil")}
            style={{ position: "absolute", right: "16px", bottom: "5px" }}
            src={Icons.cancel}
            alt="cancel"
          />
        </View>
      </View>

      <View
        paddingTop="60px"
        margin="0 16px 0 16px"
        display="flex"
        justifyContent="space-between"
        direction="column"
        alignItems="center"
        height={"100vh"}
      >
        <View width="100%">
          <RadioGroupField
            marginTop={"40px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <Radio
              readOnly={true}
              checked={sexualOrientation === "Heterofil" ? true : false}
              onChange={() => handleSexualOrientation("Heterofil")}
              value={""}
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Heterofil
              </Text>
            </Radio>
          </RadioGroupField>
          <RadioGroupField
            marginTop={"24px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <Radio
              readOnly={true}
              checked={sexualOrientation === "Homofil" ? true : false}
              onChange={() => handleSexualOrientation("Homofil")}
              value="Mann"
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Homofil
              </Text>
            </Radio>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"24px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <Radio
              readOnly={true}
              checked={sexualOrientation === "Lesbisk" ? true : false}
              onChange={() => handleSexualOrientation("Lesbisk")}
              value=""
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Lesbisk
              </Text>
            </Radio>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"24px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <Radio
              readOnly={true}
              checked={sexualOrientation === "Bifil" ? true : false}
              onChange={() => handleSexualOrientation("Bifil")}
              value=""
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Bifil
              </Text>
            </Radio>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"24px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
            marginBottom={"53px"}
          >
            <Radio
              readOnly={true}
              checked={sexualOrientation === "Annet" ? true : false}
              onChange={() => handleSexualOrientation("Annet")}
              value=""
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Annet
              </Text>
            </Radio>
          </RadioGroupField>
        </View>
      </View>
    </View>
  );
}
