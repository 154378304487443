import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha"; 
import {
	View,
	Text,
	Heading,
	Flex,
	PasswordField,
	PhoneNumberField,
	TextField,
	CheckboxField,
	RadioGroupField,
} from '@aws-amplify/ui-react';
import {
	SecondaryButton,
	IconsNew,
	Errorfailedlarge,
	Verifiedlarge,
	AlertN,
} from '../../ui-components';
import { Auth, Hub, API } from 'aws-amplify';
import PrivacyPolicy from './privacyPolicy';
import { getUserAccount } from '../../graphql/queries';
import { Icons } from '../../assets/icons';
import ActivityDetector from 'react-activity-detector';

const initialState = {
	email: '',
	password: '',
	confirmPassword: '',
	checkbox: false,
	phone_number: '',
	dial_code: '',
	given_name: '',
	family_name: '',
	picture: '',
	birthdate: '',
	captcha: '',
};

export default function SignUp() {
	const [signUpType, setSignUpType] = useState(null);
	const [loading, setLoading] = useState(false);
	const [sentEmail, setSentEmail] = useState(false);
	const [verifyCodeError, setVerifyCodeError] = useState(null);
	const [signUpError, setSignUpError] = useState(null);
	const [resendConfirmError, setResendConfirmError] = useState(null);
	const [forgotPasswordMessage, setForgotPasswordMessage] = useState(
		'Klikk her for å sende på nytt',
	);
	// const [captchaError, setCaptchaError] = useState(null);
	const [loginError, setLoginError] = useState(null);
	const [cognitoUser, setCognitoUser] = useState(null);
	const navigate = useNavigate();
	const location = useLocation();
	const [isIdle, setIsIdle] = useState(false);
	const [formState, setFormState] = useState(initialState);
	const [error, setError] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		phone_number: '',
		checkbox: '',
	});
	var {
		email,
		password,
		confirmPassword,
		checkbox,
		phone_number,
		dial_code,
		given_name,
		family_name,
		picture,
		birthdate,
		captcha,
	} = formState;
	const customActivityEvents = ['click', 'keydown'];

	function onInputChange(e) {
		const { name, value } = e.target;
		setFormState(prev => ({
			...prev,
			[name]: value,
		}));
		validateInput(e);
	}

	function validateInput(e) {
		let { name, value } = e.target;
		setError(prev => {
			const stateObj = { ...prev, [name]: '' };
			switch (name) {
				case 'email':
					if (!value) {
						stateObj[name] = 'Vennligst fyll ut din e-postadresse.';
					} else if (
						!value
							.toLowerCase()
							.match(
								/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							)
					) {
						stateObj[name] = 'Vennligst bruk en gyldig e-postadresse.';
					}
					break;

				case 'password':
					if (!value) {
						stateObj[name] = 'Vennligst skriv inn et passord.';
					} else if (
						!value.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/)
					) {
						stateObj[name] =
							'Passordet ditt må ha minst 8 tegn, minst én stor bokstav, minst ett tall, og minst ett spesialtegn (f.eks. !@#%^&*).';
					} else if (confirmPassword && value !== confirmPassword) {
						stateObj['confirmPassword'] = 'Passordet ditt og passordbekreftelsen er ikke like.';
					} else {
						stateObj['confirmPassword'] = confirmPassword ? '' : error.confirmPassword;
					}
					break;

				case 'confirmPassword':
					if (!value) {
						stateObj[name] = 'Vennligst bekreft passordet ditt.';
					} else if (password && value !== password) {
						stateObj[name] = 'Passordet ditt og passordbekreftelsen er ikke like.';
					}
					break;

				case 'phone_number':
					if (!value) {
						stateObj[name] = 'Vennligst skriv inn mobilnummeret ditt.';
					} else if (!value.match(/^\d+$/) || value.length < 8 || value.length > 10) {
						stateObj[name] = 'Vennligst skriv et gyldig mobilnummer.';
					}
					break;

				case 'checkbox':
					if (!value) {
						stateObj[name] =
							'Vennligst kryss av for at du godtar vilkårene og personvernerklæringen for å kunne gå videre.';
					}
					break;

				default:
					break;
			}

			return stateObj;
		});
	}

	function onMFAInputChange(e) {
		if (e.target.value.match(/^[0-9]+$/) && e.target.value.length === 6) {
			confirmSignIn(e.target.value);
		}
	}

	async function confirmSignIn(mfa_code) {
		try {
			const response = await Auth.confirmSignIn(cognitoUser, mfa_code);
			console.log(response);
		} catch (error) {
			console.log(error);
		}
	}

	async function handleResendMessage() {
		if (loading) {
			return;
		}
		setForgotPasswordMessage('E-post er sendt');
		setLoading(true);

		if (cognitoUser && cognitoUser.challengeName === 'SMS_MFA') {
			console.log('resending mfa code');
			const userResponse = await Auth.signIn(email, password);
			setCognitoUser(userResponse);
		}

		setLoading(false);
		setTimeout(() => setForgotPasswordMessage('Klikk her for å sende på nytt'), 3000);
		setLoading(true);

		if (cognitoUser && cognitoUser.challengeName === 'SMS_MFA') {
			console.log('resending mfa code');
			const userResponse = await Auth.signIn(email, password);
			setCognitoUser(userResponse);
		}

		setLoading(false);
		setTimeout(() => setForgotPasswordMessage('Klikk her for å sende på nytt'), 3000);
	}

	useEffect(() => {
		const listener = async data => {
			const uuid = await Auth.currentUserInfo();

			if (uuid) {
				try {
					const response = await API.graphql({
						query: getUserAccount,
						variables: {
							cognito_uuid: uuid.attributes.sub,
						},
					});

					switch (data.payload.event) {
						case 'signUp':
							//setSignUpError(null);
							console.log('signed up!');
							break;
						case 'signUp_failure':
							//setSignUpError(data.payload.data.message);
							console.log(data.payload.data.message);
							break;
						case 'confirmSignUp':
							//setConfirmSignUpError(null);
							console.log('confirmed sign up!');
							break;
						case 'confirmSignUp_failure':
							//setConfirmSignUpError(data.payload.data.message);
							console.log(data.payload.data.message);
							setSignUpType('error2fa');
							break;
						case 'signIn':
							console.log('signed in!');
							response.data.getUserAccount ? navigate('/min-profil') : navigate('/bankID');
							break;
						case 'signIn_failure':
							console.log('fail!!');
							setLoginError(data.payload.data.message);
							break;
						default:
					}
				} catch (err) {
					console.log(err);
				}
			}

			console.log(data);
		};

		Hub.listen('auth', listener);

		return () => Hub.remove('auth', listener);
	}, [navigate]);

	useEffect(() => {
		const listener = data => {
			console.log(data);
			switch (data.payload.event) {
				case 'custom':
					console.log('here!!!');
					break;
				default:
			}
		};

		Hub.listen('custom', listener);
		return () => Hub.remove('custom', listener);
	});

	useEffect(() => {
		if (location.state && location.state.signUpType === 'email') {
			setSignUpType('email');
		} else if (location.state && location.state.signUpType === 'password') {
			setSignUpType('password');
		}
	}, [location.state]);

	async function signUp() {
		console.log(process.env.REACT_APP_SITE_KEY);

		if (loading) {
			return;
		}
		setLoading(true);
		phone_number =
			dial_code.length === 0 ? '+47' + phone_number : dial_code.concat(phone_number);
		setFormState({ ...formState, [phone_number]: phone_number });
		console.log(phone_number);
		try {
			const captchaResponse = {
				'g-recaptcha-response': captcha,
			};
			console.log(captchaResponse);
			console.log(formState);
			const signUpResponse = await Auth.signUp({
				username: email,
				password,
				attributes: {
					email,
					phone_number,
					given_name,
					family_name,
					picture,
					birthdate,
				},
				clientMetadata: captchaResponse,
				/* autoSignIn: { //enables auto sign in after user is confirmed
          enabled: true,
        } */
			});
			setSignUpError(null);
			console.log(signUpResponse);
		} catch (error) {
			console.log(error);
			setSignUpError('Denne brukeren eksisterer allerede');
		}
		setSentEmail(true);
		setLoading(false);
	}

	async function confirmSignUp(validation_code) {
		console.log('email in confirm', email);
		if (loading) {
			return;
		}
		setLoading(true);
		try {
			let response = await Auth.confirmSignUp(email, validation_code);
			console.log(response);
			//setSignUpType("confirm2fa");
			try {
				console.log(email);
				console.log(password);
				const response = await Auth.signIn(email, password);
				if (response?.challengeName === 'SMS_MFA') {
					setCognitoUser(response);
				}
				setSignUpType('confirmLogin');
				console.log(response);
			} catch (err) {
				console.log(err);
			}

			setVerifyCodeError(null);
		} catch (error) {
			console.log(error);
			setVerifyCodeError('Feil verifiseringskode');
		}
		setLoading(false);
	}

	async function resendConfirmSignUp() {
		if (loading) {
			return;
		}
		setLoading(true);
		setForgotPasswordMessage('Melding er sendt');
		try {
			let response = await Auth.resendSignUp(email);
			console.log(response);
			setResendConfirmError(null);
		} catch (error) {
			console.log(error);
			setResendConfirmError('Det eksisterer allerede en bruker med denne e-postadressen');
		}
		setLoading(false);
		setTimeout(() => setForgotPasswordMessage('Klikk her for å sende på nytt'), 3000);
	}

	function validationInputChange(e) {
		console.log(e.target.value);
		console.log('her');
		if (e.target.value.match(/^\d{6}$/)) {
			console.log('og her');
			confirmSignUp(e.target.value);
		}
	}

	function captchaChange(data) {
		console.log(data);
		setFormState(prev => ({
			...prev,
			captcha: data,
		}));
		console.log(formState);
	}

	function goBackFrom2FA() {
		setSignUpError(null);
		setFormState(prevState => ({
			...prevState,
			captcha: '',
		}));
		setSentEmail(false);
		setSignUpType('phoneNumber');
	}

	function handleSetInputValue(key, value) {
		setFormState(prevState => ({
			...prevState,
			[key]: value,
		}));
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [signUpType]);

	return (
		<View padding="40px 16px 10px">
			{signUpType === 'email' && (
				<View>
					<View
						height="33px"
						backgroundColor="#F2F6F6"
						width="100%"
						position="fixed"
						top={0}
						left="0px"
						right="0px"
						style={{ zIndex: 100 }}
					/>

					<Flex
						direction="row"
						alignItems="center"
						margin="0 auto"
						justifyContent="center"
						position="fixed"
						top="32px"
						left="0px"
						right="0px"
						width="100%"
						height="48px"
						style={{ zIndex: 100 }}
						backgroundColor={'#F2F6F6'}
					>
						<View paddingLeft="15px" marginRight="auto" onClick={() => navigate('/logg-inn')}>
							<img src={Icons.arrowBackBig} alt="back" />
						</View>
						<View
							display="flex"
							direction="column"
							position="fixed"
							textAlign="center"
							justifyContent="center"
							gap="0.2rem"
						>
							<Text textAlign={'center'} fontSize="16px" fontWeight={'500'} color="#29666A">
								Registrer deg
							</Text>
						</View>
					</Flex>
					<Heading
						fontWeight="400"
						color="#6B9A9E"
						marginBottom="38px"
						marginTop="84px"
						textAlign="center"
					>
						Registrer e-postadressen din
					</Heading>
					<Flex as="form" direction="column" justifyContent={'space-between'}>
						<View>
							<TextField
								style={{
									borderLeft: 0,
									borderRight: 0,
									borderTop: 0,
									borderRadius: 0,
									borderColor: '#76B3B8',
								}}
								label="E-post"
								name="email"
								placeholder="Skriv inn e-postadressen din her"
								value={email ? email : ''}
								onChange={onInputChange}
								onBlur={validateInput}
							/>
							{error.email && (
								<Flex as="span" style={{ color: 'red' }}>
									{error.email}
								</Flex>
							)}
						</View>
						<Flex
							width="100%"
							direction="column"
							alignItems="center"
							position={'absolute'}
							bottom={'56px'}
							right={0}
							left={0}
						>
							<SecondaryButton
								property1={error.email || email === '' ? 'disabled' : 'enabled'}
								onClick={error.email || email === '' ? null : () => setSignUpType('password')}
								overrides={{
									label: {
										children: 'Neste',
									},
								}}
							/>
						</Flex>
					</Flex>
				</View>
			)}
			{signUpType === 'password' && (
				<View>
					<View
						height="33px"
						backgroundColor="#F2F6F6"
						width="100%"
						position="fixed"
						top={0}
						right="0px"
						left="0px"
						style={{ zIndex: 100 }}
					/>

					<Flex
						direction="row"
						alignItems="center"
						margin="0 auto"
						justifyContent="center"
						position="fixed"
						top="32px"
						right="0px"
						left="0px"
						width="100%"
						height="48px"
						style={{ zIndex: 100 }}
						backgroundColor={'#F2F6F6'}
					>
						<View paddingLeft="15px" marginRight="auto" onClick={() => setSignUpType('email')}>
							<img src={Icons.arrowBackBig} alt="back" />
						</View>
						<View
							display="flex"
							direction="column"
							position="fixed"
							textAlign="center"
							justifyContent="center"
							gap="0.2rem"
						>
							<Text textAlign={'center'} fontSize="16px" fontWeight={'500'} color="#5D8D92">
								Registrer deg
							</Text>
						</View>
					</Flex>
					<View>
						<Heading
							fontSize={'16px'}
							fontWeight="400"
							color="#6B9A9E"
							marginBottom="large"
							marginTop="84px"
							textAlign="center"
						>
							Velg passord
						</Heading>
						<Flex
							direction="column"
							textAlign="center"
							marginBottom="56px"
							alignItems="center"
							gap="0.5rem"
						>
							<img src={Icons.info} alt="info" />
							<Text color="#5D7679" fontSize={'14px'}>
								Passordet ditt må ha minst 8 tegn, minst én stor bokstav, minst ett tall, og
								minst ett spesialtegn (f.eks. !@#%^&*).
							</Text>
						</Flex>
						<Flex as="form" direction="column">
							<PasswordField
								label="Passord"
								variation="quiet"
								placeholder="Skriv inn passordet ditt her"
								style={{
									borderLeft: 0,
									borderRight: 0,
									borderTop: 0,
									borderRadius: 0,
									borderColor: '#76B3B8',
								}}
								name="password"
								value={password ? password : ''}
								onChange={onInputChange}
								onBlur={validateInput}
								autoComplete="new-password"
							/>
							{error.password && (
								<Flex as="span" style={{ color: 'red' }}>
									{error.password}
								</Flex>
							)}
							<PasswordField
								label="Bekreft passordet"
								variation="quiet"
								placeholder="Gjenta passordet ditt her"
								style={{
									borderLeft: 0,
									borderRight: 0,
									borderTop: 0,
									borderRadius: 0,
									borderColor: '#76B3B8',
								}}
								name="confirmPassword"
								value={confirmPassword ? confirmPassword : ''}
								onChange={onInputChange}
								onBlur={validateInput}
								autoComplete="new-password"
							/>
							{error.confirmPassword && (
								<Flex as="span" style={{ color: 'red' }}>
									{error.confirmPassword}
								</Flex>
							)}
							<Flex direction="column" alignItems="center" gap="3rem" marginBottom={'56px'}>
								<Flex
									direction="row"
									padding="1rem 3rem 1rem"
									alignItems={'center'}
									justifyContent="center"
								>
									<RadioGroupField marginRight={'5px'}>
										<CheckboxField
											onBlur={validateInput}
											checked={formState.checkbox}
											onClick={() => handleSetInputValue('checkbox', !formState.checkbox)}
											value=""
											width={'100%'}
											readOnly={true}
										/>
									</RadioGroupField>
									<Text fontSize={'14px'} color="#5D7679">
										Jeg godtar{' '}
										<View
											as="span"
											textDecoration="underline"
											fontWeight="400"
											color="#4E888D"
											onClick={() => setSignUpType('privacyPolicy')}
										>
											medlemsvilkår og personvernerklæringen
										</View>
									</Text>
								</Flex>
							</Flex>
						</Flex>

						<View
							width="100%"
							display="flex"
							textAlign="center"
							justifyContent="center"
							marginBottom="36px"
						>
							<SecondaryButton
								property1={
									error.password ||
									error.confirmPassword ||
									error.checkbox ||
									password === '' ||
									confirmPassword === '' ||
									!checkbox
										? 'disabled'
										: 'enabled'
								}
								onClick={
									error.password ||
									error.confirmPassword ||
									error.checkbox ||
									password === '' ||
									confirmPassword === '' ||
									!checkbox
										? null
										: () => setSignUpType('phoneNumber')
								}
								overrides={{
									label: {
										children: 'Neste',
									},
								}}
							/>
						</View>
					</View>
				</View>
			)}
			{signUpType === 'privacyPolicy' && (
				<PrivacyPolicy
					onClickConversation={() => setSignUpType('password')}
					signUpPage
					closePrivacyPage={setSignUpType}
				/>
			)}
			{signUpType === 'phoneNumber' && (
				<View>
					<View
						height="33px"
						backgroundColor="#F2F6F6"
						width="100%"
						position="fixed"
						top={0}
						right="0px"
						left="0px"
						style={{ zIndex: 100 }}
					/>

					<Flex
						direction="row"
						alignItems="center"
						margin="0 auto"
						justifyContent="center"
						position="fixed"
						top="32px"
						right="0px"
						left="0px"
						width="100%"
						height="48px"
						style={{ zIndex: 100 }}
						backgroundColor={'#F2F6F6'}
					>
						<View
							paddingLeft="15px"
							marginRight="auto"
							onClick={() => setSignUpType('password')}
						>
							<img src={Icons.arrowBackBig} alt="back" />
						</View>
						<View
							display="flex"
							direction="column"
							position="fixed"
							textAlign="center"
							justifyContent="center"
							gap="0.2rem"
						>
							<Text textAlign={'center'} fontSize="16px" fontWeight={'500'} color="#5D8D92">
								Registrer deg
							</Text>
						</View>
					</Flex>
					<View>
						<Heading
							fontSize={'16px'}
							fontWeight="400"
							color="#6B9A9E"
							marginBottom="large"
							paddingTop="84px"
							textAlign="center"
						>
							Registrer telefonnummeret din
						</Heading>
						<Flex as="form" direction="column">
							<PhoneNumberField
								defaultDialCode="+47"
								label="Ditt telefonnummer"
								placeholder="Skriv inn telefonnummeret ditt"
								name="phone_number"
								dialCodeName="dial_code"
								value={phone_number ? phone_number : ''}
								onDialCodeChange={onInputChange}
								onChange={onInputChange}
								onBlur={validateInput}
							/>

							{error.phone_number && (
								<Flex as="span" style={{ color: 'red' }}>
									{error.phone_number}
								</Flex>
							)}
						</Flex>
						<View
							width="100%"
							position="absolute"
							right={'0px'}
							left={'0px'}
							bottom={'56px'}
							margin={'0'}
							display="flex"
							textAlign="center"
							justifyContent="center"
						>
							<SecondaryButton
								property1={error.phone_number || phone_number === '' ? 'disabled' : 'enabled'}
								onClick={
									error.phone_number || phone_number === '' ? null : () => setSignUpType('2FA')
								}
								overrides={{
									label: {
										children: 'Neste',
									},
								}}
							/>
						</View>
					</View>
				</View>
			)}
			{signUpType === '2FA' && (
				<View>
					<View
						height="34px"
						backgroundColor="#F3F7F7"
						position="fixed"
						top="0px"
						left="0px"
						right="0px"
						width="100%"
						style={{ zIndex: 10 }}
					/>
					<View
						position="fixed"
						top="31px"
						left="0px"
						right="0px"
						width="100%"
						style={{ zIndex: 100 }}
						height="48px"
						backgroundColor="#F3F7F7"
						alignItems="center"
						display="flex"
						justifyContent="center"
					>
						<View>
							<div
								onClick={() => goBackFrom2FA()}
								style={{ position: 'absolute', left: '16px', top: '4px' }}
							>
								<img src={Icons.arrowBackBig} alt="back" />
							</div>
						</View>

						<Text textAlign={'center'} color={'#29666A'} fontSize="16px">
							Godkjenning
						</Text>
					</View>

					{/*  */}
					<Heading
						paddingTop={'84px'}
						fontWeight="light"
						color="#76B3B8"
						marginBottom="large"
						textAlign="center"
					>
						To-faktor-autentifisering
					</Heading>
					<Flex
						direction="column"
						textAlign="center"
						marginBottom="large"
						alignItems="center"
						gap="0.5rem"
					>
						<img src={Icons.info} alt="info" />
						<Text color="#5D7679" fontSize={'14px'}>
							For å øke sikkerheten vil vi bekrefte din epost og ditt telefonnummer med
							to-faktor når du registrer deg. Det vil si at du først mottar en kode på epost, og
							så en kode på sms som du skriver inn i de neste stegene.
						</Text>
					</Flex>
					<Flex direction="column" alignItems="center" marginTop="large" gap="3rem">
						<ReCAPTCHA
							sitekey={process.env.REACT_APP_SITE_KEY}
							name="captcha"
							onChange={captchaChange}
						/>
						{sentEmail && !signUpError ? (
							<SecondaryButton
								property1="done"
								overrides={{
									label: {
										children: 'Bekreftelseskode er sendt på e-post',
									},
								}}
							/>
						) : (
							<SecondaryButton
								property1={formState.captcha === '' ? 'disabled' : 'enabled'}
								onClick={formState.captcha === '' ? null : signUp}
								overrides={{
									label: {
										children: 'Send koden på e-post',
									},
								}}
							/>
						)}
						{signUpError && (
							<Flex direction="column" alignItems="center" gap="large">
								<AlertN
									overrides={{
										body: { children: signUpError },
									}}
								/>
								<SecondaryButton
									onClick={() => navigate('/logg-inn')}
									overrides={{
										label: {
											children: 'Gå tilbake til innloggingssiden',
										},
									}}
								/>
							</Flex>
						)}
						{/* {captchaError && (
              <Flex direction="column" alignItems="center" gap="large">
                <AlertN
                  overrides={{
                    body: { children: captchaError },
                  }}
                />
              </Flex>
            )} */}
					</Flex>
					<View display={sentEmail && !signUpError ? 'block' : 'none'} marginTop="large">
						<Flex textAlign="center" direction="column" marginBottom="large">
							<Text>Mottok du ikke koden? </Text>
							<Text fontWeight="bold" onClick={resendConfirmSignUp}>
								{forgotPasswordMessage}
							</Text>
							{/*TO DO: Resend works??? */}
							{resendConfirmError && (
								<Flex direction="column" alignItems="center" gap="large">
									<AlertN
										overrides={{
											body: { children: resendConfirmError },
										}}
									/>
								</Flex>
							)}
						</Flex>
						{sentEmail && (
							<>
								<ActivityDetector
									activityEvents={customActivityEvents}
									enabled={true}
									timeout={20 * 1000}
									onIdle={() => setIsIdle(true)}
									onActive={console.log('the user is active')}
								/>
								{console.log('isIdle', isIdle)}
								{isIdle ? (
									<View marginTop="medium">
										<AlertN
											overrides={{
												body: {
													children:
														'Opplever du noen problemer med registreringen? Send oss en e-post på support@medforeldre.no.',
												},
											}}
										/>
									</View>
								) : (
									''
								)}
							</>
						)}
						<Flex as="form" direction="column" marginTop="4rem">
							<TextField
								style={{
									borderLeft: 0,
									borderRight: 0,
									borderTop: 0,
									borderRadius: 0,
									borderColor: '#76B3B8',
									letterSpacing: '1rem',
									textAlign: 'center',
								}}
								name="auth_code"
								label="Mottatt kode på e-post"
								placeholder="______"
								onChange={validationInputChange}
							/>
							<Flex
								direction="column"
								ali
								gnItems="center"
								marginTop="large"
								textAlign="center"
								gap="3rem"
							>
								{verifyCodeError && (
									<View>
										<AlertN
											overrides={{
												body: { children: verifyCodeError },
											}}
										/>
									</View>
								)}
							</Flex>
						</Flex>
					</View>
				</View>
			)}
			{signUpType === 'confirmLogin' && (
				<View>
					<View
						height="34px"
						backgroundColor="#F3F7F7"
						position="fixed"
						top="0px"
						left="0px"
						right="0px"
						width="100%"
						style={{ zIndex: 10 }}
					/>
					<View
						position="fixed"
						top="31px"
						left="0px"
						right="0px"
						width="100%"
						style={{ zIndex: 100 }}
						height="48px"
						backgroundColor="#F3F7F7"
						alignItems="center"
						display="flex"
						justifyContent="center"
					>
						<View>
							<div
								onClick={() => goBackFrom2FA()}
								style={{ position: 'absolute', left: '16px', top: '4px' }}
							>
								<img src={Icons.arrowBackBig} alt="back" />
							</div>
						</View>

						<Text textAlign={'center'} color={'#29666A'} fontSize="16px" fontWeight={'500'}>
							Godkjenning
						</Text>
					</View>

					<View textAlign="center" paddingTop="84px">
						<Heading marginBottom="medium">To-faktor-autentifisering</Heading>
					</View>
					<Flex
						direction="column"
						textAlign="center"
						marginBottom="4rem"
						alignItems="center"
						gap="0.5rem"
					>
						<img src={Icons.info} alt="info" />
						<Text>Skriv inn koden du har mottatt på sms.</Text>
						<Flex direction="column" marginTop="medium">
							<Text>Mottok du ikke koden?</Text>
							<Text fontWeight="bold" onClick={handleResendMessage}>
								{forgotPasswordMessage}
							</Text>
						</Flex>
						<ActivityDetector
							activityEvents={customActivityEvents}
							enabled={true}
							timeout={20 * 1000}
							onIdle={() => setIsIdle(true)}
							onActive={console.log('the user is active')}
						/>
						{console.log('isIdle', isIdle)}
						{isIdle ? (
							<View marginTop="medium">
								<AlertN
									overrides={{
										body: {
											children:
												'Opplever du noen problemer med registreringen? Send oss en e-post på support@medforeldre.no.',
										},
									}}
								/>
							</View>
						) : (
							''
						)}
					</Flex>
					<TextField
						style={{
							borderLeft: 0,
							borderRight: 0,
							borderTop: 0,
							borderRadius: 0,
							borderColor: '#76B3B8',
							letterSpacing: '1rem',
							textAlign: 'center',
						}}
						name="mfa_code"
						label="Mottatt kode"
						placeholder="______"
						onChange={onMFAInputChange}
					/>
					<Flex direction="column" alignItems="center" marginTop="large">
						{loginError && (
							<AlertN
								overrides={{
									body: { children: loginError },
								}}
							/>
						)}
					</Flex>
				</View>
			)}
			{signUpType === 'confirm2fa' && (
				<Flex
					direction="column"
					textAlign="center"
					marginTop="4rem"
					marginBottom="large"
					alignItems="center"
					gap="2rem"
				>
					<Verifiedlarge />
					<Heading fontSize="x-large" fontWeight="light" color="#76B3B8" marginBottom="medium">
						Din konto ble bekreftet med to-faktor-autentisering
					</Heading>
					<SecondaryButton
						onClick={() => navigate('/bankID')}
						overrides={{
							label: {
								children: 'Gå til bankID verifisering',
							},
						}}
					/>
				</Flex>
			)}
			{signUpType === 'error2fa' && (
				<Flex
					direction="column"
					textAlign="center"
					marginTop="4rem"
					marginBottom="large"
					alignItems="center"
					gap="2rem"
				>
					<Errorfailedlarge />
					<Heading fontSize="x-large" fontWeight="light" color="#76B3B8" marginBottom="medium">
						Godkjenning mislyktes
					</Heading>
					<Flex
						direction="column"
						textAlign="center"
						marginBottom="large"
						alignItems="center"
						gap="0.5rem"
					>
						<IconsNew property1="info" />
						<Text color="#899D9F">
							Bekreftelsen feilet grunnet for mange forsøk på to-faktor-autentiseringen.
							Vennligst prøv igjen om 10 minutter.
						</Text>
						{/* TO DO: put an onchange on the text below */}
						<Text fontWeight="bold">Trenger du hjelp med to-faktor-autenfisering?</Text>
					</Flex>
					{/* TO DO: create a 10 minuts count down */}
					<SecondaryButton
						onClick={() => setSignUpType('email')}
						overrides={{
							label: {
								children: 'Prøv igjen',
							},
						}}
					/>
				</Flex>
			)}
		</View>
	);
}
