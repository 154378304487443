import React, { useEffect } from "react";
import { SecondaryButton } from "../../ui-components";
import { View, Text, Flex, Heading, Link } from "@aws-amplify/ui-react";
import { Icons } from "../../assets/icons";

export default function PrivacyPolicy({ signUpPage, closePrivacyPage }) {
  function backButton() {
    if (signUpPage) {
      closePrivacyPage("password");
    } else {
      window.history.back();
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Flex
        direction="row"
        alignItems="center"
        margin="0 auto"
        justifyContent="center"
        position="fixed"
        top="0px"
        left="0px"
        width="100%"
        height="80px"
        style={{ zIndex: "10" }}
        backgroundColor={"#F2F6F6"}
      >
        <View
          paddingLeft="15px"
          marginRight="auto"
          onClick={() => backButton()}
        >
          <img src={Icons.arrowBackBig} alt="back" />
        </View>
        <View
          display="flex"
          direction="column"
          position="fixed"
          textAlign="center"
          justifyContent="center"
          gap="0.2rem"
        >
          <Text textAlign={"center"} fontWeight={"500"} color="#48676A">
            Medlemsvilkår og
          </Text>

          <Text textAlign={"center"} fontWeight={"500"} color="#48676A">
            Personvernerklæring
          </Text>
        </View>
      </Flex>

      <View
        style={
          signUpPage
            ? { padding: "16px 0px 10px" }
            : { padding: "64px 16px 10px" }
        }
      >
        <Flex direction="column" textAlign="center" marginTop="2rem">
          <Heading
            marginTop={"32px"}
            fontSize="20px"
            fontWeight={"500"}
            color="#6B9A9E"
            marginBottom={"16px"}
          >
            Medlemsvilkår
          </Heading>
          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            INNLEDENDE BESTEMMELSER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Velkommen til Medforeldres medlemstjenester. Disse vilkårene (“Medlemsvilkårene”) angir de juridisk bindende vilkårene for bruk av tjenestene som Medforeldre AS tilbyr til sine medlemmer via vår personlige rådgivningstjeneste, samt vår web-/mobilapplikasjon; «Medlemssidene» (samlet “Tjenesten”). Tjenesten leveres av Medforeldre AS, organisasjonsnummer 928 948 889 («Medforeldre», eller «vi» / «oss»). Vilkårene utgjør en avtale mellom deg og oss, og definerer hvilke rettigheter og hvilket ansvar du har når det gjelder tjenesten.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Det er viktig at du leser og forstår vilkårene, og ved å registrere deg som medlem bekrefter du at du har lest og forstått vilkårene og alle andre dokumenter som det vises til når du registrerer deg, som f.eks. personvernerklæringen.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            ANSVARSFORHOLD
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Alt innhold i våre tjenester har som formål å bidra til at din Medforeldre-reise skal bli bedre og tryggere. Din tilgang til og bruk av Tjenesten er likevel på egen risiko. Tjenesten er ment å være et utgangspunkt for refleksjon og dialog med andre. Om du opplever vanskeligheter er det viktig at du søker kompetent og profesjonell hjelp. Medforeldre er ikke ansvarlig for at alt vårt innhold passer for alle som bruker Tjenesten. Om du opplever at den ikke passer for deg, ber vi om at du revurderer om du skal fortsette å bruke Tjenesten. Alle er selv ansvarlig for å behandle både seg selv og sine medmennesker på en lovlig og etisk forsvarlig måte. Medforeldre AS er ikke ansvarlig for enkeltmedlemmers handlinger, materiale eller opplysninger, ei heller eventuelle konsekvenser av dette. Dette gjelder f.eks. om medlemmer oppgir mangelfulle eller uriktige opplysninger om seg selv.
          </Text>
          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Vi leverer en tjeneste som skal oppfylle gjeldende lover og forskrifter. Utover dette påtar vi oss ikke ansvar for forstyrrelser eller funksjonsfeil i Tjenesten. Vi og våre ansatte, tilknyttede partnere, og tilknyttede selskaper, har ikke ansvar for noen form for direkte eller indirekte konsekvens eller skade som følge av:
            <span style={{ display: "block" }}>
              (i) Din tilgang til eller bruk av Tjenesten, eller din manglende tilgang til eller bruk av Tjenesten
            </span>
            <span style={{ display: "block" }}>
              (ii) Tredjeparts oppførsel eller innhold i Tjenesten
            </span>
            <span style={{ display: "block" }}>
              (iii) Innhold og/eller opplysninger ervervet via Tjenesten eller tillit til Tjenesten eller deler av den
            </span>
            <span style={{ display: "block" }}>
              (iv) Uautorisert tilgang, bruk eller forandring av materiale eller innhold
            </span>
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            uansett om vi visste eller burde visst om muligheten for slike skader.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Tjenesten kan fra tid til annen være helt eller delvis utilgjengelig på grunn av systemvedlikehold eller andre årsaker. Vi kommer i størst mulig grad til å gi deg informasjon om eventuelle begrensninger i tilgjengeligheten.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            BEHANDLING OG BESKYTTELSE AV PERSONOPPLYSNINGER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            I vår Personvernerklæring finner du informasjon om hvordan vi behandler dine personopplysninger, hvordan vi beskytter din integritet når du bruker Tjenesten, og informasjon om dine rettigheter etter gjeldende personvernlovgivning. Ved signatur på vårt Samtykkeskjema eller avkrysning i egen boks under registrering på Medlemssidene, bekrefter du at du gir oss lov til å behandle bestemte personopplysninger om deg.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            REGLER FOR BRUK AV TJENESTEN
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Ditt medlemskap hos Medforeldre er personlig og du kan ikke la andre utgi seg for å være deg eller la andre bruke kontoen din på Medlemssidene. Du har ansvar for at andre ikke får tak i dine innloggingsopplysninger til Medlemssidene og du må ta kontakt med oss om du tror noen kan ha fått tak i disse. Ved bruk av tjenesten må du følge norsk lov og reglene for bruk som oppgis i disse vilkårene. Alle våre medlemmer skal ha lest vårt verdigrunnlag på vår nettside og opptre etisk forsvarlig i dialog med andre. Dette innebærer også at du oppgir riktig informasjon om deg selv, og har ærlige intensjoner. Som medlem godtar du at vi kan stenge din brukertilgang og avslutte ditt medlemskap dersom vi vurderer at du har brutt våre regler for bruk av Tjenesten.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Følgende regler gjelder for bruk av Tjenesten:
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            <span style={{ display: "block" }}>
              • Du må ha fylt 18 år for å bruke Tjenesten.
            </span>
            <span style={{ display: "block" }}>
              • Du må oppgi riktige opplysninger i samtaler med oss og i brukerkontoen din på Medlemssidene.
            </span>
            <span style={{ display: "block" }}>
              • Du må informere oss om relevante fysiske og/eller psykiske sykdommer eller tilstander.
            </span>
            <span style={{ display: "block" }}>
              • Du må informere oss dersom du har vært arrestert, siktet, tiltalt eller dømt for forbrytelser eller forseelser (unntatt forseelser med karakter som f.eks. fartsbøter). Herunder også ilagt besøksforbud mot noen, er etterlyst av myndigheter i inn- eller utland, eller har vært involvert i voldsepisoder eller lignende uten å bli siktet, tiltalt eller dømt for slikt.
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å bruke grovt, støtende, rasistisk eller trakasserende språk i bruk av Tjenesten.
            </span>
            <span style={{ display: "block" }}>
              • Du må sørge for at innhold som kan føre til identifisering av andre personer (f.eks. bilder/navn/annet), er avklart med vedkommende.
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å kopiere, spre eller videreformidle innlegg, chat, meldinger, brev eller annen informasjon som finnes i Tjenesten.
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å ha mer enn én brukerkonto.
            </span>
            <span style={{ display: "block" }}>
              • Du kan ikke bruke innhold som er immaterialrettslig beskyttet, der hvor opphavsretten tilhører noen andre enn deg (dette kan være musikk, bilder, film, logoer eller annet materiale som du ikke har tillatelse til å bruke).
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å bruke eller dele bilder av barn.
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å selge varer eller tjenester.
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å sende spam-meldinger, be om penger fra andre medlemmer, eller utsette noen for enhver form for svindel.
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å bruke bilder eller tekst med pornografisk innhold eller tydelige seksuelle hentydninger, herunder heller ikke selge eller markedsføre seksuelle tjenester mot betaling.
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å spre eller forsøke å spre religiøse eller politiske budskap til andre medlemmer.
            </span>
            <span style={{ display: "block" }}>
              • Det er ikke tillatt å bruke tjenesten på en slik måte at medlemmer eller Medforeldre AS utsettes for byrde eller skade.
            </span>
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Vær oppmerksom på at vi ikke undersøker brukergenerert innhold fra medlemmer (tekst, bilder, annen profilinformasjon) før det gjøres tilgjengelig for andre medlemmer på Medlemssidene.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            KONTROLL OVER BRUKERKONTOER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre kan deaktivere eller slette eksisterende brukerkontoer, avslutte medlemskap, eller på andre måter begrense bruken av Tjenesten dersom vi mener det er saklig grunn for dette. Eksempler på saklig grunn er f.eks. brudd på medlemsvilkårene, eller at hensynet til andre medlemmer eller Medforeldres omdømme tilsier det. Vi vil normalt varsle om dette på medlemmets registrerte e-postadresse før en konto blir slettet eller et medlemskap blir avsluttet. Ved gjentatte overtredelser eller dersom overtredelsens karakter tilsier det, vil det ikke bli sendt varsel. Medlemmet vil ikke få refundert sin betaling ved stenging av en brukerkonto eller avslutting av et medlemskap.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre forbeholder seg retten til å deaktivere brukerkontoer som ikke har vært brukt på 2 år.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            AVGIFT FOR TJENESTEN OG ULIKE TYPER MEDLEMSKAP
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Tjenestens innhold og de ulike medlemskapene som til enhver tid tilbys presenteres i epost til medlemmer før innmelding. Prisene som oppgis er inkl. mva. Vi kan endre prisene og typer medlemskap, men dersom du allerede har et medlemskap som løper, vil prisen på dette ikke endres for deg med mindre du selv velger et nytt medlemskap.
          </Text>
              
          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            AVTALETID/OPPSIGELSE
          </Heading>

          {/* here */}
          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Alle medlemskap gjelder fra innmeldingsdato og fortsetter til det sies opp. Medlemskapet løper månedsvis fra den 1. i hver måned. Medlemskapet fornyes automatisk fram til oppsigelse. 
            Du kan si opp ditt medlemskap ved å sende en epost til{" "}
            <Link
              onClick={() =>
                (window.location = "mailto:support@medforeldre.no")
              }
            >
              <span style={{ borderBottom: "1px solid #5C7578" }}>
                support@medforeldre.no
              </span>
            </Link>{" "}
            eller kontakte oss via kontaktskjemaet på medlemssidene.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Ved oppsigelse løper betaling ut perioden (iht bindingstid per pakke). Oppsigelse må skje senest fem dager før ny periode starter.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre har rett til å si opp medlemskapet ditt med tretti (30) dagers varsel. Vi har også rett til uten forvarsel å si opp eller fryse medlemskapet ditt ved vesentlig eller gjentatte brudd på bestemmelser i vilkårene for bruk av tjenesten.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            BETALING
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre bruker Vipps som betalingsløsning eller sender faktura på epost.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            ANGRERETT
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Du har rett til å angre kjøpet ditt innen fjorten (14) dager fra kjøpsdatoen. Hvis du ikke er fornøyd med tjenesten og ønsker å bruke angreretten, tar du kontakt med oss på support@medforeldre.no. Vi tilbakebetaler din avgift med et fradrag for den tiden du har benyttet tjenesten. Angreskjema finner du her eller får tilsendt av oss.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            TJENESTER SOM YTES AV TREDJEPARTER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Tjenesten kan videreformidle informasjon fra eller til tredjepartstjenester som er nødvendig for at tjenesten skal fungere tilfredsstillende. Dette er nødvendig for eksempel for autentisering via BankID, eller opplysninger for å få til betaling. Medforeldre videreformidler ikke personopplysninger tilannonsepartnere eller andre kommersielle tredjeparter for behandling utover det som er nødvendig for bruk av denne Tjenesten (se mer informasjon i vår Personvernerklæring).
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre er ikke ansvarlig for tjenester som tilbys av samarbeidspartnere eller andre nettsteder som det lenkes til. Du er selv ansvarlig for sikkerheten på dine egne enheter, systemer, innloggingsinformasjon og handlinger du utfører.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            IMMATERIELLE RETTIGHETER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre AS har de immaterielle rettighetene til tekst, bilder, design, programkode eller annet materiale og informasjon som gjøres tilgjengelig for deg gjennom Tjenesten.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Disse vilkårene gir deg kun retten til å bruke Tjenesten så lenge disse vilkårene og eventuelle spesifiserte lisenser gjelder, og de overdrar ingen rettigheter til eierskap til tjenesten. Alle rettigheter, eierskap, interesser, enhver opphavsrett, ethvert patent, forretningshemmelighet eller annen immateriell rettighet knyttet til tjenesten, forblir Medforeldre AS sin eneeiendom. Slikt materiale og opplysninger kan ikke benyttes på annen måte enn innenfor rammene for normal bruk av Tjenesten.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            OVERTAKELSE
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Ved å bli medlem godkjenner du at avtalen du har med oss kan overtas av et annet selskap eller til tredjepart i forbindelse med oppkjøp eller virksomhetsoverdragelse som inkluderer Tjenesten.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            ENDRINGER I VÅRT TILBUD
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Vi jobber hele tiden med å forbedre og videreutvikle vårt tilbud, og kan når som helst endre Tjenesten. Vi kan når som helst også beslutte å avvikle Tjenesten eller erstatte denne, eller deler av denne, med en annen tjeneste. Dersom Tjenesten avvikles, vil eventuell forskuddsbetalt kostnad for Tjenesten bli tilbakebetalt til deg.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Om du opplever brudd eller funksjonsfeil i Tjenesten, kan du
            rapportere dette til{" "}
            <Link
              onClick={() =>
                (window.location = "mailto:support@medforeldre.no")
              }
            >
              <span style={{ borderBottom: "1px solid #5C7578" }}>
                support@medforeldre.no
              </span>
            </Link>{" "}
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            ENDRINGER I MEDLEMSVILKÅRENE
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Vilkårene kan bli oppdatert og endret fra tid til annen. Dersom vi foretar vesentlige endringer som krever ditt samtykke, vil vi sende deg en epost om dette før endret behandling påbegynner.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            SKADELØSHOLDELSE
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Ved å registrere deg som medlem og godta disse medlemsvilkårene, samtykker du til å holde oss skadeløse for ethvert krav, uavhengig om det er fremsatt av tredjepart på bakgrunn av eller som følge av brudd på disse vilkårene, eller overtredelse av enhver lov eller krenkelse av en tredjeparts rettigheter. Intet i disse vilkårene skal anses for å ekskludere eller begrense ditt ansvar hva angår den skadesløsholdelse du gir under disse vilkårene.
          </Text>
          {/*  */}
          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            JURISDIKSJON OG TVISTER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Denne avtalen skal tolkes og gjennomføres i henhold til norsk lov. Eventuelle tvister skal søkes løst ved forhandlinger. Dersom dette ikke fører frem, kan hver av partene bringe tvisten inn for de alminnelige domstoler. Oslo tingrett antas som verneting. 
            Du kan også ta kontakt med Forbrukerrådet ved behov, les mer på{" "}
            <Link onClick={() => (window.location = "www.forbrukerradet.no")}>
              <span style={{ borderBottom: "1px solid #5C7578" }}>
                www.forbrukerradet.no
              </span>
            </Link>{" "}
            .
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            KONTAKTINFORMASJON
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre AS
          </Text>
          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Tordenskioldsgate 2
          </Text>
          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            0160 Oslo
          </Text>

          <Text
            textAlign={"left"}
            fontSize="14px"
            color="#5C7578"
            marginTop={"10px"}
          >
            {" "}
            <Link
              onClick={() =>
                (window.location = "mailto:support@medforeldre.no")
              }
            >
              <span style={{ borderBottom: "1px solid #5C7578" }}>
                support@medforeldre.no
              </span>
            </Link>{" "}
          </Text>

          <Heading
            marginTop={"32px"}
            fontSize="20px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            Personvernerklæring
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Personopplysninger er opplysninger som kan kobles til deg som person. Personopplysninger kan være navn, IP-adresse, kontaktopplysninger mv. Personopplysninger kan også være annen informasjon som kan knyttes til deg indirekte. I denne personvernerklæringen kan du lese om hvilke personopplysninger Medforeldre AS er behandlingsansvarlig for. Det er viktig for Medforeldre AS at du vet hva slags personopplysninger vi behandler, slik at du kan ivareta dine rettigheter etter personvernlovgivningen.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            BEHANDLINGSANSVAR
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre AS er behandlingsansvarlig for all behandling av personopplysninger der vi selv bestemmer formålet med behandlingen av opplysningene og de virkemidlene vi benytter til dette. I denne personvernerklæringen kan du lese mer om behandlingene Medforeldre AS er ansvarlige for.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Dersom du har spørsmål om denne personvernerklæringen eller vurderingene vi har gjort, kan du kontakte oss på
            {" "}
            <Link
              onClick={() =>
                (window.location = "mailto:support@medforeldre.no")
              }
            >
              <span style={{ borderBottom: "1px solid #5C7578" }}>
                support@medforeldre.no
              </span>
            </Link>{" "}
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            NÅR SAMLER MEDFORELDRE AS INN PERSONOPPLYSNINGER?
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Vi behandler i hovedsak opplysninger om deg i følgende tilfeller:
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            <span style={{ display: "block" }}>
              • Du har sendt oss en henvendelse på epost eller ringt oss på telefon
            </span>
            <span style={{ display: "block" }}>
              • Du besøker vår nettside (www.medforeldre.no)
            </span>
            <span style={{ display: "block" }}>
              • Du har opprettet bruker på medlemssidene
            </span>
            <span style={{ display: "block" }}>
              • Du har opprettet profil på medlemssidene
            </span>
            <span style={{ display: "block" }}>
              • Du mottar tjeneste for personlig matching mellom medforeldre
            </span>
            <span style={{ display: "block" }}>
              • Du benytter deg av andre tjenestene vi tilbyr (rådgivning etc.)
            </span>
            <span style={{ display: "block" }}>
              • Du betaler for medlemskap
            </span>
            <span style={{ display: "block" }}>
              • Du har søkt jobb hos oss
            </span>
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            DU HAR SENDT OSS EN HENVENDELSE
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Telefon: Når du ringer til oss vil telefonnummeret ditt bli lagret sammen med opplysninger om når du ringte og hvor lenge samtalen varte. Loggen lagres i ca. to måneder. Loggen lagres for å kunne følge opp telefonsamtaler ved behov, for eksempel for å ringe opp personer som har kontaktet oss for en avtale, tidspunkt for rådgivning eller liknende. Behandlingsgrunnlaget for dette er EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav f.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            E-post: Vi benytter e-post for å utføre våre arbeidsoppgaver samt for dialog ved henvendelser fra deg. E-post vi har mottatt blir slettet når den ikke lenger er nødvendig for vår daglige oppgaveløsning. Vi ber om at særlige kategorier av personopplysninger ikke sendes på ukryptert e-post. Behandlingsgrunnlaget vårt for å behandle personopplysninger i e-poster er EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav f.
            Vi skanner all inn og utgående e-post for virus og skadevare for å sikre integriteten, konfidensialiteten og tilgjengeligheten i våre systemer som behandler personopplysninger. Behandlingsgrunnlaget for skanningen er EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav c. Medforeldre AS har en rettslig plikt til å sikre informasjonssikkerhet etter EUs personvernforordning (GDPR) artikkel 5 nr. 1 bokstav f og 32. 
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            DU BESØKER VÅRE NETTSIDER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Informasjonskapsler (cookies) og statistikk: Informasjonskapsler er små tekstfiler som plasseres på datamaskinen din når du laster ned en nettside. Noen av informasjonskapslene er nødvendige for at ulike tjenester på nettsiden vår skal fungere.
            Viktig informasjon: Vi gjør oppmerksom på at vår cookie-erklæring er under editering grunnet pågående implementering av ytterligere/forbedrede sikkerhetstiltak på våre nettsider.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            DU OPPRETTER BRUKER OG PROFIL PÅ MEDLEMSSIDENE
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Opprette bruker: Medforeldre gir deg mulighet til å opprette en bruker og en profil på våre medlemssider (app.medforeldre.no). Når du oppretter en bruker legger du først inn informasjon om din e-postadresse og ditt telefonnummer. For å verifisere din identitet benyttes så BankID hvor du også registrerer fødsels- og personnummer. Fødselsnummer, personnummer, e-post, navn og telefonnummer er ikke tilgjengelig for andre medlemmer. Du kan ikke selv endre denne informasjonen, og må ta kontakt med oss dersom dette er nødvendig. For å skjerme ditt privatliv, velger du selv et profilnavn (kallenavn) som blir din virtuelle identitet overfor andre medlemmer (se mer under «fylle ut profil» under) Av sikkerhetsgrunner loggfører vi IP-adressen din og bruksmønster på siden.
            Behandlingsgrunnlaget er basert på EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav f. Personopplysningene slettes fra den tiden medlemmet ikke lenger ønsker å være registrert i løsningen eller dersom vedkommende trekker tilbake samtykket. Dersom brukeren er inaktiv i 2 år eller mer slettes personopplysningene. Personopplysningene kan også slettes i tråd med årsaker som beskrevet i Medlemsvilkår.
            Ved bruk av BankID er BankID databehandler. BankID tilbyr verifiseringsfunksjonalitet og behandler i den forbindelse personopplysninger på vegne av oss. Medforeldre AS har inngått databehandleravtale med BankID. Databehandleren vil kun ha anledning til å behandle personopplysningene de får tilgang til gjennom en slik avtale etter dokumenterte instrukser fra Medforeldre AS.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Pålogging og glemt passord: For å opprette bruker og senere logge på løsningen, kreves det at du lager et passord. Dette krypteres med vilkårlig nøkkel og passordet kan ikke hentes ut igjen. Skulle du glemme passordet ditt sendes det en resetlink til e-postadresse du har registrert kontoen din på.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Fylle ut profil: I profilen din må du legge inn et profilnavn (fiktivt eller reelt) og bostedsfylke. Alder kalkuleres automatisk basert på BankID-prosessen. Utover dette, kan du selv velge om du vil legge inn valgfri informasjon som andre medlemmer kan se. Her kan du eksempelvis legge inn informasjon om kjønnsidentitet, seksuell orientering, samlivsform, interesser, beskrivende tekster og bilder. Disse opplysningene er tilgjengelige for andre medlemmer som besøker profilen din. Disse opplysningene kan du selv endre og slette.
            Behandlingsgrunnlaget er basert på EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav a og artikkel 9 nr. 2 bokstav a. Personopplysningene slettes fra den tiden medlemmet ikke lenger ønsker å være registrert i løsningen eller dersom vedkommende trekker tilbake samtykket. Dersom brukeren er inaktiv i 2 år eller mer slettes personopplysningene. Personopplysningene kan også slettes i tråd med årsaker som beskrevet i Medlemsvilkår.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            DU MOTTAR TJENESTE FOR PERSONLIG MATCHING OG RÅDGIVNING
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            For medlemmer som ønsker å benytte seg av personlig matching, behandles opplysninger om: 
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            <span style={{ display: "block" }}>
              • Intensjon og motivasjon
            </span>
            <span style={{ display: "block" }}>
              • Ønsker om familiesammensetning/-utførelse
            </span>
            <span style={{ display: "block" }}>
              • Kjerneverdier
            </span>
            <span style={{ display: "block" }}>
              • Livssyn
            </span>
            <span style={{ display: "block" }}>
              • Livsstil og helse
            </span>
            <span style={{ display: "block" }}>
              • Personlighet
            </span>
            <span style={{ display: "block" }}>
              • Utdanning og yrkesstatus
            </span>
            <span style={{ display: "block" }}>
              • Økonomisk situasjon
            </span>
            <span style={{ display: "block" }}>
              • Relasjons-/familiehistorikk
            </span>
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Opplysningene innhentes fra deg i samtale med vår rådgiver. Behandlingsgrunnlaget er basert på EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav a og artikkel 9 nr. 2 bokstav a.  Samtykket med tilhørende informasjon om behandlingen, herunder hvilke rettigheter du har blir innhentet i forkant av samtalen, før behandlingen eventuelt starter opp. 
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Opplysningene innhentes fra deg i samtale med vår rådgiver. Behandlingsgrunnlaget er basert på EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav a og artikkel 9 nr. 2 bokstav a.  Samtykket med tilhørende informasjon om behandlingen, herunder hvilke rettigheter du har blir innhentet i forkant av samtalen, før behandlingen eventuelt starter opp. 
            Personopplysningene slettes dersom samtykket trekkes tilbake, dersom medlemmet melder seg ut og ber om å få slettet disse, eller medlemmet ikke har mottatt tjenester fra oss på to år. 
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            SAMTALE/TEST MED PSYKOLOG/FAMILIETERAPEUT/ADVOKAT
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Behandling av personopplysninger som inngår i samtaler med psykologer, familieterapeuter og advokater er ikke Medforeldre AS behandlingsansvarlig eller databehandler for.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Våre samarbeidende rådgivere (psykologer, familieterapeuter, advokater og fertilitetsrådgivere) er underlagt egen taushetsplikt. Det vil si at dersom du har en samtale med for eksempel en psykolog, vil det dere snakker om være taushetsbelagt og ikke deles med de andre rådgiverne. Du bestemmer selv hva du ønsker å dele med våre ulike rådgivere.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Unntaket er dersom du tar en personlighetstest, og aktivt samtykker til at vi i forbindelse med testen og påfølgende samtale i forkant kan dele relevant informasjon med utførende psykolog for en mer effektiv gjennomføring av test og samtale, samt at vi kan inkludere overordnet resultat fra personlighetstesten i ditt matching-grunnlag. Innholdet i selve samtalen vil likevel være taushetsbelagt og ikke deles mellom rådgivere.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            DU BETALER FOR MEDLEMSKAP
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Når du er medlem hos oss forutsetter tjenestene at du betaler en månedlig kostnad for tjenestene du benytter. Formålet med behandlingen av personopplysninger er å ta betalt for medlemskap og tilhørende tjenester hos oss. Behandlingsgrunnlaget for dette er EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav b (avtale). For å kunne motta betaling for tjenester behandler vi informasjon om navn, telefonnummer, adresse og e-post. Vi benytter e-faktura og vipps. I den forbindelse benytter vi databehandlere som vi har inngått databehandleravtaler med. Inngående og utgående fakturaer oppbevares i 5 år i tråd med kravene i bokføringsloven.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            DU SØKER JOBB HOS OSS
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Dersom du søker jobb hos Medforeldre AS, trenger vi å behandle opplysninger om deg for å vurdere din søknad. Ansettelsesprosessen innebærer behandling av de opplysningene du oppgir til oss gjennom dokumentene du sender til oss, blant annet søknad, CV, vitnemål og attester. I tillegg til eventuelle intervju, kan Medforeldre også gjennomføre egne undersøkelser, typisk samtaler med jobbsøkerens referanser.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            For å vurdere innsendt dokumentasjon, gjennomføre intervjuer og ringe referanser er behandlingsgrunnlaget EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav b. Denne bestemmelsen tillater oss å behandle personopplysninger når det er nødvendig for å gjennomføre tiltak på jobbsøkers anmodning før en avtale inngås. Ved å søke på stillingen og laste opp dokumenter anser vi at jobbsøkeren ber oss vurdere innsendt dokumentasjon, gjennomføre intervjuer og ringe referanser med sikte på å inngå en arbeidsavtale.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Dersom vi gjør egne undersøkelser ut over dette, for eksempel å kontakte noen som har utstedt en attest, men som ikke er oppgitt som referanse, er behandlingsgrunnlaget for slike undersøkelser EUs personvernforordning (GDPR) artikkel 6 nr. 1 bokstav f, som tillater oss å behandle opplysninger som er nødvendig for å vareta en berettiget interesse som veier tyngre enn hensynet til den enkeltes interesser eller grunnleggende rettigheter og friheter. Den berettigete interessen er å finne rett kandidat til stillingen. Jobbsøknader slettes etter 30 dager og søknader hvor søker kommer videre til intervju sletter innen 60 dager.
          </Text>

          <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            FORHOLD TIL TREDJEPARTER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Tjenesten kan videreformidle informasjon fra eller til tredjepartstjenester som er nødvendig for at tjenesten skal fungere som tilfredsstillende. Dette er nødvendig for eksempel for autentisering via BankID, eller opplysninger for å få til betaling. 
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Medforeldre videreformidler ikke personopplysninger til annonsepartnere eller andre kommersielle tredjeparter for behandling utover det som er nødvendig for bruk av denne Tjenesten. Medforeldres ansvar for tredjepartstjenesters behandling av personopplysninger er regulert i, og begrenses til, databehandleravtaler med disse. Ved oppstart av Tjenesten har vi databehandleravtale med følgende tredjeparter: 
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            <span style={{ display: "block" }}>
              • Signicat AS (BankID)
            </span>
            <span style={{ display: "block" }}>
              • Sparebank1 Nord-Norge (fakturering)
            </span>
            <span style={{ display: "block" }}>
              • Kjerneverdier
            </span>
          </Text>

           <Heading
            marginTop={"16px"}
            textAlign={"left"}
            fontSize="16px"
            fontWeight={"500"}
            color="#6B9A9E"
          >
            DINE RETTIGHETER
          </Heading>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Du kan utøve dine rettigheter ved å sende oss e-post til support@medforeldre.no.
            Du har krav på svar uten ugrunnet opphold, og senest innen en måned. 
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Innsyn i egne opplysninger: Du kan be om kopi av alle opplysninger vi behandler om deg. For å be om kopi av egne personopplysninger, må du kontakte Medforeldre AS på support@medforeldre.no. 
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Korrigering av personopplysninger: Du kan be oss om å rette eller supplere opplysninger som er feilaktige eller misvisende. For å få rettet eller supplert opplysninger, må du kontakte Medforeldre AS på support@medforeldre.no. 
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Sletting av personopplysninger: Du kan be oss om å slette opplysninger om deg selv. For å få slettet opplysninger, må du kontakte Medforeldre AS på support@medforeldre.no. 
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Begrensning av behandling av personopplysninger: I noen situasjoner kan du be oss om å begrense behandlingen av opplysninger om deg (du kan lese mer på datatilsynets nettsider).
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Protestere mot behandling av personopplysninger: Dersom vi behandler personopplysninger på bakgrunn av interesseavveiing, har du rett til å protestere på vår behandling av personopplysninger om deg.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Dataportabilitet: Dersom vi behandler opplysninger om deg med grunnlag i samtykke eller en kontrakt, kan du be oss om å overføre opplysninger om deg til deg eller til annen behandlingsansvarlig.
          </Text>

          <Text textAlign={"left"} fontSize="14px" color="#5C7578">
            Du kan klage på vår behandling av personopplysninger: Si ifra dersom du mener vi ikke overholder reglene i personopplysningsloven. Si gjerne først i fra gjennom den kontakten eller kanalen du allerede har etablert med oss. Du har rett til å klage til Datatilsynet dersom du mener at behandlingen av dine personopplysninger er lovstridig.
          </Text>

          <Flex
            direction="column"
            alignItems="center"
            marginTop="large"
            marginBottom={"56px"}
          >
            <SecondaryButton
              onClick={() => backButton()}
              overrides={{
                label: {
                  children: "Gå tilbake til forrige side",
                },
              }}
            />
          </Flex>
        </Flex>
      </View>
    </>
  );
}
