import { useState, useEffect } from "react";
import { Text, View, TextField, TextAreaField } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../../assets/icons";
import { GalleryPicture } from "../../components/UI/GalleryPicture";
import { PrimaryButton } from "../../ui-components";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function ContactForm() {
  const navigate = useNavigate();
  const location = useLocation();

  const [inputValue, setInputValue] = useState({ subject: "", text: "" });

  function handleSetInputValue(key, value) {
    setInputValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  function handleSendEmail() {
    if (inputValue.subject.length > 0 && inputValue.text.length > 0) {
      let data = `mailto:${
        location.state.email
          ? String(location.state.email).replace("~", "@")
          : ""
      }?subject=${inputValue.subject}&body=${inputValue.text}`;
      window.open(data, "_blank");
    }
  }

  function handleScrollUp() {
    window.scrollTo({ top: 0, left: 0 });
  }
  useEffect(() => {
    handleScrollUp();
  }, []);

  return (
    <>
      <TopNavigationBar
        header={location.state.title ? location.state.title : ""}
        isMedforeldrePage
        onArrowBackClick={() => navigate("/personlig-veiledning")}
        noIconBack
        onCancelClick={() => navigate("/personlig-veiledning")}
      />
      <Layout>
        <View
          paddingTop="104px"
          display="flex"
          direction="column"
          alignItems="center"
          width="100%"
        >
          <GalleryPicture
            isPersonalGuidancePage
            pictureData={location.state.imageUrl}
          />
          <Text color="#000000" fontSize={"16px"} marginTop="16px">
            {location.state?.name ? location.state.name : ""}
          </Text>
          <img
            style={{ marginTop: "16px", marginBottom: "7px" }}
            src={Icons.info}
            alt="info"
          />
          <Text
            color="#5D7679"
            fontSize={"14px"}
            textAlign="center"
            marginBottom="64px"
          >
            {location.state.paragraph ? location.state.paragraph : ""}
          </Text>
          <View width="100%" marginBottom="32px">
            <TextField
              fontSize={"20px"}
              errorMessage={{}}
              style={{ paddingLeft: "16px" }}
              height={"70px"}
              width={"100%"}
              variation="quiet"
              label={
                <Text color="#5B8386" fontWeight={"400"} fontSize="16px">
                  Emnefelt {""}
                  <span style={{ color: "#F6B6C4" }}>*</span>
                </Text>
              }
              placeholder={"Skriv emne/overskrift for hva det gjelder"}
              color={"#5B8386"}
              onChange={(e) => handleSetInputValue("subject", e.target.value)}
              value={inputValue.subject}
            />
          </View>
          <View width="100%" marginTop="20px" marginBottom="24px">
            <TextAreaField
              onChange={(e) => handleSetInputValue("text", e.target.value)}
              fontSize={"20px"}
              border="1px solid #A2C7CB"
              style={{ color: "#5B8386", background: "#ffffff" }}
              color="#5B8386"
              width={"100%"}
              label={
                <Text color="#5B8386" fontWeight={"400"} fontSize="16px">
                  Ditt spørsmål/din henvendelse{" "}
                  <span style={{ color: "#F6B6C4" }}>*</span>
                </Text>
              }
              value={inputValue.text}
              placeholder={"Forklar hva henvendelsen din gjelder her"}
            />
          </View>
          <Text marginBottom={"53px"}>
            Alle felter merket med <span style={{ color: "#A82E30" }}>*</span>{" "}
            er obligatoriske.{" "}
          </Text>
          {/* enabled disabled */}
          <View marginBottom="98px" display="flex" justifyContent="center">
            <PrimaryButton
              property1={
                inputValue.subject.length > 0 && inputValue.text.length > 0
                  ? "enabled"
                  : "disabled"
              }
              onClick={() => handleSendEmail()}
              overrides={{ label: { children: "Send" } }}
            />
          </View>
        </View>
      </Layout>
      <BottomNavigationBar buttonState="active" type={2} />
    </>
  );
}
