import React from "react";
import { Text, Flex, Card, View, Image } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import { UserAvatar } from "./UserAvatar";
import { Icons } from "../../assets/icons";

export function ConversationsCard({ conversation, onClickConversation }) {
  const navigate = useNavigate();
  const messages = conversation.Messages.items;
  const partner = conversation.partner;
  const fromYou =
    messages.at(-1).from_username === conversation.you.cognito_uuid;
  const isRead = messages.at(-1).isRead === "true" || fromYou;

  return (
    <Card
      variation={isRead ? "default" : "outlined"}
      backgroundColor="#FFFFFF"
      borderRadius={"8px"}
      height="64px"
      display="flex"
      alignItems="center"
      onClick={() => onClickConversation(conversation)}
    >
      <Flex direction="row" alignItems="center">
        <UserAvatar
          userImage={partner.avatarURL || ""}
          gender={partner.gender_identity || ""}
          membersCard
          onClickImage={() => navigate(`/medlem/${partner.cognito_uuid}`)}
        />
        <View>
          <Text fontSize={"16px"}>{partner.display_name || ""}</Text>
          <Text
            fontSize={"12px"}
            color={isRead ? "#9DAFB0" : "#000000"}
            style={{
              textOverflow: "ellipsis",
              fontSize: "12px",
              color: isRead ? "#9DAFB0" : "#000000",
              wordWrap: "break-word",
              overflow: "hidden",
              whiteSpace: "nowrap",
              width: "calc(100vw - 140px)",
            }}
          >
            {messages.length > 0 &&
              (messages[messages.length - 1].from_username ===
              partner.cognito_uuid
                ? partner.display_name
                : "Deg") +
                ": " +
                messages[messages.length - 1].message_text}
          </Text>
        </View>
      </Flex>
      {!isRead && (
        <Image marginLeft="auto" src={Icons.redDot} alt="Unread message" />
      )}
    </Card>
  );
}
