/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text } from "@aws-amplify/ui-react";
import IconsNew from "./IconsNew";
export default function SecondaryButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        label: {},
        IconsNew: {},
        "text + icon": {},
        SecondaryButton: {},
      },
      variantValues: { property1: "enabled" },
    },
    {
      overrides: {
        label: { color: "rgba(83,160,166,1)", display: "none" },
        IconsNew: { display: "block" },
        "text + icon": {},
        SecondaryButton: { padding: "11px 16px 11px 16px" },
      },
      variantValues: { property1: "loading" },
    },
    {
      overrides: {
        label: {
          fontSize: "14px",
          fontWeight: "400",
          color: "rgba(72,103,106,1)",
          lineHeight: "21px",
          children: "Confirmation text",
        },
        IconsNew: { display: "block", property1: "check" },
        "text + icon": {},
        SecondaryButton: {
          width: "358px",
          height: "46px",
          borderRadius: "8px",
          padding: "11px 16px 11px 16px",
          backgroundColor: "rgba(224,234,235,1)",
        },
      },
      variantValues: { property1: "done" },
    },
    {
      overrides: {
        label: {},
        IconsNew: {},
        "text + icon": {},
        SecondaryButton: { backgroundColor: "rgba(224,234,235,1)" },
      },
      variantValues: { property1: "on_tap" },
    },
    {
      overrides: {
        label: { color: "rgba(186,186,186,1)" },
        IconsNew: {},
        "text + icon": {},
        SecondaryButton: { border: "1px SOLID rgba(224,224,224,1)" },
      },
      variantValues: { property1: "disabled" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="0"
      direction="row"
      width="360px"
      height="48px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(107,154,158,1)"
      borderRadius="9px"
      padding="8px 16px 8px 16px"
      backgroundColor="rgba(255,255,255,1)"
      display="flex"
      {...getOverrideProps(overrides, "SecondaryButton")}
      {...rest}
    >
      <Flex
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        display="flex"
        {...getOverrideProps(overrides, "text + icon")}
      >
        <Text
          fontFamily="Inter"
          fontSize="20px"
          fontWeight="500"
          color="rgba(107,154,158,1)"
          lineHeight="30px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Secondary"
          {...getOverrideProps(overrides, "label")}
        ></Text>
        <IconsNew
          width="24px"
          height="24px"
          display="none"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          property1="loading"
          {...getOverrideProps(overrides, "IconsNew")}
        ></IconsNew>
      </Flex>
    </Flex>
  );
}
