import { useEffect } from "react";
import { Text, View } from "@aws-amplify/ui-react";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets/icons";

export function MedforeldreMenu() {
  const navigate = useNavigate();

  useEffect(() => {
    function handleScrollUp() {
      window.scrollTo({ top: 0, left: 0 });
    }
    handleScrollUp();
  }, []);

  return (
    <View>
      <TopNavigationBar header={"Medforeldre"} noIconBack />
      <Layout>
        <View paddingTop="120px">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "32px",
            }}
          >
            <img src={Icons.medforeldreMenuIcon} alt="medforeldre logo" />
          </div>

          <View
            onClick={() => navigate("/medforeldrereisen")}
            display="flex"
            direction="row"
            width="100%"
            alignItems="center"
            marginBottom="16px"
            height="72px"
          >
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Icons.journey} alt="journey" />
            </div>
            <Text fontSize={"20px"} color="#364D4F" fontWeight={"300"}>
              Medforeldre-reisen
            </Text>
          </View>

          <View
            onClick={() => navigate("/forskning-og-erfaringer")}
            display="flex"
            direction="row"
            width="100%"
            alignItems="center"
            marginBottom="16px"
            height="72px"
          >
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Icons.research} alt="settings" />
            </div>
            <Text fontSize={"20px"} color="#364D4F" fontWeight={"300"}>
              Forskning og erfaringer
            </Text>
          </View>

          <View
            onClick={() => navigate("/personlig-veiledning")}
            display="flex"
            direction="row"
            width="100%"
            alignItems="center"
            marginBottom="16px"
            height="72px"
          >
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "56px" }}
                src={Icons.guidance}
                alt="journey"
              />
            </div>
            <Text fontSize={"20px"} color="#364D4F" fontWeight={"300"}>
              Personlig veiledning
            </Text>
          </View>

          <View
            onClick={() => navigate("/om-medforeldre-as")}
            display="flex"
            direction="row"
            width="100%"
            alignItems="center"
            marginBottom="16px"
            height="72px"
          >
            <div
              style={{
                marginRight: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={Icons.about_us} alt="about us" />
            </div>
            <Text fontSize={"20px"} color="#364D4F" fontWeight={"300"}>
              Om Medforeldre AS
            </Text>
          </View>
        </View>
      </Layout>
      <BottomNavigationBar buttonState="active" type={2} />
    </View>
  );
}
