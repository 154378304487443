export const questionsAndAnswers = [
  {
    question: `Q: Hvorfor velger man medforeldreskap? 
       A: Det er mange ulike grunner til at våre medlemmer vurderer medforeldreskap.`,
  },
  {
    answers:
      "A: Vitae mi elit diam posuere erat. In enim vitae feugiat cras scelerisque ac nulla natoque aliquam.",
  },
  {
    question:
      "Q: Hvor mange er det som kan tenke seg å vurdere medforeldreskap i Norge?",
  },
  {
    answers:
      "A: Svar under arbeid.",
  },
  {
    question:
      "Q: Hvordan vet jeg om noen er en potensiell medforelder for meg?",
  },
  {
    answers:
      "A: Dette er et stort tema.",
  },
  {
    question:
      "Q: Er det noen juridiske begrensninger knyttet til medforeldreskap?",
  },
  {
    answers:
      "A: Det er ingen juridiske begrensninger til medforeldreskap i seg selv. Dersom man derimot er tre medforeldre, så er den en begrensning i lovverket knyttet til at man bare kan være to juridiske foreldre i Norge. Dette innebærer..",
  },
  {
    question:
      "Q: Hvordan gjør man det rent praktisk når man skal prøve å bli gravide?",
  },
  {
    answers:
      "A: Dette er et spørsmål vi får veldig ofte",
  },
  {
    question:
      "Q: Er dette en trygg tjeneste?",
  },
  {
    answers:
      "A: Vi har mange mekanismer som skal bidra til at dette er en trygg tjeneste.",
  },
];
