import React, { useEffect, useState, useContext, useRef } from "react";
import Compress from "client-compress";
import {
  Flex,
  Text,
  TextField,
  TextAreaField,
  View,
} from "@aws-amplify/ui-react";
import { PrimaryButton } from "../../ui-components";
import { Layout } from "../../components/Layout";
import { UserAvatar } from "../../components/UI/UserAvatar";
import { Spacer } from "../../components/UI/Spacer";
import { GalleryPicture } from "../../components/UI/GalleryPicture";
import { SelectFieldButton } from "../../components/UI/SelectFieldButton";
import { useNavigate } from "react-router";
import { getUserProfile } from "../../graphql/queries";
import { updateUserProfile } from "../../graphql/mutations";
import { ImagesDataContext, ProfileDataContext } from "../../contexts/contexts";
import { yupSchema } from "../../consts/yupSchema";
import imgUrl from "../../helpers/imageHelper";
import { API, Auth, Storage } from "aws-amplify";
import { GalleryEmptyPicture } from "../../components/UI/GalleryEmptyPicture";
import { Icons } from "../../assets/icons";
import { Loading as LoadingAnimation } from "../loading";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";
import uuid from "react-uuid";

export function ProfileSettings() {
  const { profileSettingsValues, setProfileSettingsValues } =
    useContext(ProfileDataContext);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [imagesLists, setImagesLists] = useState([]);
  const [avatarImage, setAvatarImage] = useState("");

  const [error, setErrors] = useState({
    display_name: "",
    location: "",
  });

  const [userProfileData, setUserProfileData] = useState({
    cognito_uuid: "",
    display_name: "",
    gender_identity: "",
    sexual_orientation: "",
    about_me: "",
    userProfileLocationId: "",
    marital_status: "",
    location: "",
    avatarImage: "",
    imagesList: [],
  });

  const focusUploadImages = useRef();
  const focusAvatarImage = useRef();

  const { setImagesData } = useContext(ImagesDataContext);

  function handleChangeProfileData(name, value) {
    setProfileSettingsValues((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function handleErrors(name, value) {
    setErrors((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  async function getProfileData() {
    setLoading(true);

    const uuid = await Auth.currentUserInfo();

    try {
      const response = await API.graphql({
        query: getUserProfile,
        variables: {
          cognito_uuid: uuid.attributes.sub,
        },
      });
      setUserProfileData({
        cognito_uuid: response.data.getUserProfile.cognito_uuid,
        display_name: response.data.getUserProfile.display_name,
        gender_identity: response.data.getUserProfile.gender_identity,
        sexual_orientation: response.data.getUserProfile.sexual_orientation,
        about_me: response.data.getUserProfile.about_me,
        marital_status: response.data.getUserProfile.marital_status,
        age: response.data.getUserProfile.age,
        location: response.data.getUserProfile.location,
        favorites: response.data.getUserProfile.favorites,
        avatarImage: response.data.getUserProfile.avatarImage,
        imagesList: response.data.getUserProfile.imagesList,
      });

      let arrayImages = [];

      response.data.getUserProfile.imagesList !== null &&
        response.data.getUserProfile.imagesList.length > 0 &&
        (await Promise.all(
          response.data.getUserProfile.imagesList.map(async (data) => {
            const imageUrl = await Storage.get(imgUrl.filename(data), {
              identityId: imgUrl.id(data),
              level: "protected",
            });

            arrayImages.push({ name: data, url: imageUrl });
          })
        ));
      setImagesLists(arrayImages && arrayImages);

      const avatarPack = imgUrl.decode(
        response.data.getUserProfile.avatarImage
      );
      const imageAvatarUrl = await Storage.get(avatarPack.filename, {
        identityId: avatarPack.id,
        level: "protected",
      });

      setAvatarImage(
        response.data.getUserProfile.avatarImage !== "" &&
          response.data.getUserProfile.avatarImage
          ? imageAvatarUrl
          : ""
      );
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  function handleScrollUp() {
    window.scrollTo({ top: 0, left: 0 });
  }

  useEffect(() => {
    getProfileData();
    handleScrollUp();
  }, []);

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    if (profileSettingsValues.display_name === "") {
      setProfileSettingsValues(userProfileData);
    }
  }, [loading]);

  async function handleUpdateProfile() {
    const uuid = await Auth.currentUserInfo();

    setErrors({ display_name: "", userProfileLocationId: "" });

    await yupSchema.profileName
      .validate(profileSettingsValues.display_name)
      .catch((error) => handleErrors("display_name", error.message));

    const isValidDisplayName = await yupSchema.profileName.isValid(
      profileSettingsValues.display_name
    );

    await yupSchema.location
      .validate(profileSettingsValues.location)
      .catch((error) => handleErrors("location", error.message));

    const isValidLocation = await yupSchema.location.isValid(
      profileSettingsValues.location
    );

    if (isValidDisplayName && isValidLocation) {
      try {
        const response = await API.graphql({
          query: updateUserProfile,
          variables: {
            input: {
              cognito_uuid: uuid.attributes.sub,
              display_name: profileSettingsValues.display_name,
              gender_identity: profileSettingsValues.gender_identity,
              sexual_orientation: profileSettingsValues.sexual_orientation,
              about_me: profileSettingsValues.about_me,
              marital_status: profileSettingsValues.marital_status,
              location: profileSettingsValues.location,
            },
          },
        });
        if (response) {
          refreshPage();
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      return;
    }
  }

  const handleUploadImagesList = async () => {
    return focusUploadImages?.current?.click();
  };

  const onUploadImagesList = async (event) => {
    event.persist();
    const compress = new Compress({
      targetSize: 0.2,
      quality: 0.75,
      maxWidth: 1024,
      maxHeight: 1024,
    });

    const identityId = await Auth.currentUserInfo();
    const fileData = event.target.files[0];

    compress.compress([fileData]).then(async (conversions) => {
      const { photo, info } = conversions[0];

      console.log({ photo, info });

      try {
        const uniqName = uuid();

        await Storage.put(uniqName + fileData.name, photo.data, {
          identityId: identityId.id,
          contentType: fileData.type,
          level: "protected",
        });

        const imagepack = imgUrl.encode(
          uniqName + fileData.name,
          identityId.id
        );
        const updatedImagesList =
          userProfileData.imagesList !== null
            ? [...userProfileData.imagesList, imagepack]
            : [imagepack];

        await API.graphql({
          query: updateUserProfile,
          variables: {
            input: {
              cognito_uuid: identityId.attributes.sub,
              imagesList: updatedImagesList,
            },
          },
        });

        getProfileData();
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    });
  };

  async function handleProfileAvatar(imageName) {
    const identityId = await Auth.currentUserInfo();

    try {
      await API.graphql({
        query: updateUserProfile,
        variables: {
          input: {
            cognito_uuid: identityId.attributes.sub,
            avatarImage: imageName,
          },
        },
      });

      getProfileData();
    } catch (error) {
      console.log("Error uploading file: ", error);
    }
  }

  async function handleDeleteImage(imageId, imageName) {
    setImagesData({
      userAvatarImageName:
        imageId === userProfileData.avatarImage
          ? ""
          : userProfileData.avatarImage,
      imageId: imageId,
      imageName: imageName,
      imagesList: userProfileData.imagesList,
    });
    navigate("/slett-bilde");
  }

  return (
    <>
      <View
        height="34px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        width="100%"
        style={{ zIndex: 10 }}
      />
      <View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
        <View
          height="82px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <div
            onClick={() => navigate("/min-profil")}
            style={{ position: "absolute", left: "16px", top: "32px" }}
          >
            <img src={Icons.arrowBackBig} alt="Tilbake" />
          </div>

          <Text
            textAlign={"center"}
            color={"#29666A"}
            fontSize="16px"
            style={{ position: "absolute", top: "50%" }}
          >
            Profilinnstillinger
          </Text>

          <div
            onClick={() => navigate("/forhandsvis-profil")}
            style={{ position: "absolute", right: "24px", top: "50%" }}
          >
            <img src={Icons.eye} alt="eye" />
          </div>
        </View>
      </View>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Layout>
          <Flex paddingTop={106} direction={"column"} alignItems={"center"}>
            <Text
              fontSize={"16px"}
              fontWeight="400"
              textAlign={"center"}
              width="100%"
              color="#6B9A9E"
            >
              Profilbilder
            </Text>

            <input
              onChange={(e) => {}}
              type={"file"}
              style={{ display: "none" }}
              ref={focusAvatarImage}
            />

            <UserAvatar
              userImage={avatarImage}
              gender={userProfileData.gender_identity}
            />

            <View
              width="100%"
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {!loading && userProfileData.display_name ? (
                <Text fontSize={"24px"} color="#4D6F6D" fontWeight={"400"}>
                  {userProfileData.display_name},{" "}
                  {!loading && userProfileData.age}
                </Text>
              ) : (
                !loading && <Text>Helen, 39</Text>
              )}

              <div style={{ marginTop: "27px" }}>
                <img src={Icons.info} alt="info" />
              </div>

              <Text
                fontSize={"14px"}
                fontWeight="400"
                color="#5D7679"
                marginTop={"7px"}
              >
                Du kan laste opp maksimalt 10 bilder{" "}
              </Text>
            </View>
            <View width="100%">
              <Flex
                direction={"row"}
                wrap="wrap"
                gap={window.screen.width > 380 ? "17px" : "8px"}
              >
                {imagesLists.length > 0 &&
                  imagesLists
                    .sort((a, b) => a._lastChangedAt + b._lastChangedAt)
                    .map((data, index) => {
                      let isAvatar =
                        data.name === userProfileData.avatarImage
                          ? true
                          : false;

                      return (
                        <View key={index}>
                          <GalleryPicture
                            avatarImage={isAvatar}
                            onSetProfileImage={() =>
                              handleProfileAvatar(data.name)
                            }
                            pictureData={data.url}
                            onDeleteImage={() =>
                              handleDeleteImage(data.name, data.url)
                            }
                          />
                        </View>
                      );
                    })}
                <input
                  onChange={(e) => onUploadImagesList(e)}
                  type={"file"}
                  style={{ display: "none" }}
                  ref={focusUploadImages}
                />
                {imagesLists.length < 10 && (
                  <GalleryEmptyPicture
                    onUploadPicture={handleUploadImagesList}
                  />
                )}
              </Flex>
            </View>
            <View
              width="100%"
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                fontSize={"16px"}
                textAlign="center"
                marginTop={"56px"}
                color="#6B9A9E"
              >
                Profilinformasjon
              </Text>

              <div style={{ marginTop: "27px" }}>
                <img src={Icons.info} alt="info" />
              </div>

              <Text
                textAlign={"center"}
                color="#5D7679"
                fontSize={"14px"}
                marginTop="7px"
              >
                Her legger du inn informasjon som andre medlemmer kan se på
                ditt profilkort. Du må minimum legge inn et profilnavn og hvilket
                fylke du bor i, resten er valgfritt. 
              </Text>
            </View>

            <View width="100%" marginTop="32px">
              <TextField
                fontSize={"20px"}
                errorMessage={error.display_name}
                style={
                  error.display_name
                    ? { borderBottom: "1px solid #FF6B6B" }
                    : ""
                }
                height={"70px"}
                width={"100%"}
                variation="quiet"
                label={
                  <Text>
                    Profilnavn <span style={{ color: "#F6B6C4" }}>*</span>
                  </Text>
                }
                placeholder={"Skriv inn ønsket profilnavn / visningsnavn"}
                color={
                  !loading && profileSettingsValues.display_name
                    ? "#29666A"
                    : "#53A0A6"
                }
                onChange={(e) =>
                  handleChangeProfileData("display_name", e.target.value)
                }
                value={
                  profileSettingsValues.display_name
                    ? profileSettingsValues.display_name
                    : ""
                }
              />
            </View>

            <Spacer height={5} />

            <View
              onClick={() => navigate("/lokasjonsinnstillinger")}
              width="100%"
            >
              <SelectFieldButton
                error={error.location}
                label={"Bosted"}
                secondLabel="*"
                placeholder={"Velg din lokasjon"}
                value={
                  profileSettingsValues.location
                    ? profileSettingsValues.location
                    : ""
                }
              />
            </View>

            <Spacer height={32} />

            <View
              onClick={() => navigate("/kjonnsidentitetsinnstillinger")}
              width="100%"
            >
              <SelectFieldButton
                label={"Kjønnsidentitet"}
                placeholder={
                  profileSettingsValues.gender_identity
                    ? profileSettingsValues.gender_identity
                    : "Velg din kjønnsidentitet"
                }
                value={
                  profileSettingsValues.gender_identity
                    ? profileSettingsValues.gender_identity
                    : ""
                }
              />
            </View>

            <View
              onClick={() => navigate("/seksuellorienteringsinnstillinger")}
              width="100%"
            >
              <SelectFieldButton
                label={"Seksuell orientering"}
                placeholder={
                  profileSettingsValues.sexual_orientation
                    ? profileSettingsValues.sexual_orientation
                    : "Velg din seksuelle orientering"
                }
                value={
                  profileSettingsValues.sexual_orientation
                    ? profileSettingsValues.sexual_orientation
                    : ""
                }
              />
            </View>

            <View
              onClick={() => navigate("/sivilstatusinnstillinger")}
              width="100%"
            >
              <SelectFieldButton
                label={"Samlivsform"}
                placeholder={
                  profileSettingsValues.marital_status
                    ? profileSettingsValues.marital_status
                    : "Velg samlivsform"
                }
                value={
                  profileSettingsValues.marital_status
                    ? profileSettingsValues.marital_status
                    : ""
                }
              />
            </View>

            <View width="100%" marginTop="20px">
              <TextAreaField
                fontSize={"20px"}
                label="En kort beskrivelse av deg selv"
                value={
                  profileSettingsValues.about_me
                    ? profileSettingsValues.about_me
                    : ""
                }
                onChange={(e) =>
                  handleChangeProfileData("about_me", e.target.value)
                }
                border="1px solid #A2C7CB"
                style={{ color: "#29666A" }}
                color="#29666A"
                width={"100%"}
                placeholder="Skriv en kort tekst om deg selv så andre medlemmer lettere kan få et inntrykk av hvem du er og hva du leter etter."
              />
            </View>

            <Text
              color="#5B8386"
              fontSize={"14px"}
              lineHeight="21px"
              fontWeight={"400px"}
              textAlign={"center"}
            >
              Alle felter merket med <span style={{ color: "#A82E30" }}>*</span>{" "}
              er obligatoriske.
            </Text>

            {error.display_name || error.location ? (
              <View
                marginTop="24px"
                height="66px"
                width="100%"
                backgroundColor="#FCE9E9"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Text
                  color={"#9B1717"}
                  fontSize="14px"
                  textAlign={"center"}
                  padding="12px 16px 12px 16px"
                >
                  Vennligst fyll i alle obligatoriske felter for å fortsette.
                </Text>
              </View>
            ) : (
              <View />
            )}

            <Spacer height={33} />

            <PrimaryButton
              onClick={() => handleUpdateProfile()}
              overrides={{
                label: {
                  children: "Oppdater profilen din",
                },
              }}
            />

            <Spacer height={88} />
            <BottomNavigationBar buttonState="active" type={1} />
          </Flex>
        </Layout>
      )}
    </>
  );
}
