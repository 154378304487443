/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, View } from "@aws-amplify/ui-react";
import AppIconSimple from "./AppIconSimple";
export default function AppIcon(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="80px"
      height="80px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "AppIcon")}
      {...rest}
    >
      <View
        padding="0px 0px 0px 0px"
        width="80px"
        height="80px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0px"
        left="0px"
        {...getOverrideProps(overrides, "Group 59")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="80px"
          height="80px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          {...getOverrideProps(overrides, "Group 60")}
        >
          <Icon
            width="80px"
            height="80px"
            viewBox={{ minX: 0, minY: 0, width: 80, height: 80 }}
            paths={[
              {
                d: "M64.2953 0L15.7047 0C7.03125 0 0 7.03125 0 15.7047L0 64.2953C0 72.9688 7.03125 80 15.7047 80L64.2953 80C72.9688 80 80 72.9688 80 64.2953L80 15.7047C80 7.03125 72.9688 0 64.2953 0Z",
                fill: "rgba(211,220,221,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0px"
            bottom="0px"
            left="0px"
            right="0px"
            {...getOverrideProps(overrides, "Vector")}
          ></Icon>
          <AppIconSimple
            width="46px"
            height="46px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            position="absolute"
            top="calc(50% - 23px - 0px)"
            left="calc(50% - 23px - 0px)"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "AppIconSimple")}
          ></AppIconSimple>
        </View>
      </View>
    </View>
  );
}
