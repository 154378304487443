import React, { useState } from "react";
import { SecondaryButton } from "../../ui-components";
import { View, Heading, Flex, Text, Link } from "@aws-amplify/ui-react";
import { Auth, API } from "aws-amplify";
import { Icons } from "../../assets/icons";

export default function BankIDFailed() {
  const [loading, setLoading] = useState(false);

  async function getData() {
    if (loading) {
      return;
    }
    setLoading(true);

    const apiName = "verification";
    const path = "/authenticate";
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    try {
      const response = await API.get(apiName, path, myInit);
      console.log(response);
      console.log(response.redirect_url);
      window.location.href = response.redirect_url;
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  }

  return (
    <View padding="30px 16px 10px">
      <Flex
        direction="column"
        textAlign="center"
        marginTop="4rem"
        marginBottom="large"
        alignItems="center"
      >
        <img src={Icons.errorFailed} alt="failed" />
        <Heading
          fontSize="20px"
          fontWeight="500"
          color="#4D6F6D"
          marginBottom="medium"
        >
          Vi klarte ikke å bekrefte kontoen din med BankID
        </Heading>
        <Flex
          direction="column"
          textAlign="center"
          marginBottom="4rem"
          alignItems="center"
          gap="0.5rem"
        >
          <img src={Icons.info} alt="info" />
          <Flex direction="column" gap="0">
            <Text color="#899D9F" fontSize={"14px"} fontWeight="400">
              Identifiseringen gikk dessverre ikke igjennom. Vennligst prøv
              igjen. Om det fortsatt ikke fungerer, ta kontakt med oss på
            </Text>
            <Text as="span" fontWeight="500" color="#4E888D">
              <Link
                onClick={() =>
                  (window.location = "mailto:support@medforeldre.no")
                }
              >
                <span
                  style={{
                    color: "#4E888D",
                  }}
                >
                  support@medforeldre.no
                </span>
              </Link>{" "}
            </Text>
            <Text marginBottom={"36px"} color="#899D9F">
              så skal vi se om vi kan hjelpe deg.
            </Text>

            <Text fontWeight={"700"} color="#4E888D">
              Trenger du hjelp med kontoverifiseringen din?
            </Text>
          </Flex>
        </Flex>
        <View position="absolute" bottom={"56px"}>
          <SecondaryButton
            onClick={() => getData()}
            overrides={
              loading
                ? {
                    label: {
                      children: "Vent litt...",
                    },
                  }
                : {
                    label: {
                      children: "Prøv igjen",
                    },
                  }
            }
          />
        </View>
      </Flex>
    </View>
  );
}
