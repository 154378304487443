import { useState } from "react";
import { Text, View, TextField } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate } from "react-router-dom";
import { localImages } from "../../images";
import { Icons } from "../../assets/icons";
import { questionsAndAnswers } from "./QuestionsAndAnswers";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function CoParentingJourney() {
  const [isVisibleCategory, setIsVisibleCategory] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [questionAnswersFiltered, setQuestionAnswersFiltered] =
    useState(questionsAndAnswers);

  const navigate = useNavigate();

  function handleChangeCategory() {
    setIsVisibleCategory(!isVisibleCategory);
  }

  function handlefilterQuestionAnswers(value) {
    setInputValue(value);

    const filteredQandA = questionsAndAnswers.filter(
      (data) =>
        data.question &&
        data.question.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setQuestionAnswersFiltered(filteredQandA);
  }

  return (
    <>
      <TopNavigationBar
        header="Medforeldre-reisen"
        isMedforeldrePage
        onArrowBackClick={() => navigate("/medforeldre")}
        noIconBack
      />
      <Layout>
        <View
          paddingTop="104px"
          display="flex"
          direction="row"
          alignItems="center"
          width="100%"
        >
          <View
            height="40px"
            backgroundColor={isVisibleCategory ? "#E0EAEB" : ""}
            display="flex"
            direction="row"
            justifyContent="center"
            alignItems="center"
            borderRadius="4px"
            marginRight="26px"
          >
            <View fontSize="16px" onClick={() => handleChangeCategory()}>
              <Text
                color={isVisibleCategory ? "#48676A" : "#6B9A9E"}
                padding={"10px"}
              >
                Om prosessen
              </Text>
            </View>
          </View>

          <View
            height="40px"
            backgroundColor={!isVisibleCategory ? "#E0EAEB" : ""}
            display="flex"
            direction="row"
            justifyContent="center"
            alignItems="center"
            borderRadius="4px"
          >
            <View fontSize="16px" onClick={() => handleChangeCategory()}>
              <Text
                color={!isVisibleCategory ? "#48676A" : "#6B9A9E"}
                padding={"10px"}
              >
                Ofte stilte spørsmål
              </Text>
            </View>
          </View>
        </View>
        {isVisibleCategory ? (
          <>
            <View marginTop="40px" padding="0px 19px 0px 19px">
              <Text
                fontSize="16px"
                color="#4D6F6D"
                width="100%"
                marginTop="24px"
              >
                Å ta valget om å gå inn i et foreldreliv gjennom
                medforeldreskap, er det største og viktigste livsvalget du kan
                ta. Reisen for å bli medforelder kommer til å ta stor plass i
                livet. Prosessen tar lang tid og skal gjøres grundig.
              </Text>
              <Text
                fontSize="16px"
                color="#4D6F6D"
                width="100%"
                marginTop="24px"
              >
                De som forplikter seg til et medforeldreskap, har brukt lang tid
                på å bli kjent, og har etablert en relasjon som kjennetegnes av
                respekt, raushet og tillit overfor hverandre.
              </Text>
              <Text
                fontSize="16px"
                color="#4D6F6D"
                width="100%"
                marginTop="24px"
              >
                Vi i Medforeldre ønsker å støtte denne prosessen på en faglig,
                profesjonell og omsorgsfull måte. Vi deler med oss hva vi mener
                er viktige steg i prosessen, hva forskning sier om relevante
                områder for et foreldreskap, og tilbyr personlig veiledning for
                akkurat din reise.
              </Text>
              <Text
                fontSize="16px"
                color="#4D6F6D"
                width="100%"
                marginTop="24px"
              >
              For å komme i gang med din reise på en god måte, anbefaler vi at 
              du booker en time med vår Medforeldre-guide. I løpet av en slik 
              samtale snakker vi om hvor du står i din prosess, hva som er 
              viktig og riktig for deg når du skal finne en medforelder, og du 
              får mulighet til å stille spørsmål om både tjenesten og konseptet.
              </Text>

              <View marginTop="16px">
                <img
                  width="100%"
                  src={localImages.medforeldrereisen}
                  alt="landscape"
                />
              </View>

              <Text
                textAlign="center"
                fontSize="20px"
                color="#4D6F6D"
                width="100%"
                marginTop="40px"
                fontWeight="500"
              >
                Bli kjent med deg selv
              </Text>

              <Text
                textAlign="left"
                fontSize="16px"
                color="#4A8A8E"
                width="100%"
                marginTop="16px"
              >
                Det å utforske medforeldrekonseptet innebærer også å utforske 
                seg selv, og for mange kan dette bli en god selvutviklingsprosess. 
                Er konseptet riktig for deg? Hva trenger og ønsker du deg i et 
                medforeldreskap? Hva er eventuelt en god medforeldrematch for deg? 
                For å kunne svare godt og ærlig på disse spørsmålene må du kjenne 
                deg selv; Hvilket verdigrunnlag har du? Hva er dine tydeligste 
                personlighetstrekk? Hvilke holdninger har du til livssyn, livsstil 
                og barneoppdragelse? Vi i Medforeldre hjelper deg gjennom denne 
                prosessen, slik at du får støtte til å utforske spørsmål som vi 
                mener er viktige på Medforeldre-reisen.
              </Text>
              <View marginTop="16px">
                <img
                  width="100%"
                  src={localImages.blikjentmeddegselv}
                  alt="landscape"
                />
              </View>

              <Text
                textAlign="center"
                fontSize="20px"
                color="#4D6F6D"
                width="100%"
                marginTop="40px"
                fontWeight="500"
              >
                Bli kjent med en potensiell medforelder
              </Text>

              <Text
                textAlign="left"
                fontSize="16px"
                color="#4D6F6D"
                width="100%"
                marginTop="16px"
              >
                Å bli kjent med en potensiell medforelder handler om å finne 
                ut om man er en god match eller ikke. Har man sammen et godt 
                utgangspunkt for å bygge en tillitsfull og trygg relasjon? 
                Tenker man likt nok rundt de temaene som er viktige for begge 
                parter? Har man personlighetstrekk som legger til rette for 
                gode samarbeidsevner? Hvilke områder matcher man eventuelt 
                ikke på, og hvor kritisk er det egentlig? Mange av oss har 
                erfaring med å vurdere hva man ønsker seg fra en romantisk 
                partner, men når man skal finne en medforelder er det gjerne 
                litt andre kriterier man velger ut i fra. Som potensielle 
                medforeldre er det mange viktige temaer man bør snakke igjennom. 
                Vi hjelper dere i denne prosessen, slik at dere kan legge et 
                godt grunnlag for å bli kjent og finne ut om dere har potensial 
                for å etablere et godt foreldreskap.
              </Text>

              <View marginTop="16px">
                <img
                  width="100%"
                  src={localImages.blikjentmedmedforelder}
                  alt="freedom-human"
                />
              </View>

              <Text
                textAlign="center"
                fontSize="20px"
                color="#4D6F6D"
                width="100%"
                marginTop="40px"
                fontWeight="500"
              >
                Vår tjeneste og våre verktøy
              </Text>

              <Text
                textAlign="left"
                fontSize="16px"
                color="#4D6F6D"
                width="100%"
                marginTop="16px"
              >
                Personlig matching - Profesjonell rådgivning - Medlemssider
              </Text>
              <Text
                fontSize="16px"
                color="#4D6F6D"
                width="100%"
                marginTop="24px"
              >
                Vårt mål er å veilede deg på en slik måte at du føler deg 
                trygg på å ta det valget som er riktig for deg og ditt liv. 
                Dette tror vi at vi gjør best ved å legge til rette for 
                innsikt og læring, veiledning og rådgivning, og mulighet 
                til å tilpasse prosessen til det som blir riktig for din 
                reise. Vår hovedtjeneste er personlig matching og rådgivning, 
                og dette innebærer at vi som team setter oss godt inn i hvem 
                du er og hva du søker, for så å lete etter gode kandidater 
                til deg. Våre rådgivere kan veilede og støtte deg ut ifra at 
                de har spesialisert seg på barneønske, medforeldreskap, 
                personlighetstesting, foreldresamarbeid, barn- og 
                foreldrerelasjoner, juridiske aspekter ved foreldreskap med mer. 
                Majoriteten av våre medlemmer har et medlemskap for personlig 
                matching. Samtidig har vi gjennom å utvikle disse Medlemssidene 
                åpnet opp for at du kan utforske konseptet litt på egen hånd 
                først, før du eventuelt tar steget videre til personlig matching 
                og rådgivning.
              </Text>

              <View marginTop="16px" marginBottom="88px">
                <img
                  width={"100%"}
                  src={localImages.personligmatching}
                  alt="landscape"
                />
              </View>
            </View>
          </>
        ) : (
          <>
            <View position="relative" marginTop="40px" marginBottom="32px">
              <TextField
                fontSize={"20px"}
                style={{ paddingLeft: "38px" }}
                height={"70px"}
                width={"100%"}
                variation="quiet"
                label={
                  <Text color="#5B8386" fontWeight={"400"}>
                    Søk etter spørsmål og svar (under arbeid)
                  </Text>
                }
                placeholder={"Søk etter spørsmål og svar"}
                color={"#364D4F"}
                onChange={(e) => handlefilterQuestionAnswers(e.target.value)}
                value={inputValue}
              />
              <View position="absolute" bottom={"-2px"}>
                <img src={Icons.searchQuestions} alt="search" />
              </View>
            </View>
            <div style={{ marginBottom: "100px" }}>
              {questionAnswersFiltered.map((data, index) => {
                return (
                  <View
                    key={index}
                    padding="0px 16px 0px 16px"
                    marginBottom="32px"
                  >
                    <Text fontSize={"16px"} fontWeight="400" color="#5C7578">
                      {data.question}
                    </Text>
                  </View>
                );
              })}
            </div>
          </>
        )}
      </Layout>
      <BottomNavigationBar buttonState="active" type={2} />
    </>
  );
}
