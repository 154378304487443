import { localImages } from "../../images";

export const guidsData = [
  {
    id: "6",
    name: "Linn",
    title: "Medforeldre-guide",
    imageUrl: localImages.medforeldreguide,
    email: "linn~medforeldre.no",
    paragraph:
      "Om du lurer på hvor du skal starte og hvordan din medforeldrereise kan se ut, så anbefaler vi at du setter opp en samtale med Linn. Da får dere snakket om hvor du står i din prosess, hva du tror at du har behov for akkurat nå, og du får mulighet til å stille spørsmål om både tjenesten og konseptet. Linn er Norges første Medforeldre-guide og har også personlig erfaring med både barneønske og det å være på medforeldrereisen.",
  },
  {
    id: "2",
    name: "Kristian",
    title: "Familieterapeut",
    imageUrl: localImages.kristian,
    email: "familieterapeut~medforeldre.no",
    paragraph:
      "Trenger du å sortere tankene rundt barneønske og familieliv? Kristian har lang erfaring med å støtte mennesker med barneønske, både par og single. Han har spesielt jobbet mye med menn og barneønske, og har egenerfaring både som ufrivillig barnløs og som pappa. I tillegg til å veilede våre medlemmer, jobber han som klinisk sosionom ved Kvinneklinikken på Rikshospitalet i Oslo.",
  },
  {
    id: "7",
    name: "Andreas",
    title: "Psykolog",
    imageUrl: localImages.andreas,
    email: "psykolog~medforeldre.no",
    paragraph:
      "Når du er i prosess med å bli kjent med en potensiell medforelder, kan det være til stor hjelp å ta en personlighetstest for å sette lys på hvor dere er like og hvor dere er forskjellige. Dette er noe vi anbefaler at dere gjør ganske tidlig i prosessen. Det er også mulig å ta personlighetstest for sin egen del - før man har møtt en potensiell medforelder. Andreas har mange års spesialisering innenfor personlighetstesting og parterapi.",
  },
  {
    id: "3",
    name: "Ida og Stig",
    title: "Psykolog",
    imageUrl: localImages.stigogida,
    email: "psykolog~medforeldre.no",
    paragraph:
      "Når du har funnet den som du tenker kan være din potensielle medforelder, er det på tide å sette opp en samtale med Stig og Ida. Formålet med samtalene er å gå dypere inn i å finne ut hvor dere har like og ulike verdier, holdning, syn på barneoppdragelse m.m. Stig og Ida har spisskompetanse på foreldreskap, foreldre-barn-relasjoner, samt barns sosiale og emosjonelle utvikling.",
  },
  {
    id: "4",
    name: "Silje",
    title: "Advokat",
    imageUrl: localImages.silje,
    email: "advokat~medforeldre.no",
    paragraph:
      "Silje er vår advokat og kan rådgi innenfor familierett og barnerett. Hun er selv medforelder og har særdeles god innsikt i både de juridiske aspektene og hvordan det generelt sett er å være medforelder. Har du funnet din medforelder? Da er det på tide å ta en prat med Silje :)",
  },
  {
    id: "5",
    name: "Under rekruttering",
    title: "Fertilitetsrådgiver",
    imageUrl: localImages.user5,
    email: "fertilitet~medforeldre.no",
    paragraph: "Vi er i gang med rekruttering av en fertilitetsrådgiver!",
  },
  {
    id: "1",
    name: "Morten, Jostein og Ylva",
    title: "Etablerte medforeldre",
    imageUrl: localImages.user2,
    email: "ambassador~medforeldre.no",
    paragraph:
      "Våre medforeldre-ambassadører har selv vært medforeldre i mange år. De kan dele med seg om både de fine og de litt mer utfordrende sidene ved å være medforeldre.",
  },
];
