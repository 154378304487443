import React, { useState, useContext } from "react";
import { View, Text, TextAreaField } from "@aws-amplify/ui-react";
import { SecondaryButton } from "../../ui-components";
import { useNavigate } from "react-router";
import { ProfileDataContext } from "../../contexts/contexts";
import { Icons } from "../../assets/icons";

export function ChooseDescription() {
  const navigate = useNavigate();
  // const [descriptionValue, setDescriptionValue] = useState("");
  const { setProfileSettingsValues, profileSettingsValues } =
    useContext(ProfileDataContext);
  const [currentDescription, setCurrentDescription] = useState(
    profileSettingsValues
  );

  function handleInputValue(value) {
    setCurrentDescription(() => ({
      ...profileSettingsValues,
      about_me: value,
    }));
  }

  function handleApplyDescription() {
    setProfileSettingsValues((prevData) => ({
      ...prevData,
      about_me: currentDescription.about_me,
    }));

    navigate("/lag-profil");
  }

  return (
    <View height="100vh">
      {/* header */}

      <View
        height="32px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        left="0px"
        right="0px"
        width="100%"
      />
      <View position="fixed" top="32px" left="0px" right="0px" width="100%">
        <View
          height="48px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <Text textAlign={"center"} color={"#29666A"} fontSize="16px">
            En kort beskrivelse av deg selv
          </Text>

          <img
            onClick={() => navigate("/lag-profil")}
            style={{ position: "absolute", right: "16px", bottom: "5px" }}
            src={Icons.cancel}
            alt="cancel"
          />
        </View>
      </View>

      <View
        paddingTop="60px"
        display="flex"
        justifyContent="space-between"
        direction="column"
        alignItems="center"
        height={"100vh"}
      >
        <TextAreaField
          value={currentDescription.about_me}
          onChange={(e) => handleInputValue(e.target.value)}
          marginTop={"30px"}
          onClick={() => navigate("/velg-beskrivelse")}
          border="1px solid #A2C7CB"
          style={{ color: "#29666A" }}
          color="#29666A"
          width={"90%"}
          placeholder="Skriv en kort tekst om deg selv så andre medlemmer lettere kan få et inntrykk av hvem du er og hva du leter etter."
        />

        <SecondaryButton
          onClick={() => handleApplyDescription()}
          marginTop={"56px"}
          marginBottom="77px"
          overrides={{
            label: {
              children: "Lagre beskrivelse",
            },
          }}
        />
      </View>
    </View>
  );
}
