/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "@aws-amplify/ui-react/internal";
import { Flex, Text, View } from "@aws-amplify/ui-react";
export default function SwitchFieldNew(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        track: {},
        thumb: {},
        switch: {},
        label: {},
        "\u274C": {},
        SwitchFieldNew: {},
      },
      variantValues: { property1: "EnabledOn" },
    },
    {
      overrides: {
        track: { backgroundColor: "rgba(255,255,255,1)" },
        thumb: { left: "2px", backgroundColor: "rgba(242,246,246,1)" },
        switch: {},
        label: { children: "Off" },
        "\u274C": { children: "Off" },
        SwitchFieldNew: {},
      },
      variantValues: { property1: "EnabledOff" },
    },
    {
      overrides: {
        track: { backgroundColor: "rgba(220,220,220,1)" },
        thumb: { left: "2px", backgroundColor: "rgba(239,240,240,1)" },
        switch: {},
        label: { children: "Off" },
        "\u274C": { children: "Off" },
        SwitchFieldNew: {},
      },
      variantValues: { property1: "DisabledOff" },
    },
    {
      overrides: {
        track: { backgroundColor: "rgba(156,188,191,1)" },
        thumb: {},
        switch: {},
        label: {},
        "\u274C": {},
        SwitchFieldNew: {},
      },
      variantValues: { property1: "DisabledOn" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="8px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      padding="8px 8px 8px 8px"
      display="flex"
      {...getOverrideProps(overrides, "SwitchFieldNew")}
      {...rest}
    >
      <Flex
        padding="0px 0px 0px 0px"
        width="44px"
        height="24px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        shrink="0"
        position="relative"
        {...getOverrideProps(overrides, "switch")}
      >
        <View
          width="44px"
          height="24px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0px"
          left="0px"
          borderRadius="16px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(78,136,141,1)"
          {...getOverrideProps(overrides, "track")}
        ></View>
        <View
          width="20px"
          height="20px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="2px"
          left="22px"
          border="1px SOLID rgba(143,184,188,1)"
          borderRadius="16px"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "thumb")}
        ></View>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="400"
        color="rgba(134,151,152,1)"
        lineHeight="24px"
        textAlign="right"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="On"
        {...getOverrideProps(overrides, "label")}
      ></Text>
      <Text
        fontFamily="Inter"
        fontSize="20px"
        fontWeight="400"
        color="rgba(48,64,80,1)"
        lineHeight="30px"
        textAlign="left"
        display="none"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="On"
        {...getOverrideProps(overrides, "\u274C")}
      ></Text>
    </Flex>
  );
}
