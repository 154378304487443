import { Text, View } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate } from "react-router-dom";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function AboutMedparent() {
  const navigate = useNavigate();
  return (
    <View>
      <TopNavigationBar
        noIconBack
        header={"Om Medforeldre AS"}
        isMedforeldreMenuPages
        onArrowBackClick={() => navigate("/medforeldre")}
      />
      <Layout>
        <View
          paddingTop="144px"
          display="flex"
          direction="column"
          alignItems="center"
        >
          <Text
            textAlign={"center"}
            onClick={() => navigate("/om-oss")}
            fontSize={"20px"}
            color="#4D6F6D"
            fontWeight={"500"}
            marginBottom="24px"
          >
            Om oss
          </Text>

          <View
            width="100%"
            height="1px"
            backgroundColor="#9CBCBF"
            marginBottom="24px"
          />

          <Text
            textAlign={"center"}
            onClick={() => navigate("/vilkar-og-personvernerklaring")}
            fontSize={"20px"}
            color="#4D6F6D"
            fontWeight={"500"}
            marginBottom="24px"
          >
            Brukervilkår og Personvernerklæring
          </Text>

          <View
            width="100%"
            height="1px"
            backgroundColor="#9CBCBF"
            marginBottom="24px"
          />

          <Text
            textAlign={"center"}
            onClick={() => navigate("/kontakt-oss")}
            fontSize={"20px"}
            color="#4D6F6D"
            fontWeight={"500"}
            marginBottom="24px"
          >
            Kontakt oss (hjelp / feil, tilbakemeldinger / forslag)
          </Text>

          <View
            width="100%"
            height="1px"
            backgroundColor="#9CBCBF"
            marginBottom="24px"
          />
        </View>
      </Layout>
      <BottomNavigationBar buttonState="active" type={2} />
    </View>
  );
}
