import React from "react";
import { Icons } from "../../assets/icons";
import { View, Text } from "@aws-amplify/ui-react";

export function FilteredCard({ title, onDeleteFilter }) {
  return (
    <View marginRight="10px" marginBottom="10px">
      <View
        borderRadius="32px"
        backgroundColor="#ffffff"
        display="flex"
        direction="row"
        alignItems="center"
        width="100%"
        height="34px"
      >
        <Text
          padding="10px"
          fontSize={"14px"}
          color="#4D6F6D"
          fontWeight={"500"}
        >
          {title ? title : "Text"}
        </Text>

        <View
          marginRight="10px"
          onClick={() => onDeleteFilter()}
          display="flex"
          alignItems="center"
        >
          <img src={Icons.deleteFilter} alt="delete" />
        </View>
      </View>
    </View>
  );
}
