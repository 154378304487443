import React from "react";
import styled from "styled-components";
import { View, Text, Flex } from "@aws-amplify/ui-react";
import { Icons } from "../assets/icons";

const Container = styled.div`
  background: linear-gradient(#d3dcdd, #81cace);
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default function Splash() {
  return (
    <Container>
      <Flex
        height={"100vh"}
        textAlign="center"
        direction="column"
        gap="0"
        justifyContent="space-between"
        position={"relative"}
        alignItems="center"
      >
        <View marginTop="180px">
          <img alt="logo" src={Icons.logo} />
        </View>

        <div>
          <div className="loadingio-spinner-rolling-zctqyswbwvg2">
            <div className="ldio-d5ovkz6p4dk2">
              <div></div>
            </div>
          </div>
        </div>

        <View
          marginBottom="24px"
          height="122px"
          display="flex"
          direction="column"
          justifyContent="space-between"
        >
          <Text
            fontSize={"20px"}
            color="#ffffff"
            fontWeight={"500"}
            lineHeight="30px"
          >
            M E D L E M S S I D E R
          </Text>
          <View>
            <Text fontSize={"12px"} color="#FFFFFF">
              Opphavsrett © 2022 Medforeldre.
            </Text>
            <Text fontSize={"12px"} color="#FFFFFF">
              Alle rettigheter forbeholdt.
            </Text>
          </View>
        </View>
      </Flex>
    </Container>
  );
}
