import { Text, View } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate } from "react-router-dom";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function ResearchAndExperience() {
  const navigate = useNavigate();
  return (
    <View>
      <TopNavigationBar
        noIconBack
        header={"Forskning og erfaringer"}
        isMedforeldreMenuPages
        onArrowBackClick={() => navigate("/medforeldre")}
      />
      <Layout>
        <Text
          textAlign={"center"}
          paddingTop="112px"
          fontSize={"20px"}
          fontWeight="500"
          color="#6B9A9E"
        >
          Om relevant forskning
        </Text>
          
      <View padding="0px 16px 0px 16px">
        <Text
          textAlign={"left"}
          marginTop="24px"
          fontSize={"16px"}
          fontWeight="400"
          color="#4D6F6D"
          marginBottom={"32px"}
          >
            Det er enn så lenge begrenset med forskning som spesifikt ser 
            på medforeldrefamilier. Likevel kan vi se til tilgrensende 
            områder som er relevante for medforeldreskap. Det er f.eks. 
            forsket en del på barn som vokser opp i to hjem, hva som skal 
            til for at et barn skal ha det bra, hvordan et godt 
            foreldresamarbeid ser ut, og hvordan barn som vokser opp i litt 
            mer utradisjonelle familier klarer seg senere i livet. 
            Disse temaene kan du lese mer om her. 
          </Text>
              
          <View
            width="100%"
            height="1px"
            backgroundColor="#9CBCBF"
            marginBottom="24px"
          />

          <Text
            textAlign={"center"}
            onClick={() => navigate("/elsket-og-trygg")}
            fontSize={"20px"}
            color="#4D6F6D"
            fontWeight={"500"}
            marginBottom="24px"
          >
            Hva får et barn til å føle seg elsket og trygt?
          </Text>

          <View
            width="100%"
            height="1px"
            backgroundColor="#9CBCBF"
            marginBottom="24px"
          />

          <Text
            textAlign={"center"}
            onClick={() => navigate("/gode-foreldreskap")}
            fontSize={"20px"}
            color="#4D6F6D"
            fontWeight={"500"}
            marginBottom="24px"
          >
            Suksessfaktorer i et foreldreskap
          </Text>

          <View
            width="100%"
            height="1px"
            backgroundColor="#9CBCBF"
            marginBottom="24px"
          />

          <Text
            textAlign={"center"}
            onClick={() => navigate("/barn-med-to-hjem")}
            fontSize={"20px"}
            color="#4D6F6D"
            fontWeight={"500"}
            marginBottom="24px"
          >
            Barn med to hjem
          </Text>

          <View
            width="100%"
            height="1px"
            backgroundColor="#9CBCBF"
            marginBottom="24px"
          />

          <Text
            textAlign={"center"}
            onClick={() => navigate("/utradisjonelle-familier")}
            fontSize={"20px"}
            color="#4D6F6D"
            fontWeight={"500"}
            marginBottom="24px"
          >
            Barn i utradisjonelle familier
          </Text>

          <View
            width="100%"
            height="1px"
            backgroundColor="#9CBCBF"
            marginBottom="24px"
          />
        </View>
      </Layout>
      <BottomNavigationBar buttonState="active" type={2} />
    </View>
  );
}
