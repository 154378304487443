import React, { useState, useEffect } from "react";
import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { BrowserRouter } from "react-router-dom";
import { View } from "@aws-amplify/ui-react";
import {
  ImagesDataContext,
  ProfileDataContext,
  FiltersLocationsContext,
  FiltersGenderContext,
  FiltersOrientationContext,
  FiltersCohabitationContext,
  FiltersApplyContext,
} from "./contexts/contexts";
import AllRoutes from "./Routes";
import Contexts from "./contexts";

const profileInitalDataValues = {
  cognito_uuid: "",
  display_name: "",
  gender_identity: "",
  sexual_orientation: "",
  about_me: "",
  userProfileLocationId: "",
  marital_status: "",
};

const initialFiltersLocationState = {
  Agder: false,
  Innlandet: false,
  "Møre og Romsdal": false,
  Nordland: false,
  Oslo: false,
  Rogaland: false,
  "Vestfold og Telemark": false,
  "Troms og Finnmark": false,
  Trøndelag: false,
  Vestland: false,
  Viken: false,
};

const initialFiltersGenderState = {
  Mann: false,
  Kvinne: false,
  "Ikke-binær": false,
};

const initialFiltersOrientation = {
  Heterofil: false,
  Homofil: false,
  Lesbisk: false,
  Bifil: false,
  Annet: false,
};

const initialFiltersCohabitations = {
  Singel: false,
  "Parforhold / romantisk partner": false,
  Gift: false,
  Annet: false,
};

const initialMessageNotification = {
  NewMessage: false,
  Conversations: [],
  PreviousMessages: {},
  UserProfiles: {},
  Loading: true,
  UserData: [],
};

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const [notShowIntro, setNotShowIntro] = useState(false);
  const [imagesData, setImagesData] = useState();
  const [imageUrlProfile, setImageUrlProfile] = useState("");

  const [profileSettingsValues, setProfileSettingsValues] = useState(
    profileInitalDataValues
  );

  const [messageNotification, setMessageNotification] = useState(
    initialMessageNotification
  );

  // set filters
  const [filtersCohabitation, setFiltersCohabitation] = useState(
    initialFiltersCohabitations
  );

  const [filtersOrientation, setFiltersOrientation] = useState(
    initialFiltersOrientation
  );

  const [filtersLocationData, setFiltersLocationData] = useState(
    initialFiltersLocationState
  );
  const [genderState, setGenderState] = useState(initialFiltersGenderState);

  // apply filters
  const [applyFiltersGender, setApplyFiltersGender] = useState([]);
  const [applyFiltersLocations, setApplyFiltersLocations] = useState([]);
  const [applyFiltersOrientation, setApplyFiltersOrientation] = useState([]);
  const [applyFiltersCohabitation, setApplyFiltersCohabitation] = useState([]);

  // send apply filters to Context
  const applyFiltersData = {
    applyFiltersGender,
    setApplyFiltersGender,
    applyFiltersLocations,
    setApplyFiltersLocations,
    applyFiltersOrientation,
    setApplyFiltersOrientation,
    applyFiltersCohabitation,
    setApplyFiltersCohabitation,
  };

  // send filters to Context
  const filtersCohabitationValuesContext = {
    filtersCohabitation,
    setFiltersCohabitation,
  };

  const filtersOrientationValuesContext = {
    filtersOrientation,
    setFiltersOrientation,
  };

  const filtersGenderValuesContext = {
    genderState,
    setGenderState,
  };

  const filtersLocationValuesContext = {
    filtersLocationData,
    setFiltersLocationData,
  };

  const imagesDataContext = {
    imagesData,
    setImagesData,
    imageUrlProfile,
    setImageUrlProfile,
  };

  const profileSettingsValuesContext = {
    profileSettingsValues,
    setProfileSettingsValues,
  };

  const messageNotificationContext = {
    messageNotification,
    setMessageNotification,
  };

  useEffect(() => {
    setTimeout(() => {
      setShowSplash(false);
    }, 1500);
  }, []);

  useEffect(() => {
    const value = JSON.parse(localStorage.getItem("dontShowAgain"));

    if (value) {
      setNotShowIntro(value);
    }
  }, []);

  return (
    <View>
      <BrowserRouter>
        <FiltersApplyContext.Provider value={applyFiltersData}>
          <FiltersCohabitationContext.Provider
            value={filtersCohabitationValuesContext}
          >
            <FiltersOrientationContext.Provider
              value={filtersOrientationValuesContext}
            >
              <FiltersGenderContext.Provider value={filtersGenderValuesContext}>
                <FiltersLocationsContext.Provider
                  value={filtersLocationValuesContext}
                >
                  <ImagesDataContext.Provider value={imagesDataContext}>
                    <ProfileDataContext.Provider
                      value={profileSettingsValuesContext}
                    >
                      <Contexts value={messageNotificationContext}>
                        <AllRoutes
                          notShowIntro={notShowIntro}
                          showSplash={showSplash}
                        />
                      </Contexts>
                    </ProfileDataContext.Provider>
                  </ImagesDataContext.Provider>
                </FiltersLocationsContext.Provider>
              </FiltersGenderContext.Provider>
            </FiltersOrientationContext.Provider>
          </FiltersCohabitationContext.Provider>
        </FiltersApplyContext.Provider>
      </BrowserRouter>
    </View>
  );
}

export default App;
