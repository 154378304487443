import React from "react";
import { View } from "@aws-amplify/ui-react";
import { Icons } from "../../assets/icons";

export function GalleryEmptyPicture({ onUploadPicture }) {
  return (
    <View
      as="div"
      width="108px"
      height="108px"
      backgroundColor="#FFFFFF"
      justifyContent="center"
      alignItems="center"
      direction="column"
      borderRadius="16px"
      display="flex"
      onClick={() => onUploadPicture()}
    >
      <img src={Icons.cameraIcon} alt="upload-button" />
    </View>
  );
}
