import React, { useEffect, useState } from "react";
import { Text, View } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate } from "react-router-dom";
import { localImages } from "../../images";
import { Icons } from "../../assets/icons";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function CoParenting() {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0);

  const handleNavigation = (e) => {
    setScroll(e.currentTarget.scrollY);
  };

  useEffect(() => {
    setScroll(window.scrollY);

    window.addEventListener("scroll", (e) => handleNavigation(e));
  }, []);

  function handleScrollUp() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  return (
    <View>
      <TopNavigationBar
        noIconBack
        header={"Forskning og erfaringer"}
        isMedforeldreMenuPages
        onArrowBackClick={() => navigate("/forskning-og-erfaringer")}
      />
      <Layout>
        <Text
          textAlign={"center"}
          paddingTop="112px"
          fontSize={"20px"}
          fontWeight="500"
          color="#4D6F6D"
        >
          Medforeldreskap
        </Text>

        <View padding="0px 16px 0px 16px">
          <Text
            textAlign={"left"}
            marginTop="24px"
            fontSize={"16px"}
            fontWeight="400"
            color="#4D6F6D"
            marginBottom={"32px"}
          >
            Medforeldreskap er et lurt valg.
          </Text>
        </View>

        <View
          width="100%"
          height="1px"
          backgroundColor="#9CBCBF"
          marginBottom="32px"
        />

        <Text
          textAlign={"center"}
          color="#869798"
          fontSize={"14px"}
          marginBottom="24px"
        >
          Forskningsartikler
        </Text>

        <View marginBottom="32px">
          <Text
            textAlign={"center"}
            color="#4E888D"
            fontSize={"14px"}
            fontWeight="700"
            marginBottom={"22px"}
          >
            Sample link title here
          </Text>

          <Text
            textAlign={"center"}
            color="#4E888D"
            fontSize={"14px"}
            fontWeight="700"
            marginBottom={"22px"}
          >
            Sample link title here
          </Text>

          <Text
            textAlign={"center"}
            color="#4E888D"
            fontSize={"14px"}
            fontWeight="700"
            marginBottom={"22px"}
          >
            Sample link title here
          </Text>
        </View>
        <View
          width="100%"
          height="1px"
          backgroundColor="#9CBCBF"
          marginBottom="32px"
        />

        <Text color="#869798" fontSize={"14px"} textAlign="center">
          Våre ambassadørers mening / erfaring på dette området
        </Text>

        <View
          display="flex"
          direction="column"
          alignItems="center"
          marginBottom="93px"
        >
          <View width="46px" height="46px" borderRadius="23px" marginTop="24px">
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "23px",
                objectFit: "fill",
              }}
              src={localImages.userFeedback}
              alt="user-avatar"
            />
          </View>

          <Text
            fontSize={"16px"}
            color="#4D6F6D"
            lineHeight={"24px"}
            marginBottom="4px"
          >
            Amelia
          </Text>

          <Text fontSize={"12px"} color="#5D7679" textAlign={"center"}>
            “Vel nec non aenean malesuada proin vestibulum molestie dictum
            cursus. Sit volutpat fermentum enim elit elementum ipsum. Ut
            pharetra vitae erat ut. Dolor sed orci pretium eget justo. Vitae sed
            tristique lacinia gravida sodales sapien. Quis urna vitae et tortor
            sagittis consequat placerat dictum id. Urna ac vitae nulla vel
            nulla.”
          </Text>

          <View width="46px" height="46px" borderRadius="23px" marginTop="24px">
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "23px",
                objectFit: "fill",
              }}
              src={localImages.userFeedback}
              alt="user-avatar"
            />
          </View>

          <Text
            fontSize={"16px"}
            color="#4D6F6D"
            lineHeight={"24px"}
            marginBottom="4px"
          >
            Vetle
          </Text>

          <Text fontSize={"12px"} color="#5D7679" textAlign={"center"}>
            “Ac duis amet magna velit volutpat condimentum mi amet id. In
            lobortis mauris amet sed at. Libero eu dictum eget faucibus dolor
            enim.”
          </Text>

          <View width="46px" height="46px" borderRadius="23px" marginTop="24px">
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "23px",
                objectFit: "fill",
              }}
              src={localImages.userFeedback}
              alt="user-avatar"
            />
          </View>

          <Text
            fontSize={"16px"}
            color="#4D6F6D"
            lineHeight={"24px"}
            marginBottom="4px"
          >
            Jens
          </Text>

          <Text fontSize={"12px"} color="#5D7679" textAlign={"center"}>
            “Leo vestibulum amet et ultrices viverra. Aenean aliquet habitant
            pulvinar suspendisse amet laoreet imperdiet pellentesque. Neque eros
            aliquet lectus vitae porta in vestibulum vulputate nunc. Ornare
            malesuada fermentum at tincidunt sit eu eget nisl sagittis. Ultrices
            et ut nunc nunc.”
          </Text>

          <View width="46px" height="46px" borderRadius="23px" marginTop="24px">
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "23px",
                objectFit: "fill",
              }}
              src={localImages.userFeedback}
              alt="user-avatar"
            />
          </View>

          <Text
            fontSize={"16px"}
            color="#4D6F6D"
            lineHeight={"24px"}
            marginBottom="4px"
          >
            Alex
          </Text>

          <Text fontSize={"12px"} color="#5D7679" textAlign={"center"}>
            “Leo vestibulum amet et ultrices viverra. Aenean aliquet habitant
            pulvinar suspendisse amet laoreet imperdiet pellentesque. Neque eros
            aliquet lectus vitae porta in vestibulum vulputate nunc. Ornare
            malesuada fermentum at tincidunt sit eu eget nisl sagittis. Ultrices
            et ut nunc nunc.”
          </Text>

          <View width="46px" height="46px" borderRadius="23px" marginTop="24px">
            <img
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "23px",
                objectFit: "fill",
              }}
              src={localImages.userFeedback}
              alt="user-avatar"
            />
          </View>

          <Text
            fontSize={"16px"}
            color="#4D6F6D"
            lineHeight={"24px"}
            marginBottom="4px"
          >
            Vetle
          </Text>

          <Text fontSize={"12px"} color="#5D7679" textAlign={"center"}>
            “Leo vestibulum amet et ultrices viverra. Aenean aliquet habitant
            pulvinar suspendisse amet laoreet imperdiet pellentesque. Neque eros
            ”
          </Text>
          {scroll > 400 && (
            <View
              onClick={() => handleScrollUp()}
              width="40px"
              height="40px"
              backgroundColor="#E0EAEB"
              borderRadius="8px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              position="fixed"
              bottom={"80px"}
              right={"16px"}
            >
              <img src={Icons.arrowUp} alt="arrow-up" />
            </View>
          )}
        </View>
      </Layout>
      <BottomNavigationBar buttonState="active" type={2} />
    </View>
  );
}
