import React, { useEffect, useState, useRef, useContext } from 'react';
import { Text, Flex, View } from '@aws-amplify/ui-react';
import { UserAvatar } from '../../components/UI/UserAvatar';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { createMessage } from '../../graphql/mutations';
import { ConversationsContext } from '../../contexts/conversations';
import styled from 'styled-components';
import { Icons } from '../../assets/icons';
import moment from 'moment';
import 'moment/locale/nb';

export function Messages({ conversation }) {
	const { updateConversations } = useContext(ConversationsContext);

	const [messageText, setMessageText] = useState('');
	const [onFocus, setOnFocus] = useState(false);
	const [onBlur, setOnBlur] = useState(false);
	const viewRef = useRef(null);
	const textareaRef = useRef(null);
	const messageAreaRef = useRef(null);
	const navigate = useNavigate();

	const messages = conversation.Messages.items;
	const you = conversation.you;
	const partner = conversation.partner;

	moment.locale('nb');

	function autoGrow() {
		textareaRef.current.style.height = '5px';
		textareaRef.current.style.height =
			Math.min(textareaRef.current.scrollHeight + 15, 300) + 'px';
		messageAreaRef.current.style.padding = `80px 16px ${Math.min(
			textareaRef.current.scrollHeight + 30,
			300,
		)}px 16px`;
		// viewRef.current.scrollIntoView();
	}

	// scroll Top or Bottom
	useEffect(() => {
		setTimeout(() => {
			messages?.length <= 3
				? window.scrollTo({ top: 0, left: 0 })
				: viewRef.current.scrollIntoView();
		});
	}, [messages?.length]);

	useEffect(() => {
		if (messageText === '' && textareaRef !== null) {
			autoGrow();
		}
	}, [messageText]);

	async function handleSubmit() {
		setOnFocus(!onFocus);

		const input = {
			conversationID: conversation.id,
			from_username: you.cognito_uuid,
			message_text: messageText,
			to_username: partner.cognito_uuid,
			participants_usernames: [you.cognito_uuid, partner.cognito_uuid],
			isRead: 'false',
		};

		try {
			setMessageText('');
			await API.graphql({
				query: createMessage,
				variables: {
					input: input,
				},
			});
			updateConversations();
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<View backgroundColor="#FDFCFC" height="100vh">
			<View
				height="34px"
				backgroundColor=""
				position="fixed"
				top="0px"
				width="100%"
				style={{ zIndex: 10 }}
			/>
			<View position="fixed" top="0px" left="0" width="100%" style={{ zIndex: 100 }}>
				<View
					height="82px"
					backgroundColor="#FDFCFC"
					width="100%"
					alignItems="center"
					display="flex"
					justifyContent="center"
					position="relative"
				>
					<View>
						<div
							onClick={() => navigate('/samtaler')}
							style={{ position: 'absolute', left: '16px', top: '32px' }}
						>
							<img alt="Tilbake" src={Icons.arrowBackBig} />
						</div>
					</View>

					<Text
						textAlign={'center'}
						color={'#29666A'}
						fontSize="16px"
						style={{ position: 'absolute', top: '50%' }}
					>
						{partner?.display_name}
					</Text>
				</View>
			</View>

			<Flex
				direction="column"
				width="100%"
				backgroundColor={'#FDFCFC'}
				padding="80px 16px 84px 16px"
				ref={messageAreaRef}
			>
				{messages &&
					messages.map(function (message, i) {
						const dateCreatedMessage = moment(message.createdAt).calendar();
						const timeSincePreviousMessage =
							i === 0
								? 1000
								: (new Date(message.createdAt).getTime() -
										new Date(messages[i - 1]?.createdAt).getTime()) /
								  1000 /
								  60;
						const previousMessageIsFromSameUser =
							i !== 0 && messages[i - 1].from_username === message.from_username;
						return (
							message && (
								<View key={message.id} style={{ overflow: 'hidden' }}>
									{timeSincePreviousMessage > 5 && (
										<View textAlign="center">
											<Text color="#AEC4C6">
												{dateCreatedMessage ? dateCreatedMessage : ''}
											</Text>
										</View>
									)}
									<View
										display="flex"
										margin={'0'}
										direction="column"
										alignItems={
											message.from_username === partner.cognito_uuid ? 'flex-start' : 'flex-end'
										}
									>
										{!previousMessageIsFromSameUser && (
											<UserAvatar
												userImage={
													message.from_username === partner.cognito_uuid
														? partner.avatarURL
														: you.avatarURL
												}
												gender={
													message.from_username === partner.cognito_uuid
														? partner.gender_identity || ''
														: you.gender_identify || ''
												}
												membersCard={true}
												onClickImage={() => {
													message.from_username === partner.cognito_uuid &&
														navigate(`/medlem/${partner.cognito_uuid}`);
												}}
											/>
										)}

										<MessageCard
											backgroundColor={
												message.from_username === partner.cognito_uuid ? '#E5F4F5' : '#F6F5F4'
											}
											messageFromOwner={message.from_username === partner.cognito_uuid}
										>
											{message.message_text.split('\n').map((item, index) => (
												<Text fontSize={'14px'} color="#5C7578" key={`${message.id}: ${index}`}>
													{item}
												</Text>
											))}
										</MessageCard>
									</View>
								</View>
							)
						);
					})}
			</Flex>
			<View ref={viewRef} />
			<form
				onSubmit={function (e) {
					e.preventDefault();
					if (messageText !== '') {
						handleSubmit();
					}
				}}
			>
				<InputContainer>
					<InputComponent
						placeholder="Skriv din melding her"
						onFocus={() => {}}
						onBlur={() => setOnBlur(!onBlur)}
						onChange={e => {
							setMessageText(e.target.value);
						}}
						onInput={() => {
							autoGrow();
						}}
						value={messageText}
						ref={textareaRef}
					/>
					<View
						as="button"
						type="submit"
						display="flex"
						alignItems="center"
						marginLeft="16px"
						style={{ border: 'none', background: 'none', cursor: 'pointer' }}
					>
						{messageText !== '' ? (
							<img style={{ width: '40px' }} src={Icons.messageSend} alt="send message" />
						) : (
							<img
								style={{ width: '40px' }}
								src={Icons.sendMessageDisabled}
								alt="send message"
							/>
						)}
					</View>
				</InputContainer>
			</form>
		</View>
	);
}

const InputContainer = styled.div`
	box-shadow: 0px 0px 8px rgba(22, 74, 78, 0.2);
	padding-right: 16px;
	padding-left: 16px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 0px;
	left: 0px;
	background-color: #f6f5f4;
`;

const InputComponent = styled.textarea`
	box-shadow: 0px 0px 8px rgba(22, 74, 78, 0.2);
	padding-right: 16px;
	padding-left: 16px;
	margin: 8px 0;
	width: 100%;
	height: 41px;
	background-color: #ffffff;
	border: none;
	border-radius: 8px;
	&:focus {
		outline: none !important;
		border: 1px solid #a2c7cb;
	}
`;

const MessageCard = styled.div`
	margin-top: 5px;
	background-color: ${({ backgroundColor }) => backgroundColor && backgroundColor};
	padding: 10px;
	border-bottom-left-radius: 16px;
	border-top-left-radius: ${({ messageFromOwner }) => (!messageFromOwner ? '16px' : '8px')};
	border-top-right-radius: ${({ messageFromOwner }) => (!messageFromOwner ? '8px' : '16px')};
	border-bottom-right-radius: 16px;
`;
