import React, { useState } from "react";
import { View, Heading, Flex, Text } from "@aws-amplify/ui-react";
import { Auth, API } from "aws-amplify";
import { SecondaryButton } from "../../ui-components";
import { Spacer } from "../../components/UI/Spacer";
import { Icons } from "../../assets/icons";

export default function BankIDVerification() {
  const [loading, setLoading] = useState(false);

  //To do: use this if we want to check if the user is already bankID verified
  /* async function checkIfBankIDVerified() {
    const uuid = await Auth.currentUserInfo();

    if (uuid) {
      try {
        const response = await API.graphql({
          query: getUserAccount,
          variables: {
            cognito_uuid: uuid.attributes.sub,
          },
        });

        if (!!response.data.getUserAccount) {
          console.log("bankIDVerified");
          return true;
        }
      } catch (err) {
        console.log(err);
      }
    }
    console.log("not bankid verified");
    return false;
  } */

  async function getData() {
    if (loading) {
      return;
    }
    setLoading(true);

    const uuid = await Auth.currentUserInfo();
    console.log("uuid", uuid);

    const apiName = "verification";
    const path = "/authenticate";
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    try {
      const response = await API.get(apiName, path, myInit);
      console.log(response);
      console.log(response.redirect_url);

      window.location.href = response.redirect_url;
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  return (
    <View padding="40px 16px 10px">
      <Heading
        fontWeight="400"
        color="#48676A"
        marginTop="large"
        marginBottom="36px"
        textAlign="center"
        fontSize={"16px"}
      >
        Registrer deg
      </Heading>
      <Heading
        fontWeight="400"
        color="#6B9A9E"
        marginTop="large"
        marginBottom="large"
        textAlign="center"
        fontSize={"16px"}
      >
        Bekreft kontoen din med BankID
      </Heading>
      <Flex
        direction="column"
        textAlign="center"
        marginTop="large"
        marginBottom="large"
        alignItems="center"
        gap="0.5rem"
      >
        <img src={Icons.info} alt="info" />
        <Text
          fontWeight="400"
          color="#5D7679"
          textAlign="center"
          fontSize={"14px"}
        >
          Alle som ønsker å bli medlem hos Medforeldre må identifisere seg med
          BankID. Dette gjøres kun ved første registrering. Neste gang du logger
          inn bruker du bare e-post og passord.
        </Text>
      </Flex>
      <Flex
        direction="column"
        alignItems="center"
        gap="medium"
        marginBottom="large"
      >
        {/* <Profilelarge /> */}
        {/* <BankIDlogo /> */}
        <img src={Icons.bankId} alt="bankId-logo" />
      </Flex>
      <Flex direction="column" alignItems="center">
        <Spacer height={250} />
        <View position="absolute" bottom={"56px"}>
          <SecondaryButton
            onClick={() => getData()}
            overrides={
              loading
                ? {
                    label: {
                      children: "Vent litt...",
                    },
                  }
                : {
                    label: {
                      children: "Registrer deg med BankID",
                    },
                  }
            }
          />
        </View>
      </Flex>
    </View>
  );
}
