import React, { useContext, useState, useEffect } from 'react';
import { PrimaryButton, SecondaryButton } from '../../ui-components';
import { Auth, Storage } from 'aws-amplify';
import { ImagesDataContext } from '../../contexts/contexts';
import { useNavigate } from 'react-router';
import { Flex, View, Text } from '@aws-amplify/ui-react';
import { localImages } from '../../images';
import { API } from 'aws-amplify';
import { updateUserProfile } from '../../graphql/mutations';
import imgUrl from '../../helpers/imageHelper';

function getWindowSize() {
	const { innerWidth, innerHeight } = window;
	return { innerWidth, innerHeight };
}

export function DeleteImage() {
	const navigate = useNavigate();
	const [successDeleted, setSuccessDeleted] = useState(false);
	const { imagesData } = useContext(ImagesDataContext);
	const [windowHeight, setWindowHeight] = useState(getWindowSize().innerHeight);

	useEffect(() => {
		function handleWindowResize() {
			setWindowHeight(getWindowSize().innerHeight);
		}

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	async function handleDeleteImage() {
		const identityId = await Auth.currentUserInfo();

		await Storage.remove(imgUrl.filename(imagesData.imageId), {
			identityId: imgUrl.id(imagesData.imageId),
			level: 'protected',
		})
			.then(async response => {
				setSuccessDeleted(true);

				let imagesListFiltered = imagesData.imagesList.filter(images => {
					return images !== imagesData.imageId;
				});

				await API.graphql({
					query: updateUserProfile,
					variables: {
						input: {
							cognito_uuid: identityId.attributes.sub,
							avatarImage: imagesData.userAvatarImageName,
							imagesList: imagesListFiltered,
						},
					},
				});
			})
			.catch(err => {
				setSuccessDeleted(false);
				console.log(err);
			});
	}
	return (
		<>
			<View padding="44px 16px 0px 16px">
				<View
					display="flex"
					direction="column"
					justifyContent="space-between"
					alignItems={'center'}
					height={windowHeight - 112}
				>
					<Flex
						width={'100%'}
						paddingTop={'104px'}
						display="flex"
						direction="column"
						alignItems={'center'}
						height={'243px'}
					>
						{!successDeleted ? (
							<>
								<View>
									<img src={localImages.errorPage} alt="warning" />
								</View>

								<Text fontSize={'20px'} fontWeight="500" color={'#4D6F6D'}>
									Er du sikker på at du vil slette bildet?
								</Text>

								<Text fontSize={'14px'} fontWeight="400" color={'#5D7679'}>
									Du kan ikke gjøre om på slettingen etterpå.
								</Text>
							</>
						) : (
							<>
								<View>
									<img src={localImages.verifiedLarge} alt="warning" />
								</View>

								<Text fontSize={'20px'} fontWeight="500" color={'#6B9A9E'}>
									Bildet ble slettet!
								</Text>
							</>
						)}
					</Flex>
					<View>
						{!successDeleted && (
							<PrimaryButton
								style={{ marginBottom: '32px' }}
								onClick={() => handleDeleteImage()}
								overrides={{
									label: {
										children: 'Slett bilde',
									},
								}}
							/>
						)}

						<SecondaryButton
							onClick={() => navigate('/profil/innstillinger')}
							overrides={{
								label: {
									children: successDeleted ? 'Gå tilbake' : 'Avbryt',
								},
							}}
						/>
					</View>
				</View>
			</View>
		</>
	);
}
