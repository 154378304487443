import { useEffect, useState } from "react";
import { View, Text } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { Layout } from "../../components/Layout";
import { API, Auth, Storage } from "aws-amplify";
import imgUrl from "../../helpers/imageHelper";
import { getUserProfile } from "../../graphql/queries";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Icons } from "../../assets/icons";
import { Loading as LoadingAnimation } from "../loading";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";
import styled from "styled-components";

export function PreviewProfile() {
  const [userProfileData, setUserProfileData] = useState({
    cognito_uuid: "",
    display_name: "",
    gender_identity: "",
    sexual_orientation: "",
    about_me: "",
    userProfileLocationId: "",
    marital_status: "",
    favorites: [],
    imagesList: [],
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [imagesLists, setImagesLists] = useState([]);

  // initial Images
  const images = ["", "", "", "", "", "", "", "", "", ""];

  const buttonStyle = {
    width: "40px",
    height: "160px",
    background: "none",
    border: "0px",
    display: "flex",
    justifyContent: "center",
  };

  const slideShowProprietes = {
    duration: 5000,
    transitionDuration: 500,
    infinite: false,
    indicators: true,
    arrows: true,
    autoplay: false,

    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <img
          style={{ height: "160px", width: "40px", zIndex: 1 }}
          src={Icons.slideLeft}
          alt="left"
        />
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <img
          style={{ height: "160px", width: "40px", zIndex: 1 }}
          src={Icons.slideRight}
          alt="right"
        />
      </button>
    ),
  };

  async function getProfileData() {
    const uuid = await Auth.currentUserInfo();

    try {
      const response = await API.graphql({
        query: getUserProfile,
        variables: {
          cognito_uuid: uuid.attributes.sub,
        },
      });

      setUserProfileData({
        cognito_uuid: response.data.getUserProfile.cognito_uuid,
        display_name: response.data.getUserProfile.display_name,
        gender_identity: response.data.getUserProfile.gender_identity,
        sexual_orientation: response.data.getUserProfile.sexual_orientation,
        about_me: response.data.getUserProfile.about_me,
        location: response.data.getUserProfile.location,
        marital_status: response.data.getUserProfile.marital_status,
        age: response.data.getUserProfile.age,
        favorites: response.data.getUserProfile.favorites,
        imagesList: response.data.getUserProfile.imagesList,
      });

      let arrayImages = [];

      response.data.getUserProfile.imagesList !== null &&
        response.data.getUserProfile.imagesList.length > 0 &&
        (await Promise.all(
          response.data.getUserProfile.imagesList.map(async (data) => {
            const signedUrl = await Storage.get(imgUrl.filename(data), {
              identityId: imgUrl.id(data),
              level: "protected",
            });

            arrayImages.push({ name: data, url: signedUrl });
          })
        ));
      setImagesLists(arrayImages);
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  function handleScrollUp() {
    window.scrollTo({ top: 0, left: 0 });
  }

  useEffect(() => {
    getProfileData();
    handleScrollUp();
  }, []);

  return (
    <>
      <View
        height="34px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        width="100%"
        style={{ zIndex: 10 }}
      />
      <View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
        <View
          height="82px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <View>
            <div
              onClick={() => navigate("/profil/innstillinger")}
              style={{ position: "absolute", left: "16px", top: "32px" }}
            >
              <img src={Icons.arrowBackBig} alt="Tilbake" />
            </div>
          </View>

          <Text
            textAlign={"center"}
            color={"#29666A"}
            fontSize="16px"
            style={{ position: "absolute", top: "50%" }}
          >
            Forhåndsvis profilen din
          </Text>
        </View>
      </View>

      {loading ? (
        <LoadingAnimation />
      ) : (
        <View height="100vh">
          <View
            paddingTop="60px"
            display="flex"
            direction="column"
            height={"100vh"}
            width={"100vw"}
          >
            {imagesLists.length > 0 && (
              <View marginTop="36px" width="100vw">
                {loading ? (
                  <Slide {...slideShowProprietes}>
                    {images.map((index) => {
                      return (
                        <div
                          style={{
                            height: "293px",
                            margin: "auto",
                            width: "100%",
                          }}
                          key={index}
                        >
                          <div
                            style={{
                              width: "100%",
                              height: "293px",
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                      );
                    })}
                  </Slide>
                ) : (
                  <Slide {...slideShowProprietes}>
                    {imagesLists.map((image, index) => {
                      return (
                        <div
                          style={{
                            height: "293px",
                            margin: "auto",
                            width: "100%",
                          }}
                          key={index}
                        >
                          <Image src={image.url} />
                        </div>
                      );
                    })}
                  </Slide>
                )}
              </View>
            )}
            <Layout>
              <View
                direction="row"
                justifyContent="space-between"
                display="flex"
                marginTop="18px"
                alignItems="center"
              >
                <Text color={"#4D6F6D"} fontSize="24px">
                  {!loading && `${userProfileData.display_name}, `}
                  {!loading && userProfileData.age}
                </Text>

                <div>
                  <img src={Icons.disableMessages} alt="message" />
                </div>
              </View>

              <View
                direction="row"
                justifyContent="flex-start"
                display="flex"
                marginTop="18px"
                alignItems="center"
              >
                <img src={Icons.locationPreviewProfile} alt="location" />

                <Text
                  color="#869798"
                  fontSize={"20px"}
                  fontWeight={"500"}
                  marginLeft="10px"
                >
                  {!loading && userProfileData.location !== ""
                    ? userProfileData.location
                    : ""}
                </Text>
              </View>

              <Text fontSize={"16px"} color="#324846" marginTop={"21px"}>
                Om meg:
              </Text>

              <Text fontSize={"16px"} color="#869798">
                {!loading && userProfileData.about_me
                  ? userProfileData.about_me
                  : ""}
              </Text>

              <Text marginTop={"16px"} color="#324846">
                Kjønnsidentitet:
              </Text>

              <Text fontSize={"16px"} color="#869798">
                {!loading && userProfileData.gender_identity
                  ? userProfileData.gender_identity
                  : ""}
              </Text>

              <Text marginTop={"16px"} color="#324846">
                Seksuelle orientering:
              </Text>

              <Text fontSize={"16px"} color="#869798">
                {!loading && userProfileData.sexual_orientation
                  ? userProfileData.sexual_orientation
                  : ""}
              </Text>

              {/*  */}
              <Text marginTop={"16px"} color="#324846">
                Samlivsform:
              </Text>

              <Text fontSize={"16px"} color="#869798">
                {!loading && userProfileData.marital_status
                  ? userProfileData.marital_status
                  : ""}
              </Text>

              <View
                marginTop="16px"
                width="100%"
                backgroundColor="#C6DFE1"
                height="2px"
              />

              <View
                display="flex"
                direction="row"
                alignItems="center"
                marginTop="24px"
                marginBottom="66px"
              >
                <View
                  borderRadius="18px"
                  border="1px solid #B9B9B9"
                  width="64px"
                  height="64px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <img
                    alt="favorites"
                    src={Icons.favoritesEmpty}
                    style={{ width: "32px", height: "32px" }}
                  />
                </View>

                <Text fontSize={"16px"} color="#B9B9B9" marginLeft={"16px"}>
                  Legg medlem til favoritter
                </Text>
              </View>
              <View marginBottom="98px" />

              <BottomNavigationBar buttonState="active" type={1} />
            </Layout>
          </View>
        </View>
      )}
    </>
  );
}

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
