import React, { useContext } from "react";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import {
  Text,
  View,
  RadioGroupField,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { FiltersCohabitationContext } from "../../contexts/contexts";
import { useNavigate } from "react-router-dom";

export function FilterCohabitation() {
  const navigate = useNavigate();
  const { filtersCohabitation, setFiltersCohabitation } = useContext(
    FiltersCohabitationContext
  );

  function handleFilterData(name, value) {
    setFiltersCohabitation((prevData) => ({
      ...prevData,
      [name]: !value,
    }));
  }

  return (
    <>
      <TopNavigationBar
        noIconBack
        isMembersFilterPage
        onArrowBackClick={() => navigate("/filter-medlemmer")}
        header="Velg samlivsform"
        onFilterClick={() => navigate("/filter-medlemmer")}
      />

      <Layout>
        <View
          paddingTop="104px"
          display="flex"
          justifyContent="center"
          direction="column"
        >
          <RadioGroupField
            marginTop={0}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Singel", filtersCohabitation.Singel)
              }
              value={""}
              width={"100%"}
              checked={filtersCohabitation.Singel}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Singel
            </Text>
            {/* </Radio> */}
          </RadioGroupField>
          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData(
                  "Parforhold / romantisk partner",
                  filtersCohabitation["Parforhold / romantisk partner"]
                )
              }
              value=""
              width={"100%"}
              checked={filtersCohabitation["Parforhold / romantisk partner"]}
              readOnly={true}
            />

            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Parforhold / romantisk partner
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() => handleFilterData("Gift", filtersCohabitation.Gift)}
              value=""
              width={"100%"}
              checked={filtersCohabitation.Gift}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Gift
            </Text>
          </RadioGroupField>
          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Annet", filtersCohabitation.Annet)
              }
              value=""
              width={"100%"}
              checked={filtersCohabitation.Annet}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Annet
            </Text>
          </RadioGroupField>
        </View>
      </Layout>
    </>
  );
}
