import { createContext, useState, useEffect, useCallback } from 'react';
import { Auth, API, Hub, Storage } from 'aws-amplify';
import { listConversations } from '../graphql/queries';
import {
	onCreateMessage,
	onCreateConversation,
	onUpdateConversation,
	onUpdateMessage,
} from '../graphql/subscriptions';
import imgUrl from '../helpers/imageHelper';

console.log("before createcontext()")
export const ConversationsContext = createContext();

export const ConversationsProvider = ({ children }) => {
	console.log("conversations.js; conversationsProvider")
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [conversations, setConversations] = useState({
		newMessages: false,
		isLoading: true,
		conversations: [],
	});
	const [isLoaded, setIsLoaded] = useState(false);
	console.log("conversationsProvider: before getConversations")
	const getConversations = useCallback(async () => {
		try {
			console.log("conversations; getting conversations. Auth.currentUserInfo next")
			const uuid = await Auth.currentUserInfo();
			console.log("uuid: " + uuid)
			if (!uuid) {
				console.log("!uuid")
				setIsAuthenticated(false);
				return null;
			}

			setIsAuthenticated(true);

			let nextToken;
			let listedConversations = [];
			while (nextToken !== null) {
				console.log("before listConversations")
				const conversationsResponse = await API.graphql({
					query: listConversations,
					variables: { limit: 10000, nextToken },
				});
				nextToken = conversationsResponse?.data?.listConversations?.nextToken;
				listedConversations = [
					...listedConversations,
					...conversationsResponse?.data?.listConversations?.items,
				];
			}
			console.log("got conversations. populating list if any conversations")
			if (listedConversations.length !== 0) {
				listedConversations.sort(function (a, b) {
					return new Date(b.updatedAt) - new Date(a.updatedAt);
				});
				const addedPartnerConversations = listedConversations.map(conv => ({
					...conv,
					partner:
						uuid.username === conv.conversationInvited_usernameId
							? conv.initiator_username
							: conv.invited_username,
					you:
						uuid.username === conv.conversationInvited_usernameId
							? conv.invited_username
							: conv.initiator_username,
				}));

				// Map the conversations to include the partner's user details and store it under partner.
				console.log("adding avatars to conversation (fetching images)")
				const addedPartnersAvatarsToConversations = await Promise.all(
					addedPartnerConversations.map(async conv => {
						if (!conv.partner?.avatarImage) return { ...conv, partner: { ...conv.partner } };
						return {
							...conv,
							partner: {
								...conv.partner,
								avatarURL: conv.partner?.avatarImage
									? await Storage.get(imgUrl.filename(conv.partner.avatarImage), {
											level: 'protected',
											identityId: imgUrl.id(conv.partner.avatarImage),
									  })
									: '',
							},
						};
					}),
				);

				const yourAvatarUrl =
					addedPartnersAvatarsToConversations[0].you.avatarImage &&
					(await Storage.get(
						imgUrl.filename(addedPartnersAvatarsToConversations[0].you.avatarImage),
						{
							level: 'protected',
							identityId: imgUrl.id(addedPartnersAvatarsToConversations[0].you.avatarImage),
						},
					));

				const addedAvatarsToConversations = addedPartnersAvatarsToConversations.map(conv => {
					if (!yourAvatarUrl) return conv;
					return {
						...conv,
						you: { ...conv.you, avatarURL: yourAvatarUrl },
					};
				});

				// Added partnerAvatarUrl to the conversations.
				const payload = {
					items: addedAvatarsToConversations,
					isLoading: false,
					// Check if any of the conversations have unread messages
					newMessages: addedAvatarsToConversations.reduce(function (acc, curr) {
						if (curr.Messages.items?.length === 0) {
							return acc;
						}
						return curr.Messages.items?.at(-1).isRead !== 'true' &&
							curr.Messages.items?.at(-1).from_username !== uuid.username
							? true
							: acc;
					}, false),
					updateConversations: getConversations,
				};
				setConversations(() => ({
					...payload,
					isLoading: false,
				}));
			} else {
				setConversations(() => ({ items: [], isLoading: false }));
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	console.log("conversationsProvider: before useEffect1")
	useEffect(() => {
		if (!isLoaded) {
			getConversations();
			setIsLoaded(true);
		}
	}, [conversations, getConversations, isLoaded]);

	console.log("conversationsProvider: before useEffect2")
	useEffect(() => {
		Hub.listen('auth', data => {
			console.log("auth hub event; data.payload: ", data.payload)
			const { payload } = data;
			if (payload.event === 'signIn') {
				//Query all conversations when a user has signed in
				getConversations();
			}
		});
	}, []);

	useEffect(() => {
		if (!isAuthenticated) return;

		const sub = API.graphql({
			query: onCreateMessage,
		}).subscribe({
			next: () => {
				getConversations();
			},
			error: error => {console.log("oncreatemessage subscription error: "+ error)},
		});

		return () => sub?.unsubscribe();
	}, [isAuthenticated, getConversations]);
//TODO: error handling below (next: and error:)
	useEffect(() => {
		if (!isAuthenticated) return;

		const subscription = API.graphql({
			query: onCreateConversation,
		}).subscribe({ next: () => getConversations() });

		return () => subscription.unsubscribe();
	}, [getConversations, isAuthenticated]);

	useEffect(() => {
		if (!isAuthenticated) return;

		const subscription = API.graphql({
			query: onUpdateMessage,
		}).subscribe({
			next: () => {
				getConversations();
			},
		});

		return () => subscription.unsubscribe();
	}, [getConversations, isAuthenticated]);

	useEffect(() => {
		if (!isAuthenticated) return;

		const subscription = API.graphql({
			query: onUpdateConversation,
		}).subscribe({
			next: ({ value }) => {
				console.log('onUpdateConversation called!', value);
			},
		});

		return () => subscription.unsubscribe();
	}, [isAuthenticated]);

	console.log("ConversationsProvider: returing context provider")
	return (
		<ConversationsContext.Provider value={conversations}>
			{children}
		</ConversationsContext.Provider>
	);
};
