import { useEffect, useState } from "react";
import { View, Text } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { Layout } from "../../components/Layout";
import { UserAvatar } from "../../components/UI/UserAvatar";
import { SecondaryButton } from "../../ui-components";
import { Icons } from "../../assets/icons";
import { API, Auth, Storage } from "aws-amplify";
import imgUrl from "../../helpers/imageHelper";
import { getUserProfile } from "../../graphql/queries";
import { Loading as LoadingAnimation } from "../loading";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function ProfileCreated() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [userProfileData, setUserProfileData] = useState({
    display_name: "",
    gender_identity: "",
    avatarImage: "",
  });
  const [avatarImage, setAvatarImage] = useState();

  function handleScrollUp() {
    window.scrollTo({ top: 0, left: 0 });
  }

  async function getProfileData() {
    setLoading(true);

    const uuid = await Auth.currentUserInfo();
    try {
      const response = await API.graphql({
        query: getUserProfile,
        variables: {
          cognito_uuid: uuid.attributes.sub,
        },
      });
      setUserProfileData({
        display_name: response.data.getUserProfile.display_name,
        gender_identity: response.data.getUserProfile.gender_identity,
        avatarImage: response.data.getUserProfile.avatarImage,
      });

      const imageAvatarUrl = await Storage.get(
        imgUrl.filename(response.data.getUserProfile.avatarImage),
        {
          identityId: imgUrl.id(response.data.getUserProfile.avatarImage),
          level: "protected",
        }
      );

      setAvatarImage(
        response.data.getUserProfile.avatarImage !== "" &&
          response.data.getUserProfile.avatarImage
          ? imageAvatarUrl
          : ""
      );
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  }

  useEffect(() => {
    getProfileData();
    handleScrollUp();

    return () => {};
  }, []);

  return (
    <Layout>
      {loading ? (
        <LoadingAnimation />
      ) : (
        <>
          <View
            display="flex"
            paddingTop={"53px"}
            direction={"column"}
            alignItems={"center"}
            height={"100%"}
            width="100%"
            paddingBottom="33px"
          >
            <View>
              <View
                width="100%"
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                marginBottom="24px"
              >
                <UserAvatar
                  gender={userProfileData.gender_identity}
                  userImage={avatarImage !== "" ? avatarImage : ""}
                />
              </View>
              <Text
                color="#66334A"
                fontWeight="500"
                fontSize="20px"
                textAlign="center"
              >
                Velkommen til Medforeldre, {userProfileData.display_name}!
              </Text>
            </View>
            <View
              width="100%"
              display="flex"
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Text
                textAlign="center"
                color="#6B9A9E"
                fontWeight="400"
                fontSize="20px"
                marginTop="20px"
              >
                Her på våre medlemssider kan du blant annet lese mer om det vi
                kaller "Medforeldre-reisen", utforske konseptet gjennom relevant
                forskning og erfaringer, søke etter og snakke med andre
                potensielle medforeldre, og selvfølgelig ta kontakt med våre
                rådgivere for personlig veiledning.
              </Text>
              <Text
                textAlign="center"
                color="#6B9A9E"
                fontWeight="400"
                fontSize="20px"
                marginTop="20px"
              >
                Vi ønsker deg lykke til på reisen!
              </Text>
            </View>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "25px",
                marginBottom: "40px",
              }}
            >
              <img
                src={Icons.medforeldreMenuIcon}
                width="40px"
                alt="medforeldre logo"
              />
            </div>
          </View>
          <div
            style={{
              paddingBottom: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SecondaryButton
              overrides={{
                label: { children: "Fortsett" },
              }}
              onClick={() => navigate("/medforeldre")}
            />
          </div>
        </>
      )}
      <BottomNavigationBar type={1} buttonState="active" />
    </Layout>
  );
}
