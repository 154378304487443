import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton, AlertN } from '../../ui-components';
import {
	View,
	Text,
	Heading,
	TextField,
	Flex,
	PasswordField,
	useAuthenticator,
} from '@aws-amplify/ui-react';
import { Auth, Hub, API } from 'aws-amplify';
import { getUserAccount } from '../../graphql/queries';
import { Icons } from '../../assets/icons';

export default function SignIn() {
	const { user, route } = useAuthenticator(context => [context.user, context.route]);
	const [hubError, setHubError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [resent, setResent] = useState(false);
	const [logInType, setLogInType] = useState('logIn');
	const [cognitoUser, setCognitoUser] = useState(null);
	const navigate = useNavigate();
	const [input, setInput] = useState({
		email: '',
		password: '',
	});

	function onInputChange(e) {
		setHubError(null);
		const { name, value } = e.target;
		setInput(prev => ({
			...prev,
			[name]: value,
		}));
	}

	useEffect(() => {
		const listener = async data => {
			const uuid = await Auth.currentUserInfo();
			console.log('SignIn.js  uuid: ' + uuid);
			try {
				console.log('query to get user account');
				const response = await API.graphql({
					query: getUserAccount,
					variables: {
						cognito_uuid: uuid?.attributes.sub || null,
					},
				});
				console.log('useAuthenticator: route, user: ' + route + ', ' + user);
				console.log('response.data.getUserAccount: ' + response.data.getUserAccount);
				response.data.getUserAccount ? navigate('/medforeldre') : navigate('/bankID');
			} catch (err) {
				setHubError(data.payload.data.message);
				if (data.payload.data.message.includes('does not exist')) {
					setHubError('Denne brukeren eksisterer ikke.');
				} else if (data.payload.data.message.includes('username or password')) {
					setHubError('Feil e-postadresse eller passord.');
				} else {
					setHubError(data.payload.data.message);
				}
			}
		};

		Hub.listen('auth', listener);
		return () => Hub.remove('auth', listener);
	}, [navigate]);

	async function signIn() {
		if (loading) {
			return;
		}
		setLoading(true);
		try {
			await Auth.signIn(input.email, input.password).then(user => {
				if (user.challengeName === 'SMS_MFA') {
					setCognitoUser(user);
				}
				setLogInType('mfa');
			});
		} catch (err) {
			console.log(err);
		}
		setLoading(false);
	}

	async function confirmSignIn(mfa_code) {
		if (loading) {
			return;
		}
		setLoading(true);
		try {
			await Auth.confirmSignIn(cognitoUser, mfa_code);
			checkUser();
		} catch (error) {
			console.log(error);
			setHubError('Feil verifiseringskode');
		}
		setLoading(false);
	}

	async function handleResend() {
		if (loading) {
			return;
		}
		setLoading(true);

		if (cognitoUser && cognitoUser.challengeName === 'SMS_MFA') {
			console.log('resending mfa code');
			setResent(true);
			const userResponse = await Auth.signIn(input.email, input.password);
			setCognitoUser(userResponse);
		}

		setLoading(false);
		setTimeout(() => setResent(false), 3000);
	}

	function onMFAInputChange(e) {
		if (e.target.value.match(/^[0-9]+$/) && e.target.value.length === 6) {
			confirmSignIn(e.target.value);
		}
	}

	useEffect(() => {
		checkUser();
	}, []);

	async function checkUser() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			setCognitoUser(user);
		} catch (error) {
			setCognitoUser(null);
		}
	}

	function goToSite() {
		window.location.href = 'https://www.medforeldre.no/';
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [logInType]);

	return (
		<View padding="40px 16px 10px">
			{logInType === 'logIn' && (
				<View>
					<Flex direction="column" alignItems="center" marginBottom={'20px'}>
						<View>
							<img src={Icons.logoMedforeldre} alt="logo" />
						</View>

						<Heading fontWeight="400" fontSize={'16px'} marginBottom="medium" color="#6B9A9E">
							Logg inn
						</Heading>
					</Flex>
					<Flex
						as="form"
						direction="column"
						onSubmit={function (e) {
							e.preventDefault();
							if (input.email !== '' || input.password !== '' || !hubError) {
								signIn();
							}
						}}
					>
						<TextField
							style={{
								borderLeft: 0,
								borderRight: 0,
								borderTop: 0,
								borderRadius: 0,
								borderColor: '#76B3B8',
							}}
							name="email"
							label="E-post"
							type="email"
							placeholder="Skriv inn e-postadressen din her"
							autoComplete="username"
							onChange={onInputChange}
						/>

						<PasswordField
							label="Passord"
							variation="quiet"
							name="password"
							placeholder="Skriv inn passordet ditt her"
							style={{
								borderLeft: 0,
								borderRight: 0,
								borderTop: 0,
								borderRadius: 0,
								borderColor: '#76B3B8',
							}}
							autoComplete="current-password"
							onChange={onInputChange}
						/>

						<Text
							style={{
								textAlign: 'right',
								fontWeight: '700',
								color: '#4E888D',
								fontSize: '14px',
							}}
							onClick={() => navigate('/glemt-passord')}
							marginTop="medium"
							marginBottom="large"
						>
							Har du glemt passordet ditt?
						</Text>
						<Flex direction="column" alignItems="center" gap="large">
							{hubError && (
								<AlertN
									overrides={{
										body: { children: hubError },
									}}
								/>
							)}
							<button
								style={{
									backgroundColor: 'rgb(107, 154, 158)',
									color: 'white',
									padding: '7px 15px',
									borderRadius: '8px',
									border: 'none',
									fontSize: '20px',
									width: '358px',
									fontWeight: '500',
								}}
								type="submit"
								disabled={input.email === '' || input.password === '' || hubError}
							>
								{loading ? 'Vent litt...' : 'Logg inn'}
							</button>
							<View direction="column" textAlign="center" marginTop="4rem">
								<Text
									style={{
										textAlign: 'center',
										fontWeight: '400',
										color: '#6B9A9E',
										fontSize: '16px',
									}}
								>
									Har du ikke konto hos oss enda?
								</Text>
								<Text
									style={{
										textAlign: 'center',
										fontWeight: '400',
										color: '#6B9A9E',
										fontSize: '16px',
									}}
								>
									Klikk under for å registrere deg
								</Text>
							</View>
							<button
								onClick={() => navigate('/registrering', { state: { signUpType: 'email' } })}
								style={{
									backgroundColor: '#f38183',
									color: 'white',
									padding: '7px 15px',
									borderRadius: '8px',
									border: 'none',
									fontSize: '20px',
									width: '358px',
									fontWeight: '500',
								}}
							>
								Registrer deg (med BankID)
							</button>
							<View
								direction="column"
								textAlign="center"
								marginTop="medium"
								style={{ fontWeight: 'bold' }}
							>
								<Text
									onClick={() => goToSite()}
									style={{
										textAlign: 'right',
										fontWeight: '700',
										color: '#4E888D',
										fontSize: '14px',
									}}
								>
									Vil du lese mer om Medforeldre?
								</Text>
								<Text
									style={{
										textAlign: 'center',
										fontWeight: '700',
										color: '#4E888D',
										fontSize: '14px',
									}}
									onClick={() => goToSite()}
								>
									Besøk vår nettside.
								</Text>
							</View>
						</Flex>
					</Flex>
				</View>
			)}
			{logInType === 'mfa' && (
				<View>
					<View textAlign="center">
						<Heading fontSize={'16px'} color="#48676A" marginBottom="36px" fontWeight={'500'}>
							Godkjenning
						</Heading>
						<Heading
							marginTop={'10px'}
							fontSize={'16px'}
							color="#6B9A9E"
							fontWeight={'400'}
							marginBottom="27px"
						>
							To-faktor-autentifisering
						</Heading>
					</View>
					<Flex
						marginTop={'10px'}
						direction="column"
						textAlign="center"
						marginBottom="4rem"
						alignItems="center"
						gap="0.5rem"
					>
						<img src={Icons.info} alt="info" />
						<Text fontSize={'14px'} color="#5D7679" marginBottom="medium" fontWeight={'400'}>
							For å øke sikkerheten i din innloggingsprosess, ber vi deg om å bruke to-faktor.
							Det vil si at du legger inn telefonnummeret ditt, mottar en kode på sms, og så
							skriver inn koden under her.
						</Text>
						<Flex direction="column" marginTop="medium">
							<Text fontSize={'16px'} color="#5B8386" fontWeight={'400'}>
								Mottok du ikke koden?
							</Text>
							<Text
								fontSize={'14px'}
								color="#4E888D"
								fontWeight={'700'}
								onClick={() => handleResend()}
							>
								{resent ? 'Bekreftelseskode er sendt' : 'Klikk her for å sende på nytt'}
							</Text>
						</Flex>
					</Flex>
					<TextField
						as="input"
						style={{
							borderLeft: 0,
							borderRight: 0,
							borderTop: 0,
							borderRadius: 0,
							borderColor: '#76B3B8',
							letterSpacing: '1rem',
							textAlign: 'center',
						}}
						name="mfa_code"
						label="Mottatt kode på sms"
						autoComplete="one-time-code"
						placeholder="______"
						onChange={onMFAInputChange}
					/>
					<Flex direction="column" alignItems="center" marginTop="large">
						{hubError && (
							<AlertN
								overrides={{
									body: { children: hubError },
								}}
							/>
						)}
					</Flex>
				</View>
			)}
		</View>
	);
}
