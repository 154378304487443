import React from "react";
import { View, Text, Flex } from "@aws-amplify/ui-react";
import { IconsNew } from "../../ui-components";

export function SelectFieldButton({
  label,
  placeholder,
  error,
  value,
  secondLabel,
  type,
}) {
  return (
    <View style={{ width: "100%" }}>
      <Text
        textAlign={"left"}
        width={"100%"}
        fontSize="16px"
        color={type === "personalGuidance" ? "#5B8386" : "#5B8386"}
        marginBottom={8}
      >
        {label ? label : ""}{" "}
        <span style={{ color: "#F6B6C4" }}>
          {secondLabel ? secondLabel : ""}
        </span>
      </Text>

      <View
        onClick={() => {}}
        style={{
          width: "100%",
          height: "100%",
          borderRadius: 4,
          borderWidth: 1,
          border: `1px solid ${
            error
              ? "#FF6B6B"
              : type === "personalGuidance"
              ? "#4E888D"
              : "#43898E"
          } `,
        }}
      >
        <Flex
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
        >
          <Text
            color={value ? "#29666A" : "#AEC4C6"}
            padding={"8px 50px 8px 16px "}
            paddingLeft={16}
            marginRight={50}
            overflow="hidden"
            fontSize={"20px"}
          >
            {value ? value : placeholder}
          </Text>

          <IconsNew
            position={"absolute"}
            right="11px"
            marginRight={16}
            property1="chevron_right"
            color="#43898E"
          />
        </Flex>
      </View>
    </View>
  );
}
