import male from "./male.png";
import female from "./female.png";
import nonBinary from "./non_binary.png";
import other from "./other.png";
import addPhotoDefault from "./addPhotoDefault.svg";
import photoOnTap from "./photoOnTap.svg";
import removeDefault from "./removeDefault.svg";
import removeOnTap from "./removeOnTap.svg";
import saveProfileImage from "./saveProfileImage.svg";
import setProfileImage from "./setProfileImage.svg";
import deletePhotoButton from "./deletePhotoButton.svg";
import errorPage from "./error_page.svg";
import verifiedLarge from "./verified_large.svg";
import photoLandscape from "./photo_landscape.png";
import freedomHuman from "./freedom.png";
import userFeedback from "./user-feedback.png";
import aboutUsImage from "./medforeldre_About_us.png";
import user1 from "./user1.png";
import user2 from "./user2.png";
import user3 from "./user3.png";
import user4 from "./user4.png";
import user5 from "./user5.png";
import user6 from "./user6.png";
import user7 from "./user7.png";
import user8 from "./user8.png";
import solnedgang from "./solnedgang.jpg"
import medforeldreguide from "./medforeldreguide.png"
import andreas from "./andreas.png"
import kristian from "./kristian.png"
import silje from "./silje.jpg"
import stigogida from "./stigogida.png"
import medforeldrereisen from "./medforeldrereisen.png"
import blikjentmeddegselv from "./blikjentmeddegselv.jpg"
import blikjentmedmedforelder from "./blikjentmedmedforelder.jpg"
import personligmatching from "./personligmatching.png"

export const localImages = {
  personligmatching,
  blikjentmedmedforelder,
  blikjentmeddegselv,
  medforeldrereisen,
  stigogida,
  silje,
  kristian,
  andreas,
  medforeldreguide,
  solnedgang,
  male,
  female,
  nonBinary,
  other,
  addPhotoDefault,
  removeDefault,
  removeOnTap,
  photoOnTap,
  setProfileImage,
  saveProfileImage,
  deletePhotoButton,
  errorPage,
  verifiedLarge,
  photoLandscape,
  freedomHuman,
  userFeedback,
  user1,
  user2,
  user3,
  user4,
  user5,
  user6,
  user7,
  user8,
  aboutUsImage,
};
