import React from "react";
import { Text, Flex, Card, View } from "@aws-amplify/ui-react";
import { UserAvatar } from "./UserAvatar";
import { Icons } from "../../assets/icons";

export function MembersCard({
  membersData,
  areNewMembers,
  onClickMember,
  isFavourite,
}) {
  return (
    <Card
      position={"relative"}
      variation="outlined"
      backgroundColor="#FFFFFF"
      borderRadius={"8px"}
      height="64px"
      display={"flex"}
      alignItems="center"
      border={areNewMembers ? "" : "1px solid #ffffff"}
      onClick={() => onClickMember()}
    >
      <Flex direction="row" alignItems="center">
        <UserAvatar
          userImage={
            membersData.userAvatarImage ? membersData.userAvatarImage : ""
          }
          gender={
            membersData.gender_identity ? membersData.gender_identity : ""
          }
          membersCard
        />
        <Text color="#000000" fontSize={"12px"}>
          {membersData.display_name ? membersData.display_name : ""},{" "}
          {membersData.age ? membersData.age : ""},{" "}
          {membersData.location ? membersData.location : ""}
          <br />
          {membersData.isMatch && " Dere har begge hverandre som favoritt!"}
        </Text>
      </Flex>
      {isFavourite ? (
        <View position="absolute" right="5px" bottom={"0px"}>
          <img src={Icons.addedToFavourites} alt="added-to-favourites" />
        </View>
      ) : (
        <div />
      )}
    </Card>
  );
}
