import React, { useState } from "react";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import { PrimaryButton, AppIcon } from "../../ui-components";
import { Layout } from "../../components/Layout";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets/icons";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function Recommendation() {
  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();

  return (
    <>
      <View
        height="34px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        width="100%"
        style={{ zIndex: 10 }}
      />
      <View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
        <View
          height="82px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <View>
            <div
              onClick={() => navigate("/min-profil")}
              style={{ position: "absolute", left: "16px", top: "32px" }}
            >
              <img src={Icons.arrowBackBig} alt="back" />
            </div>
          </View>

          <Text
            textAlign={"center"}
            color={"#29666A"}
            fontSize="16px"
            style={{ position: "absolute", top: "50%" }}
          >
            Anbefal Medforeldre til andre
          </Text>
        </View>
      </View>

      <Layout>
        <Flex
          direction="column"
          textAlign="center"
          marginTop={106}
          marginBottom="xl"
          alignItems="center"
          gap="0.5rem"
        >
          <AppIcon marginBottom="large" />

          <img src={Icons.info} alt="info" />
          <Text color="#899D9F" marginBottom="large">
            Hvis du vil anbefale Medforeldre til andre, kan du enkelt klikke på
            knappen under for å kopiere en link. Så er det bare å lime inn denne
            i en melding og sende på f.eks. sms, messenger eller snap.
          </Text>
          <CopyToClipboard
            text={"https://www.medforeldre.no/"}
            onCopy={() => setCopied(true)}
          >
            <PrimaryButton
              overrides={{
                label: {
                  children: copied
                    ? "Linken ble kopiert"
                    : "Kopier link til Medforeldre",
                },
              }}
              property1={copied ? "done" : "enabled"}
            />
          </CopyToClipboard>
        </Flex>
        {/* <Flex direction="column">
          <TextField
            style={{
              borderLeft: 0,
              borderRight: 0,
              borderTop: 0,
              borderRadius: 0,
              borderColor: "#76B3B8",
            }}
            name="email"
            label="E-post"
            placeholder="Skriv inn e-postadressen din her"
          />
          <Text
            style={{ textAlign: "right", fontWeight: "bold" }}
            //onClick={() => navigate("/forgotPassword")}
            marginTop="medium"
            marginBottom="large"
          >
            Legg til ny e-postadresse
          </Text>
        </Flex> */}
      </Layout>

      <BottomNavigationBar buttonState="active" type={1} />
    </>
  );
}
