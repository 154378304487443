import React, { useState, useContext, useEffect } from "react";
import { View, Text, RadioGroupField, Radio } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router";
import { ProfileDataContext } from "../../contexts/contexts";
import { Icons } from "../../assets/icons";

export function MaritalStatusSettings() {
  const { setProfileSettingsValues, profileSettingsValues } =
    useContext(ProfileDataContext);

  const navigate = useNavigate();

  const [currentMaritalStatus, setCurrentMaritalStatus] = useState(
    profileSettingsValues.marital_status
  );

  function handleApplyMaritalStatus(value) {
    setCurrentMaritalStatus(value);
    setProfileSettingsValues((prevData) => ({
      ...prevData,
      marital_status: value,
    }));
    navigate("/profil/innstillinger");
  }

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <View height="100vh">
      <View
        height="32px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        width="100%"
      />
      <View
        position="fixed"
        top="32px"
        width="100%"
        display="flex"
        direction="row"
      >
        <View
          height="48px"
          backgroundColor="#F3F7F7"
          width="100%"
          direction="row"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <Text textAlign={"center"} color={"#29666A"} fontSize="16px">
            Samlivsform
          </Text>

          <img
            onClick={() => navigate("/profil/innstillinger")}
            style={{ position: "absolute", right: "16px", bottom: "4px" }}
            src={Icons.cancel}
            alt="cancel"
          />
        </View>
      </View>

      <View
        paddingTop="60px"
        margin="0 16px 0 16px"
        display="flex"
        justifyContent="space-between"
        direction="column"
        alignItems="center"
        height={"100vh"}
      >
        <View width="100%">
          <RadioGroupField
            marginTop={"40px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <Radio
              readOnly={true}
              checked={currentMaritalStatus === "Singel" ? true : false}
              onClick={() => handleApplyMaritalStatus("Singel")}
              value={""}
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Singel
              </Text>
            </Radio>
          </RadioGroupField>
          <RadioGroupField
            marginTop={"24px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <Radio
              readOnly={true}
              checked={
                currentMaritalStatus === "Parforhold / romantisk partner"
                  ? true
                  : false
              }
              onClick={() =>
                handleApplyMaritalStatus("Parforhold / romantisk partner")
              }
              value=""
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Parforhold / romantisk partner
              </Text>
            </Radio>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"24px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <Radio
              readOnly={true}
              checked={currentMaritalStatus === "Gift" ? true : false}
              onClick={() => handleApplyMaritalStatus("Gift")}
              value=""
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Gift
              </Text>
            </Radio>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"24px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F9FBFB"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <Radio
              readOnly={true}
              checked={currentMaritalStatus === "Annet" ? true : false}
              onClick={() => handleApplyMaritalStatus("Annet")}
              value=""
              width={"100%"}
            >
              <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                Annet
              </Text>
            </Radio>
          </RadioGroupField>
        </View>
      </View>
    </View>
  );
}
