import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Container, IconWrapper, Image } from './BottomNavigationBar.style';
import { Icons } from '../../assets/icons';
import { ConversationsContext } from '../../contexts/conversations';

export function BottomNavigationBar({ buttonState, type }) {
	const conversations = useContext(ConversationsContext);

	return (
		<Container>
			<IconWrapper
				onClick={() => (window.location.href = '/min-profil')}
				buttonState={type === 1 && buttonState}
			>
				{type === 1 ? (
					<Image src={Icons.userProfileWhite} alt="user-profile" />
				) : (
					<Image src={Icons.userProfile} alt="user-profile" />
				)}
			</IconWrapper>
			<IconWrapper
				onClick={() => (window.location.href = '/medforeldre')}
				buttonState={type === 2 && buttonState}
			>
				{type === 2 ? (
					<Image src={Icons.medforeldreWhite} alt="medforeldre" />
				) : (
					<Image src={Icons.medforeldre} alt="medforeldre" />
				)}
			</IconWrapper>
			<IconWrapper
				onClick={() => (window.location.href = '/medlemmer')}
				buttonState={type === 3 && buttonState}
			>
				{type === 3 ? (
					<Image src={Icons.searchWhite} alt="search" />
				) : (
					<Image src={Icons.search} alt="search" />
				)}
			</IconWrapper>
			<IconWrapper
				onClick={() => (window.location.href = '/favoritter')}
				buttonState={type === 4 && buttonState}
			>
				{type === 4 ? (
					<Image src={Icons.favoritesWhite} alt="favourites" />
				) : (
					<Image src={Icons.favourites} alt="favourites" />
				)}
			</IconWrapper>
			<IconWrapper
				onClick={() => (window.location.href = '/samtaler')}
				buttonState={type === 5 && buttonState}
			>
				{type === 5 ? (
					conversations.newMessages ? (
						<Image src={Icons.messagesWithNotifyWhite} alt="messages" />
					) : (
						<Image src={Icons.messageEmptyWhite} alt="messages" />
					)
				) : conversations.newMessages ? (
					<Image src={Icons.messageActive} alt="messages" />
				) : (
					<img src={Icons.messageInactiveGreen} alt="messages" />
				)}
			</IconWrapper>
		</Container>
	);
}
