import React, { useEffect, useState, useContext, useRef } from 'react';
import { Flex, Text, View } from '@aws-amplify/ui-react';
import { SecondaryButton } from '../../ui-components';
import { Layout } from '../../components/Layout';
import imgUrl from '../../helpers/imageHelper';
import Compress from 'client-compress';
import { UserAvatar } from '../../components/UI/UserAvatar';
import { GalleryPicture } from '../../components/UI/GalleryPicture';
import { TopNavigationBar } from '../../components/UI/TopNavigationBar';
import { useNavigate } from 'react-router';
import { getUserProfile } from '../../graphql/queries';
import { ImagesDataContext } from '../../contexts/contexts';
import { updateUserProfile } from '../../graphql/mutations';
import uuid from 'react-uuid';

import { API, Auth, Storage } from 'aws-amplify';
import { GalleryEmptyPicture } from '../../components/UI/GalleryEmptyPicture';

import { Loading as LoadingAnimation } from '../loading';

function getWindowSize() {
	const { innerWidth, innerHeight } = window;
	return { innerWidth, innerHeight };
}

export function AccountDetails() {
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [imagesLists, setImagesLists] = useState([]);
	const [avatarImage, setAvatarImage] = useState();

	const [windowHeight, setWindowHeight] = useState(getWindowSize().innerHeight);

	const [userProfileData, setUserProfileData] = useState({
		gender_identity: '',
		imagesList: [],
		avatarImage: '',
	});

	const focusUploadImages = useRef();

	const { setImagesData } = useContext(ImagesDataContext);
	const { setImageUrlProfile } = useContext(ImagesDataContext);

	useEffect(() => {
		function handleWindowResize() {
			setWindowHeight(getWindowSize().innerHeight);
		}

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	const getProfileData = React.useCallback(async () => {
		setLoading(true);
		const uuid = await Auth.currentUserInfo();
		try {
			const response = await API.graphql({
				query: getUserProfile,
				variables: {
					cognito_uuid: uuid.attributes.sub,
				},
			});
			setUserProfileData({
				gender_identity: response.data.getUserProfile.gender_identity,
				avatarImage: response.data.getUserProfile.avatarImage,
				imagesList: response.data.getUserProfile.imagesList,
			});

			let arrayImages = [];

			response.data.getUserProfile.imagesList !== null &&
				response.data.getUserProfile.imagesList.length > 0 &&
				(await Promise.all(
					response.data.getUserProfile.imagesList.length > 0 &&
						response.data.getUserProfile.imagesList.map(async data => {
							const signedUrl = await Storage.get(imgUrl.filename(data), {
								identityId: imgUrl.id(data),
								level: 'protected',
							});

							arrayImages.push({ name: data, url: signedUrl });
						}),
				));
			setImagesLists(arrayImages && arrayImages);
			setImageUrlProfile(arrayImages && arrayImages[0].url);

			const avatarPack = imgUrl.decode(response.data.getUserProfile.avatarImage);

			const imageAvatarUrl = await Storage.get(avatarPack.filename, {
				identityId: avatarPack.id,
				level: 'protected',
			});

			setAvatarImage(
				response.data.getUserProfile.avatarImage !== '' &&
					response.data.getUserProfile.avatarImage
					? imageAvatarUrl
					: '',
			);
			setImageUrlProfile(
				response.data.getUserProfile.avatarImage !== '' &&
					response.data.getUserProfile.avatarImage
					? imageAvatarUrl
					: '',
			);
		} catch (error) {
			console.log(error);
		}

		setLoading(false);
	}, [setImageUrlProfile]);

	useEffect(() => {
		getProfileData();
	}, [getProfileData]);

	function handleScrollUp() {
		window.scrollTo({ top: 0, left: 0 });
	}

	useEffect(() => {
		handleScrollUp();
	}, [loading]);

	const handleUploadImagesList = async () => {
		return focusUploadImages?.current?.click();
	};

	const onUploadImagesList = async event => {
		event.persist();
		const compress = new Compress({
			targetSize: 0.2,
			quality: 0.75,
			maxWidth: 1024,
			maxHeight: 1024,
		});

		const identityId = await Auth.currentUserInfo();
		const fileData = event.target.files[0];

		compress.compress([fileData]).then(async conversions => {
			const { photo, info } = conversions[0];

			console.log({ photo, info });

			try {
				const uniqName = uuid();

				await Storage.put(uniqName + fileData.name, photo.data, {
					identityId: identityId.id,
					contentType: fileData.type,
					level: 'protected',
				});

				const imagepack = imgUrl.encode(uniqName + fileData.name, identityId.id);
				const updatedImagesList =
					userProfileData.imagesList !== null
						? [...userProfileData.imagesList, imagepack]
						: [imagepack];

				await API.graphql({
					query: updateUserProfile,
					variables: {
						input: {
							cognito_uuid: identityId.attributes.sub,
							imagesList: updatedImagesList,
						},
					},
				});

				getProfileData();
			} catch (error) {
				console.log('Error uploading file: ', error);
			}
		});
	};

	async function handleProfileAvatar(imageName) {
		const identityId = await Auth.currentUserInfo();

		try {
			await API.graphql({
				query: updateUserProfile,
				variables: {
					input: {
						cognito_uuid: identityId.attributes.sub,
						avatarImage: imageName,
					},
				},
			});

			getProfileData();
		} catch (error) {
			console.log('Error uploading file: ', error);
		}
	}

	async function handleDeleteImage(imageId, imageName) {
		setImagesData({
			userAvatarImageName:
				imageId === userProfileData.avatarImage ? '' : userProfileData.avatarImage,
			imageId: imageId,
			imageName: imageName,
			imagesList: userProfileData.imagesList,
		});
		navigate('/slett-bilde');
	}

	return (
		<>
			<TopNavigationBar
				header="Profilinnstillinger"
				showIcon={true}
				navigateBackTo="/min-profil"
				accountDetailsPage={true}
				noIconBack
			/>
			{loading ? (
				<LoadingAnimation />
			) : (
				<>
					<Layout>
						<Flex
							marginTop={106}
							direction={'column'}
							alignItems={'center'}
							justifyContent="space-between"
							minHeight={windowHeight - 162}
						>
							<View
								width="100%"
								display="flex"
								direction={'column'}
								alignItems={'center'}
								justifyContent="center"
								minHeight="400px"
							>
								<Text
									fontSize={16}
									fontWeight="400"
									textAlign={'center'}
									width="100%"
									color="#4A888C"
									marginBottom="20px"
								>
									Velg ditt profilbilde
								</Text>

								<UserAvatar userImage={avatarImage} gender={userProfileData.gender_identity} />

								<View
									width="100%"
									display="flex"
									direction="column"
									justifyContent="center"
									alignItems="center"
								>
									<Text
										fontSize={'16px'}
										fontWeight="400"
										color="#6B9A9E"
										marginTop={'40px'}
										marginBottom="20px"
									>
										Legg til bilder i ditt galleri (opp til 10 bilder){' '}
									</Text>
								</View>
								<View width="100%">
									<Flex
										direction={'row'}
										wrap="wrap"
										gap={window.screen.width > 380 ? '17px' : '8px'}
									>
										<input
											onChange={e => onUploadImagesList(e)}
											type={'file'}
											style={{ display: 'none' }}
											ref={focusUploadImages}
										/>
										{imagesLists
											.sort((a, b) => b._lastChangedAt - a._lastChangedAt)
											.map((data, index) => {
												let isAvatar = data.name === userProfileData.avatarImage ? true : false;
												return (
													<View key={index}>
														<GalleryPicture
															avatarImage={isAvatar}
															savedImage={false}
															onSetProfileImage={() => handleProfileAvatar(data.name)}
															pictureData={data.url}
															onDeleteImage={() => handleDeleteImage(data.name, data.url)}
														/>
													</View>
												);
											})}
										{imagesLists.length < 10 && (
											<GalleryEmptyPicture onUploadPicture={handleUploadImagesList} />
										)}
									</Flex>
								</View>
							</View>

							<View marginTop={'25px'}>
								<SecondaryButton
									onClick={() => {
										navigate('/forhandsvis-ny-profil');
									}}
									overrides={{
										label: {
											children: 'Lagre og forhåndsvis din profil',
										},
									}}
								/>
							</View>
						</Flex>
					</Layout>
				</>
			)}
		</>
	);
}
