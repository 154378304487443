import React, { useEffect, useState } from "react";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import { SecondaryButton, SwitchFieldNew } from "../../ui-components";
import { Layout } from "../../components/Layout";
import { Spacer } from "../../components/UI/Spacer";
import { Icons } from "../../assets/icons";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";
import { useNavigate } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { getUserAccount } from "../../graphql/queries";
import { updateUserAccount } from "../../graphql/mutations";

export function NotificationSettings() {
  const navigate = useNavigate();
  
  const [hasEmailNotification, setHasEmailNotification] =
    useState(null); //sets default notification settings to null, to prevent display before loading.

  useEffect(() => {
    getAccountData();
  }, []);

  async function getAccountData() {
    try {
      const uuid = await Auth.currentUserInfo();

      const response = await API.graphql({
        query: getUserAccount,
        variables: {
          cognito_uuid: uuid.attributes.sub,
        },
      });

      if (response.data.getUserAccount) {
        response.data.getUserAccount.notification_preference === "email"
          ? setHasEmailNotification(true)
          : setHasEmailNotification(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function updateNotificationPreference() {
    console.log("updaing notification preference");
    console.log("notification preference", hasEmailNotification);
    try {
      const uuid = await Auth.currentUserInfo();
      console.log(uuid);

      const response = await API.graphql({
        query: updateUserAccount,
        variables: {
          input: {
            cognito_uuid: uuid.attributes.sub,
            notification_preference: hasEmailNotification ? "email" : "",
          },
        },
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    navigate("/min-profil");
  }

  return (
    <>
      <View
        height="34px"
        backgroundColor="#F3F7F7"
        position="fixed"
        top="0px"
        width="100%"
        style={{ zIndex: 10 }}
      />
      <View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
        <View
          height="82px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <View>
            <div
              onClick={() => navigate("/min-profil")}
              style={{ position: "absolute", left: "16px", top: "32px" }}
            >
              <img src={Icons.arrowBackBig} alt="Tilbake" />
            </div>
          </View>

          <Text
            textAlign={"center"}
            color={"#29666A"}
            fontSize="16px"
            style={{ position: "absolute", top: "50%" }}
          >
            Varselinnstillinger
          </Text>
        </View>
      </View>
      <Layout>
        <Flex
          direction="column"
          textAlign="center"
          marginTop={106}
          marginBottom="xl"
          alignItems="center"
          gap="0.5rem"
        >
          <img src={Icons.info} alt="info" />
          <Text color="#5D7679">
            Her kan du endre innstillinger for hvilke varsler du ønsker å motta.
          </Text>
        </Flex>
        <Flex direction="column" gap="medium" marginBottom="4rem">
          <Text>E-post-varsler</Text>
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text color="#869798">Mottatt ny melding</Text>
            {hasEmailNotification !== null && <SwitchFieldNew
              property1={hasEmailNotification ? "EnabledOn" : "EnabledOff"}
              onClick={() =>
                setHasEmailNotification(!hasEmailNotification)
              }
              overrides={
                hasEmailNotification
                  ? {
                      label: { children: "På" },
                    }
                  : { label: { children: "Av" } }
              }
            />}
          </Flex>
        </Flex>
        <Spacer height={150} />
        <Flex direction="column" alignItems="center" marginTop="large">
          <SecondaryButton
            onClick={() => updateNotificationPreference()}
            overrides={{
              label: {
                children: "Lagre innstillinger",
              },
            }}
          />
        </Flex>
        <BottomNavigationBar buttonState="active" type={1} />
      </Layout>
    </>
  );
}
