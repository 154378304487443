import { useState, useEffect } from "react";
import {
  Text,
  View,
  TextAreaField,
  RadioGroupField,
  Radio,
  Flex,
} from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets/icons";
import { PrimaryButton } from "../../ui-components";
import { SelectFieldButton } from "../../components/UI/SelectFieldButton";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function ContactMedforeldre() {
  const navigate = useNavigate();
  const [isVisibleCategories, setIsVisibleCategories] = useState(false);

  const [inputValue, setInputValue] = useState({
    subject: "",
    body: "",
    terms: false,
  });

  function handleSetInputValue(key, value) {
    setInputValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }

  function handleFilterCategory(value) {
    setInputValue((prevState) => ({
      ...prevState,
      subject: value,
    }));

    setIsVisibleCategories(false);
  }

  function handleSendEmail() {
    // replace("^")  is for security
    if (inputValue.subject.length > 0 && inputValue.body.length > 0) {
      let data = `mailto:${"support^medforeldre.no".replace(
        "^",
        "@"
      )}?subject=${inputValue.subject}&body=${inputValue.body}`;
      window.open(data, "_blank");
    }
  }

  function handleScrollUp() {
    window.scrollTo({ top: 0, left: 0 });
  }

  useEffect(() => {
    handleScrollUp();
  }, [isVisibleCategories]);

  return (
    <>
      {!isVisibleCategories ? (
        <>
          <TopNavigationBar
            header={"Kontakt oss"}
            isMedforeldrePage
            onArrowBackClick={() => navigate("/om-medforeldre-as")}
            noIconBack
            onCancelClick={() => navigate("/om-medforeldre-as")}
          />
          <Layout>
            <View
              paddingTop="104px"
              display="flex"
              direction="column"
              alignItems="center"
              width="100%"
            >
              <img
                style={{ marginTop: "16px", marginBottom: "7px" }}
                src={Icons.info}
                alt="info"
              />
              <Text
                textAlign={"center"}
                color="#5D7679"
                fontSize={"14px"}
                marginTop="16px"
                marginBottom={"32px"}
              >
                Send oss en melding om du har noen spørsmål, gode
                innspill, eller vil melde fra om en feil. Vi svarer deg så fort
                vi kan (når du klikker "send" under vil meldingen åpne seg som en epost som du så sender).
              </Text>

              <View
                onClick={() => setIsVisibleCategories(true)}
                width="100%"
                marginBottom="32px"
              >
                <SelectFieldButton
                  error={""}
                  label={"Velg en overskrift / tema"}
                  placeholder={"Velg en overskrift / tema"}
                  value={inputValue.subject}
                  type={"personalGuidance"}
                />
              </View>

              <View width="100%" marginTop="20px" marginBottom="24px">
                <TextAreaField
                  onChange={(e) => handleSetInputValue("body", e.target.value)}
                  fontSize={"20px"}
                  border="1px solid #A2C7CB"
                  style={{ color: "#5B8386", background: "#ffffff" }}
                  color="#5B8386"
                  width={"100%"}
                  label={
                    <Text color="#5B8386" fontWeight={"400"} fontSize="16px">
                      Din melding <span style={{ color: "#F6B6C4" }}>*</span>
                    </Text>
                  }
                  value={inputValue.body}
                  placeholder={"Skriv din melding her"}
                />
              </View>
              <Text marginBottom={"29px"}>
                Alle felter merket med{" "}
                <span style={{ color: "#A82E30" }}>*</span> er obligatoriske.{" "}
              </Text>

              <View marginBottom="98px" display="flex" justifyContent="center">
                <PrimaryButton
                  property1={
                    inputValue.subject.length > 0 && inputValue.body.length > 0
                      ? "enabled"
                      : "disabled"
                  }
                  onClick={() =>
                    inputValue.subject.length > 0 && inputValue.body.length > 0
                      ? handleSendEmail()
                      : {}
                  }
                  overrides={{ label: { children: "Send" } }}
                />
              </View>
            </View>
          </Layout>
          <BottomNavigationBar buttonState="active" type={2} />
        </>
      ) : (
        <Layout>
          <View
            height="33px"
            backgroundColor="#F2F6F6"
            width="100%"
            position="fixed"
            top={0}
            left={0}
            right={0}
            style={{ zIndex: 100 }}
          />

          <Flex
            direction="row"
            alignItems="center"
            margin="0 auto"
            justifyContent="center"
            position="fixed"
            top="32px"
            left="0px"
            right="0px"
            width="100%"
            height="48px"
            style={{ zIndex: 100 }}
            backgroundColor={"#F2F6F6"}
          >
            <View
              paddingLeft="15px"
              marginRight="auto"
              onClick={() => setIsVisibleCategories(false)}
            >
              <img src={Icons.arrowBackBig} alt="back" />
            </View>
            <View
              display="flex"
              direction="column"
              position="fixed"
              textAlign="center"
              justifyContent="center"
              gap="0.2rem"
            >
              <Text
                textAlign={"center"}
                fontSize="16px"
                fontWeight={"500"}
                color="#5D8D92"
              >
                Velg overskrift / tema
              </Text>
            </View>
          </Flex>

          <View width="100%" marginTop="112px">
            <RadioGroupField
              marginTop={"40px"}
              width="100%"
              height={"48px"}
              backgroundColor={"#F6F5F4"}
              borderRadius="4px"
              justifyContent={"flex-start"}
              direction="row"
              alignItems="center"
            >
              <Radio
                readOnly={true}
                checked={inputValue.subject === "Hjelp" ? true : false}
                onClick={() => handleFilterCategory("Hjelp")}
                value={""}
                width={"100%"}
              >
                <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                  Hjelp
                </Text>
              </Radio>
            </RadioGroupField>
            <RadioGroupField
              marginTop={"24px"}
              width="100%"
              height={"48px"}
              backgroundColor={"#F6F5F4"}
              borderRadius="4px"
              justifyContent={"flex-start"}
              direction="row"
              alignItems="center"
            >
              <Radio
                readOnly={true}
                checked={
                  inputValue.subject === "Tilbakemelding / forslag"
                    ? true
                    : false
                }
                onClick={() => handleFilterCategory("Tilbakemelding / forslag")}
                value=""
                width={"100%"}
              >
                <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                  Tilbakemelding / forslag
                </Text>
              </Radio>
            </RadioGroupField>

            <RadioGroupField
              marginTop={"24px"}
              width="100%"
              height={"48px"}
              backgroundColor={"#F6F5F4"}
              borderRadius="4px"
              justifyContent={"flex-start"}
              direction="row"
              alignItems="center"
            >
              <Radio
                readOnly={true}
                checked={
                  inputValue.subject === "Meld ifra om en feil" ? true : false
                }
                onClick={() => handleFilterCategory("Meld ifra om en feil")}
                value=""
                width={"100%"}
              >
                <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                  Meld ifra om en feil
                </Text>
              </Radio>
            </RadioGroupField>

            <RadioGroupField
              marginTop={"24px"}
              width="100%"
              height={"48px"}
              backgroundColor={"#F6F5F4"}
              borderRadius="4px"
              justifyContent={"flex-start"}
              direction="row"
              alignItems="center"
            >
              <Radio
                readOnly={true}
                checked={
                  inputValue.subject === "Rapporter et annet medlem"
                    ? true
                    : false
                }
                onClick={() =>
                  handleFilterCategory("Rapporter et annet medlem")
                }
                value=""
                width={"100%"}
              >
                <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                  Rapporter et annet medlem
                </Text>
              </Radio>
            </RadioGroupField>

            <RadioGroupField
              marginTop={"24px"}
              width="100%"
              height={"48px"}
              backgroundColor={"#F6F5F4"}
              borderRadius="4px"
              justifyContent={"flex-start"}
              direction="row"
              alignItems="center"
            >
              <Radio
                readOnly={true}
                checked={inputValue.subject === "Annet" ? true : false}
                onClick={() => handleFilterCategory("Annet")}
                value=""
                width={"100%"}
              >
                <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                  Annet
                </Text>
              </Radio>
            </RadioGroupField>
          </View>
        </Layout>
      )}
    </>
  );
}
