import React from "react";
import { SecondaryButton } from "../../ui-components";
import { Flex, View, Text, Link } from "@aws-amplify/ui-react";
import { localImages } from "../../images";

export function ErrorScreen() {
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <>
      <View padding="0px 16px 0px 16px">
        <Flex
          direction="column"
          justifyContent="space-between"
          alignItems={"center"}
          height={"100vh"}
        >
          <Flex
            width={"100%"}
            paddingTop={120}
            display="flex"
            direction="column"
            alignItems={"center"}
            height={"243px"}
          >
            <>
              <View>
                <img src={localImages.errorPage} alt="warning" />
              </View>

              <Text fontSize={"20px"} fontWeight="500" color={"#4D6F6D"}>
                Tilkoblingsfeil
              </Text>

              <Text
                fontSize={"14px"}
                fontWeight="400"
                color={"#5D7679"}
                textAlign="center"
              >
                Noe gikk dessverre ikke som det skulle. Vi beklager det!
                Vennligst prøv igjen. Om problemet vedvarer, kan du kontakte oss
                via vår nettside:{" "}
                <Link
                  color="#6B9A9E"
                  onClick={() => (window.location = "www.medforeldre.no")}
                >
                  <span style={{ color: "#6B9A9E" }}>www.medforeldre.no</span>
                </Link>{" "}
              </Text>

              <View display="flex" justifyContent="center">
                <View position="absolute" bottom="56px">
                  <SecondaryButton
                    onClick={() => refreshPage()}
                    overrides={{
                      label: {
                        children: "Prøv igjen",
                      },
                    }}
                  />
                </View>
              </View>
            </>
          </Flex>
        </Flex>
      </View>
    </>
  );
}
