import React, { useContext } from "react";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import {
  Text,
  View,
  RadioGroupField,
  CheckboxField,
} from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { FiltersGenderContext } from "../../contexts/contexts";
import { useNavigate } from "react-router-dom";

export function FilterGender() {
  const navigate = useNavigate();
  const { genderState, setGenderState } = useContext(FiltersGenderContext);

  function handleFilterData(name, value) {
    setGenderState((prevData) => ({
      ...prevData,
      [name]: !value,
    }));
  }

  return (
    <>
      <TopNavigationBar
        noIconBack
        isMembersFilterPage
        onArrowBackClick={() => navigate("/filter-medlemmer")}
        header="Velg kjønnsidentitet(er)"
        onFilterClick={() => navigate("/filter-medlemmer")}
      />

      <Layout>
        <View
          paddingTop="104px"
          display="flex"
          justifyContent="center"
          direction="column"
        >
          <RadioGroupField
            marginTop={0}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() => handleFilterData("Mann", genderState.Mann)}
              value={""}
              width={"100%"}
              checked={genderState.Mann}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Mann
            </Text>
            {/* </Radio> */}
          </RadioGroupField>
          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() => handleFilterData("Kvinne", genderState.Kvinne)}
              value=""
              width={"100%"}
              checked={genderState.Kvinne}
              readOnly={true}
            />

            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Kvinne
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Ikke-binær", genderState["Ikke-binær"])
              }
              value=""
              width={"100%"}
              checked={genderState["Ikke-binær"]}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Ikke-binær
            </Text>
          </RadioGroupField>
        </View>
      </Layout>
    </>
  );
}
