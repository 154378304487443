import React, { useEffect, useState } from "react";
import { View, Heading, Flex, Text, TextField } from "@aws-amplify/ui-react";
import { SecondaryButton } from "../../ui-components";
import { useNavigate } from "react-router-dom";
import { API, Auth } from "aws-amplify";
import { getUserAccount } from "../../graphql/queries";
import { Icons } from "../../assets/icons";

export function BankIDAccountDetails() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [userAccountData, setUserAccountData] = useState({
    cognito_uuid: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    phone_number: "",
    email: "",
  });
  const [birthDate, setBirthDate] = useState("");

  useEffect(() => {
    getAccountData();
  }, []);

  async function getAccountData() {
    setLoading(true);
    const uuid = await Auth.currentUserInfo();

    setUserAccountData({
      cognito_uuid: uuid.attributes.sub,
      phone_number: uuid.attributes.phone_number,
      email: uuid.attributes.email,
    });

    await API.graphql({
      query: getUserAccount,
      variables: {
        cognito_uuid: uuid.attributes.sub,
      },
    })
      .then((response) => {
        setUserAccountData({
          cognito_uuid: response.data.getUserAccount.cognito_uuid,
          first_name: response.data.getUserAccount.first_name,
          last_name: response.data.getUserAccount.last_name,
          date_of_birth: response.data.getUserAccount.date_of_birth,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  }

  useEffect(() => {
    if (userAccountData.date_of_birth && userAccountData.date_of_birth !== "") {
      const dateList = userAccountData.date_of_birth.split("-");
      const newString =
        dateList[2].substring(0, dateList[2].indexOf("T")) +
        "." +
        dateList[1] +
        "." +
        dateList[0];
      setBirthDate(newString);
    }
  }, [userAccountData]);

  return (
    <>
      <View position="fixed" top="0px" width="100%" style={{ zIndex: 100 }}>
        <View
          height="82px"
          backgroundColor="#F3F7F7"
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
          position="relative"
        >
          <Text
            textAlign={"center"}
            color={"#29666A"}
            fontSize="16px"
            fontWeight={"500"}
            style={{ position: "absolute", top: "50%" }}
          >
            Din registrerte kontoinformasjon
          </Text>
        </View>
      </View>

      <View padding="104px 16px 10px">
        <Heading
          fontWeight="400"
          color="#6B9A9E"
          // paddingTop="104px"
          marginBottom="large"
          textAlign="center"
        >
          Opplysninger innhentet via BankID
        </Heading>
        <Flex
          direction="column"
          textAlign="center"
          marginTop="large"
          marginBottom="xl"
          alignItems="center"
          gap="0.5rem"
        >
          <img src={Icons.info} alt="info" />
          <Text color="#5D7679" fontWeight={"400"} fontSize="14px">
            Informasjonen under blir kun registrert på din brukerkonto, og vil
            ikke være synlig for andre medlemmer. I neste steg oppgir du
            informasjonen som blir synlig for andre på ditt profilkort.
          </Text>
        </Flex>
        <Flex as="form" direction="column">
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#5B8386",
            }}
            name="first_name"
            label="Fornavn"
            isReadOnly={true}
            defaultValue={userAccountData.first_name}
          />
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#5B8386",
            }}
            name="last_name"
            label="Etternavn"
            isReadOnly={true}
            defaultValue={userAccountData.last_name}
          />
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#5B8386",
            }}
            name="birth_date"
            label="Fødselsdato"
            isReadOnly={true}
            defaultValue={birthDate}
          />
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#5B8386",
            }}
            name="telephone"
            label="Telefonnummer"
            isReadOnly={true}
            defaultValue={userAccountData.phone_number}
          />
          <TextField
            style={{
              border: 0,
              borderColor: "#76B3B8",
              color: "#5B8386",
            }}
            name="email"
            label="E-post"
            isReadOnly={true}
            defaultValue={userAccountData.email}
          />
        </Flex>
        <Flex
          direction="column"
          alignItems="center"
          marginTop="48px"
          marginBottom={"48px"}
        >
          <SecondaryButton
            onClick={() => navigate("/lag-profil")}
            overrides={
              loading
                ? {
                    label: { children: "Vent litt..." },
                  }
                : { label: { children: "Neste" } }
            }
          />
        </Flex>
      </View>
    </>
  );
}
