import { createContext } from "react";

export const ProfileDataContext = createContext();

export const ImagesDataContext = createContext();

export const FiltersLocationsContext = createContext();

export const FiltersGenderContext = createContext();

export const FiltersOrientationContext = createContext();

export const FiltersCohabitationContext = createContext();

export const FiltersApplyContext = createContext();
