import React, { useContext, useEffect, useState } from "react";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { Text, View } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../assets/icons";
import { RadioGroupField, CheckboxField } from "@aws-amplify/ui-react";
import { FiltersLocationsContext } from "../../contexts/contexts";

export function FilterLocations() {
  const navigate = useNavigate();
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const { setFiltersLocationData, filtersLocationData } = useContext(
    FiltersLocationsContext
  );

  function handleFilterData(name, value) {
    setFiltersLocationData((prevData) => ({
      ...prevData,
      [name]: !value,
    }));
  }

  function handleSelectAll() {
    setFiltersLocationData((prevData) => ({
      ...prevData,
      Agder: true,
      Innlandet: true,
      "Møre og Romsdal": true,
      Nordland: true,
      Oslo: true,
      Rogaland: true,
      "Vestfold og Telemark": true,
      "Troms og Finnmark": true,
      Trøndelag: true,
      Vestland: true,
      Viken: true,
    }));
  }

  function deselectAll() {
    setFiltersLocationData((prevData) => ({
      ...prevData,
      Agder: false,
      Innlandet: false,
      "Møre og Romsdal": false,
      Nordland: false,
      Oslo: false,
      Rogaland: false,
      "Vestfold og Telemark": false,
      "Troms og Finnmark": false,
      Trøndelag: false,
      Vestland: false,
      Viken: false,
    }));
  }

  useEffect(() => {
    const selected = Object.values(filtersLocationData).filter(
      (data) => data === true
    );
    setSelectedCheckboxes(selected);
  }, [filtersLocationData]);

  return (
    <>
      <TopNavigationBar
        noIconBack
        isMembersFilterPage
        onArrowBackClick={() => navigate("/filter-medlemmer")}
        header="Velg fylke(r) du vil søke i"
        onFilterClick={() => navigate("/filter-medlemmer")}
      />

      <Layout>
        <View
          paddingTop="104px"
          display="flex"
          justifyContent="center"
          direction="column"
        >
          <View
            display="flex"
            justifyContent="center"
            direction="column"
            alignItems="center"
          >
            <img width={"24px"} height="24px" src={Icons.info} alt="info" />
          </View>

          <Text textAlign={"center"} marginTop="4px">
            Velg de fylkene du vil søke etter medlemmer i. Du kan velge flere om
            du ønsker det.
          </Text>

          <View
            height="36px"
            onClick={() => {}}
            marginTop="24px"
            marginRight="16px"
          >
            <Text
              onClick={() =>
                selectedCheckboxes.length < 5
                  ? handleSelectAll()
                  : deselectAll()
              }
              color={"#4E888D"}
              fontSize="16px"
              fontWeight={"700"}
              textAlign="right"
            >
              {selectedCheckboxes.length < 5 ? "Velg alle" : "Fjern alle"}
            </Text>
          </View>

          <RadioGroupField
            marginTop={"24px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Agder", filtersLocationData.Agder)
              }
              value={""}
              width={"100%"}
              checked={filtersLocationData.Agder}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Agder
            </Text>
            {/* </Radio> */}
          </RadioGroupField>
          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Innlandet", filtersLocationData.Innlandet)
              }
              value=""
              width={"100%"}
              checked={filtersLocationData.Innlandet}
              readOnly={true}
            />

            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Innlandet
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData(
                  "Møre og Romsdal",
                  filtersLocationData["Møre og Romsdal"]
                )
              }
              value=""
              width={"100%"}
              checked={filtersLocationData["Møre og Romsdal"]}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Møre og Romsdal
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Nordland", filtersLocationData.Nordland)
              }
              value=""
              width={"100%"}
              checked={filtersLocationData.Nordland}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Nordland
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() => handleFilterData("Oslo", filtersLocationData.Oslo)}
              value=""
              width={"100%"}
              checked={filtersLocationData.Oslo}
              readOnly={true}
            />

            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Oslo
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Rogaland", filtersLocationData.Rogaland)
              }
              value=""
              width={"100%"}
              checked={filtersLocationData.Rogaland}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Rogaland
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData(
                  "Vestfold og Telemark",
                  filtersLocationData["Vestfold og Telemark"]
                )
              }
              value=""
              width={"100%"}
              checked={filtersLocationData["Vestfold og Telemark"]}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Vestfold og Telemark
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData(
                  "Troms og Finnmark",
                  filtersLocationData["Troms og Finnmark"]
                )
              }
              value=""
              width={"100%"}
              checked={filtersLocationData["Troms og Finnmark"]}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Troms og Finnmark
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Trøndelag", filtersLocationData["Trøndelag"])
              }
              value=""
              width={"100%"}
              checked={filtersLocationData.Trøndelag}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Trøndelag
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Vestland", filtersLocationData.Vestland)
              }
              value=""
              width={"100%"}
              checked={filtersLocationData.Vestland}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Vestland
            </Text>
          </RadioGroupField>

          <RadioGroupField
            marginBottom={"53px"}
            marginTop={"14px"}
            width="100%"
            height={"48px"}
            backgroundColor={"#F6F5F4"}
            borderRadius="4px"
            justifyContent={"flex-start"}
            direction="row"
            alignItems="center"
          >
            <CheckboxField
              onClick={() =>
                handleFilterData("Viken", filtersLocationData.Viken)
              }
              value={null}
              width={"100%"}
              checked={filtersLocationData.Viken}
              readOnly={true}
            />
            <Text marginLeft={"5px"} fontSize="16px" color="#5B8386">
              Viken
            </Text>
          </RadioGroupField>
        </View>
      </Layout>
    </>
  );
}
