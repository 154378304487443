import React, { useEffect, useState } from "react";
import { Text, View, RadioGroupField, Radio } from "@aws-amplify/ui-react";
import { Layout } from "../../components/Layout";
import { TopNavigationBar } from "../../components/UI/TopNavigationBar";
import { useNavigate } from "react-router-dom";

import { Icons } from "../../assets/icons";
import { SelectFieldButton } from "../../components/UI/SelectFieldButton";
import { GalleryPicture } from "../../components/UI/GalleryPicture";
import { guidsData } from "./GuidsData";
import { BottomNavigationBar } from "../../components/UI/BottomNavigationBar";

export function PersonalGuidance() {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(0);
  const [guidName, setGuidName] = useState("");
  const [filteredGuid, setFilteredGuid] = useState(guidsData);
  const [expertiseCategories, setExpertiseCategories] = useState(false);

  const handleNavigation = (e) => {
    setScroll(e.currentTarget.scrollY);
  };

  function handleScrollUp() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  useEffect(() => {
    setScroll(window.scrollY);

    window.addEventListener("scroll", (e) => handleNavigation(e));

    handleScrollUp();

    setGuidName("Alle veiledere");
  }, []);

  useEffect(() => {
    handleScrollUp();
  }, [expertiseCategories]);

  function handleFilterCategory(categoryName) {
    setGuidName(categoryName);

    if (!categoryName || categoryName === "Alle veiledere") {
      return setFilteredGuid(guidsData);
    }
    const filteredData = guidsData.filter(
      (data) => data && data.title.includes(categoryName)
    );

    setFilteredGuid(filteredData);
    setExpertiseCategories(false);
  }

  return (
    <View>
      {!expertiseCategories ? (
        <>
          <TopNavigationBar
            noIconBack
            header={"Personlig veiledning"}
            isMedforeldreMenuPages
            onArrowBackClick={() => navigate("/medforeldre")}
          />
          <Layout style={{ marginBottom: "100px" }}>
            <Text
              textAlign={"center"}
              paddingTop="112px"
              marginBottom={"24px"}
              fontSize={"20px"}
              fontWeight="500"
              color="#4D6F6D"
            >
              Våre veiledere og rådgivere
            </Text>
            <View
              onClick={() => setExpertiseCategories(true)}
              width="100%"
              marginBottom="56px"
            >
              <SelectFieldButton
                error={""}
                label={"Velg fagområde"}
                placeholder={"Alle veiledere"}
                value={
                  filteredGuid.length > 0 && guidName !== "Alle veiledere"
                    ? filteredGuid[0].title
                    : "Alle veiledere"
                }
                type={"personalGuidance"}
              />
            </View>
            <View marginBottom="110px">
              {filteredGuid.length > 0 &&
                filteredGuid.map((data) => {
                  return (
                    <View key={data.id}>
                      <Text
                        textAlign={"center"}
                        color="#6B9A9E"
                        fontSize={"16px"}
                        marginBottom="16px"
                      >
                        {data ? data.title : ""}
                      </Text>
                      <View
                        padding="0 16px 0 16px"
                        display="flex"
                        direction="row"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <View
                          display="flex"
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <GalleryPicture
                            isPersonalGuidancePage
                            pictureData={data ? data.imageUrl : ""}
                          />

                          <Text
                            marginLeft="16px"
                            fontSize={"16px"}
                            color="#000000"
                          >
                            {data ? data.name : ""}
                          </Text>
                        </View>

                        <View
                          onClick={() =>
                            navigate("/kontakt-radgiver", { state: data })
                          }
                          width="40px"
                          height="40px"
                          backgroundColor="#E0EAEB"
                          borderRadius="8px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img src={Icons.mail} alt="email" />
                        </View>
                      </View>

                      <Text
                        padding="0 16px 0 16px"
                        color="#5C7578"
                        fontSize={"14px"}
                        textAlign="left"
                        marginTop="16px"
                        marginBottom="100px"
                      >
                        {data ? data.paragraph : ""}
                      </Text>
                      <View
                        width="100%"
                        height="1px"
                        backgroundColor="#9CBCBF"
                        marginBottom="40px"
                      />
                    </View>
                  );
                })}
            </View>

            {scroll > 400 && (
              <View
                onClick={() => handleScrollUp()}
                width="40px"
                height="40px"
                backgroundColor="#E0EAEB"
                borderRadius="8px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                position="fixed"
                bottom={"80px"}
                right={"16px"}
              >
                <img src={Icons.arrowUp} alt="arrow-up" />
              </View>
            )}
          </Layout>
        </>
      ) : (
        <>
          <Layout>
            <TopNavigationBar
              header={"Velg fagområde"}
              isMedforeldreMenuPages
              noIconBack
              onArrowBackClick={() => setExpertiseCategories(false)}
            />
            <View width="100%" marginTop="112px">
              <RadioGroupField
                marginTop={"40px"}
                width="100%"
                height={"48px"}
                backgroundColor={"#F6F5F4"}
                borderRadius="4px"
                justifyContent={"flex-start"}
                direction="row"
                alignItems="center"
              >
                <Radio
                  readOnly={true}
                  checked={guidName === "Alle veiledere" ? true : false}
                  onClick={() => handleFilterCategory("Alle veiledere")}
                  value={""}
                  width={"100%"}
                >
                  <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                    Alle veiledere
                  </Text>
                </Radio>
              </RadioGroupField>
              <RadioGroupField
                marginTop={"24px"}
                width="100%"
                height={"48px"}
                backgroundColor={"#F6F5F4"}
                borderRadius="4px"
                justifyContent={"flex-start"}
                direction="row"
                alignItems="center"
              >
                <Radio
                  readOnly={true}
                  checked={guidName === "Medforeldre-guide" ? true : false}
                  onClick={() => handleFilterCategory("Medforeldre-guide")}
                  value=""
                  width={"100%"}
                >
                  <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                    Medforeldre-guide
                  </Text>
                </Radio>
              </RadioGroupField>

              <RadioGroupField
                marginTop={"24px"}
                width="100%"
                height={"48px"}
                backgroundColor={"#F6F5F4"}
                borderRadius="4px"
                justifyContent={"flex-start"}
                direction="row"
                alignItems="center"
              >
                <Radio
                  readOnly={true}
                  checked={guidName === "Familieterapeut" ? true : false}
                  onClick={() => handleFilterCategory("Familieterapeut")}
                  value=""
                  width={"100%"}
                >
                  <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                    Familieterapeut
                  </Text>
                </Radio>
              </RadioGroupField>

              <RadioGroupField
                marginTop={"24px"}
                width="100%"
                height={"48px"}
                backgroundColor={"#F6F5F4"}
                borderRadius="4px"
                justifyContent={"flex-start"}
                direction="row"
                alignItems="center"
              >
                <Radio
                  readOnly={true}
                  checked={guidName === "Psykolog" ? true : false}
                  onClick={() => handleFilterCategory("Psykolog")}
                  value=""
                  width={"100%"}
                >
                  <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                    Psykolog
                  </Text>
                </Radio>
              </RadioGroupField>

              <RadioGroupField
                marginTop={"24px"}
                width="100%"
                height={"48px"}
                backgroundColor={"#F6F5F4"}
                borderRadius="4px"
                justifyContent={"flex-start"}
                direction="row"
                alignItems="center"
              >
                <Radio
                  readOnly={true}
                  checked={guidName === "Advokat" ? true : false}
                  onClick={() => handleFilterCategory("Advokat")}
                  value=""
                  width={"100%"}
                >
                  <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                    Advokat
                  </Text>
                </Radio>
              </RadioGroupField>

              <RadioGroupField
                marginTop={"24px"}
                width="100%"
                height={"48px"}
                backgroundColor={"#F6F5F4"}
                borderRadius="4px"
                justifyContent={"flex-start"}
                direction="row"
                alignItems="center"
              >
                <Radio
                  readOnly={true}
                  checked={guidName === "Fertilitetsrådgiver" ? true : false}
                  onClick={() => handleFilterCategory("Fertilitetsrådgiver")}
                  value=""
                  width={"100%"}
                >
                  <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                    Fertilitetsrådgiver
                  </Text>
                </Radio>
              </RadioGroupField>

              <RadioGroupField
                marginTop={"24px"}
                width="100%"
                height={"48px"}
                backgroundColor={"#F6F5F4"}
                borderRadius="4px"
                justifyContent={"flex-start"}
                direction="row"
                alignItems="center"
              >
                <Radio
                  readOnly={true}
                  checked={guidName === "Etablerte medforeldre" ? true : false}
                  onClick={() => handleFilterCategory("Etablerte medforeldre")}
                  value=""
                  width={"100%"}
                >
                  <Text marginLeft={"5px"} fontSize="16px" color="#43898E">
                    Etablerte medforeldre
                  </Text>
                </Radio>
              </RadioGroupField>
            </View>
          </Layout>
        </>
      )}
      <BottomNavigationBar buttonState="active" type={2} />
    </View>
  );
}
