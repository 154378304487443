/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Icon, View } from "@aws-amplify/ui-react";
export default function AppIconSimple(props) {
  const { overrides, ...rest } = props;
  return (
    <View
      width="46px"
      height="46px"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "AppIconSimple")}
      {...rest}
    >
      <Icon
        width="0.22px"
        height="0.01px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.215087890625,
          height: 0.008056640625,
        }}
        paths={[
          {
            d: "M0.121704 0C0.0811364 0 0.040568 0.0040568 0 0.00811359C0.0730223 0.00811359 0.141988 0.00811359 0.21501 0.00811359C0.182556 0.00811359 0.154159 0.0040568 0.121704 0.0040568L0.121704 0Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0%"
        bottom="99.98%"
        left="18.48%"
        right="81.05%"
        {...getOverrideProps(overrides, "Vector472058964")}
      ></Icon>
      <Icon
        width="4.54px"
        height="1.68px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 4.535400390625,
          height: 1.6795654296875,
        }}
        paths={[
          {
            d: "M0 1.67951C1.31846 0.713996 2.89655 0.125761 4.5355 0C2.84381 0.11359 1.29006 0.72211 0 1.67951Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0.06%"
        bottom="96.29%"
        left="7.62%"
        right="82.52%"
        {...getOverrideProps(overrides, "Vector472058966")}
      ></Icon>
      <Icon
        width="0.02px"
        height="0.2px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.0244140625,
          height: 0.19873046875,
        }}
        paths={[
          {
            d: "M0 0.198784C0.0040568 0.133875 0.0162272 0.0689655 0.0243408 0C0.0162272 0.0649087 0.00811359 0.129818 0 0.198784Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="16.58%"
        bottom="82.99%"
        left="0.09%"
        right="99.86%"
        {...getOverrideProps(overrides, "Vector472058967")}
      ></Icon>
      <Icon
        width="0.01px"
        height="0.05px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.008056640625,
          height: 0.052734375,
        }}
        paths={[
          {
            d: "M0 0.052738C0 0.052738 0.00811359 0.0162272 0.00811359 0C0.00811359 0.0162272 0 0.0365108 0 0.052738Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="14.95%"
        bottom="84.94%"
        left="0.37%"
        right="99.61%"
        {...getOverrideProps(overrides, "Vector472058968")}
      ></Icon>
      <Icon
        width="0.32px"
        height="0.26px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.324462890625,
          height: 0.2596435546875,
        }}
        paths={[
          {
            d: "M0 0.259635C0.105477 0.170386 0.21501 0.0851927 0.324544 0C0.21501 0.0851927 0.105477 0.170386 0 0.259635L0 0.259635Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="3.92%"
        bottom="95.51%"
        left="6.62%"
        right="92.67%"
        {...getOverrideProps(overrides, "Vector472058969")}
      ></Icon>
      <Icon
        width="0.32px"
        height="0.26px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.324462890625,
          height: 0.2596435546875,
        }}
        paths={[
          {
            d: "M0 0C0.109533 0.0851927 0.219068 0.170386 0.324545 0.259635C0.219068 0.170386 0.109533 0.0851927 0 0Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="3.92%"
        bottom="95.51%"
        left="92.66%"
        right="6.63%"
        {...getOverrideProps(overrides, "Vector472058970")}
      ></Icon>
      <Icon
        width="0.22px"
        height="0.01px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.215087890625,
          height: 0.008056640625,
        }}
        paths={[
          {
            d: "M0.0933046 0.0040568C0.0608502 0.0040568 0.0324544 0.00811359 0 0.00811359C0.0730223 0.00811359 0.141987 0.00811359 0.21501 0.00811359C0.174442 0.00811359 0.133872 0 0.0933046 0L0.0933046 0.0040568Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="-0.01%"
        bottom="99.99%"
        left="81.05%"
        right="18.48%"
        {...getOverrideProps(overrides, "Vector472058971")}
      ></Icon>
      <Icon
        width="0.01px"
        height="0.05px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.008056640625,
          height: 0.052734375,
        }}
        paths={[
          {
            d: "M0 0C0 0 0.00811223 0.0365108 0.00811223 0.052738C0.00811223 0.0365108 0 0.0162272 0 0Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="14.95%"
        bottom="84.94%"
        left="99.6%"
        right="0.38%"
        {...getOverrideProps(overrides, "Vector472058972")}
      ></Icon>
      <Icon
        width="4.54px"
        height="1.68px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 4.535400390625,
          height: 1.6795654296875,
        }}
        paths={[
          {
            d: "M0 0C1.63895 0.125761 3.21704 0.713996 4.5355 1.67951C3.24949 0.72211 1.69168 0.117647 0 0L0 0Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0.06%"
        bottom="96.29%"
        left="82.52%"
        right="7.62%"
        {...getOverrideProps(overrides, "Vector472058973")}
      ></Icon>
      <Icon
        width="0.02px"
        height="0.2px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.0244140625,
          height: 0.19873046875,
        }}
        paths={[
          {
            d: "M0 0C0.00811359 0.0649087 0.0162262 0.129818 0.0243398 0.198784C0.0162262 0.133875 0.00811359 0.0689655 0 0Z",
            fill: "rgba(255,255,255,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="16.58%"
        bottom="82.99%"
        left="99.86%"
        right="0.09%"
        {...getOverrideProps(overrides, "Vector472058974")}
      ></Icon>
      <Icon
        width="0.13px"
        height="0.1px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.1298828125,
          height: 0.097412109375,
        }}
        paths={[
          {
            d: "M0 0.097363C0.0446247 0.0649086 0.085193 0.0324544 0.129818 0C0.085193 0.0324544 0.040568 0.0649086 0 0.097363Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="3.71%"
        bottom="96.08%"
        left="7.34%"
        right="92.38%"
        {...getOverrideProps(overrides, "Vector472058977")}
      ></Icon>
      <Icon
        width="0.46px"
        height="0.02px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.46240234375,
          height: 0.0242919921875,
        }}
        paths={[
          {
            d: "M0 0.0243408C0.154158 0.0121704 0.308316 0.0040568 0.462474 0C0.308316 0 0.154158 0.0121704 0 0.0243408Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0.01%"
        bottom="99.94%"
        left="17.48%"
        right="81.52%"
        {...getOverrideProps(overrides, "Vector472058978")}
      ></Icon>
      <Icon
        width="0.11px"
        height="0.7px"
        viewBox={{ minX: 0, minY: 0, width: 0.10546875, height: 0.69775390625 }}
        paths={[
          {
            d: "M0 0.697769C0.0283976 0.462474 0.0608519 0.227181 0.105477 0C0.0608519 0.227181 0.0243408 0.462474 0 0.697769L0 0.697769Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="15.06%"
        bottom="83.42%"
        left="0.14%"
        right="99.63%"
        {...getOverrideProps(overrides, "Vector472058979")}
      ></Icon>
      <Icon
        width="0.04px"
        height="0.8px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.04052734375,
          height: 0.7991943359375,
        }}
        paths={[
          {
            d: "M0 0.799188C0 0.53144 0.0162272 0.263692 0.040568 0C0.0162272 0.263692 0 0.527383 0 0.799188Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="17%"
        bottom="81.26%"
        left="0%"
        right="99.91%"
        {...getOverrideProps(overrides, "Vector472058980")}
      ></Icon>
      <Icon
        width="2.87px"
        height="4.81px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 2.8681640625,
          height: 4.8114013671875,
        }}
        paths={[
          {
            d: "M2.8641 0C1.44828 1.18864 0.397566 2.83976 0 4.81136C0.397566 2.90467 1.43205 1.22515 2.86815 0.00405669L2.8641 0Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="4.49%"
        bottom="85.05%"
        left="0.4%"
        right="93.37%"
        {...getOverrideProps(overrides, "Vector472058981")}
      ></Icon>
      <Icon
        width="0.46px"
        height="0.02px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.46240234375,
          height: 0.0242919921875,
        }}
        paths={[
          {
            d: "M0 0C0.154158 0 0.308316 0.0121704 0.462474 0.0243408C0.308316 0.0121704 0.154158 0.0040568 0 0L0 0Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="0.02%"
        bottom="99.93%"
        left="81.52%"
        right="17.48%"
        {...getOverrideProps(overrides, "Vector472058982")}
      ></Icon>
      <Icon
        width="0.04px"
        height="0.8px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.04052734375,
          height: 0.7991943359375,
        }}
        paths={[
          {
            d: "M0.0405673 0.799188C0.0405673 0.527383 0.0243408 0.263692 0 0C0.0243408 0.263692 0.0405673 0.53144 0.0405673 0.799188Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="17%"
        bottom="81.26%"
        left="99.91%"
        right="0%"
        {...getOverrideProps(overrides, "Vector472058983")}
      ></Icon>
      <Icon
        width="0.13px"
        height="0.1px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 0.1298828125,
          height: 0.097412109375,
        }}
        paths={[
          {
            d: "M0 0C0.0446247 0.0324544 0.0892494 0.0649086 0.129817 0.097363C0.0851926 0.0649086 0.0446247 0.0324544 0 0Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="3.71%"
        bottom="96.08%"
        left="92.38%"
        right="7.34%"
        {...getOverrideProps(overrides, "Vector472058984")}
      ></Icon>
      <Icon
        width="2.87px"
        height="4.81px"
        viewBox={{
          minX: 0,
          minY: 0,
          width: 2.8681640625,
          height: 4.8072509765625,
        }}
        paths={[
          {
            d: "M2.86815 4.8073C2.46653 2.8357 1.41988 1.18864 0 0C1.43611 1.2211 2.47059 2.90061 2.86815 4.8073Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="4.5%"
        bottom="85.05%"
        left="93.37%"
        right="0.4%"
        {...getOverrideProps(overrides, "Vector472058985")}
      ></Icon>
      <Icon
        width="0.11px"
        height="0.7px"
        viewBox={{ minX: 0, minY: 0, width: 0.10546875, height: 0.69775390625 }}
        paths={[
          {
            d: "M0 0C0.0446247 0.231237 0.0811368 0.462474 0.105478 0.697769C0.07708 0.462474 0.0446247 0.227181 0 0Z",
            fill: "rgba(255,136,136,1)",
            fillRule: "nonzero",
          },
        ]}
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        position="absolute"
        top="15.06%"
        bottom="83.42%"
        left="99.63%"
        right="0.14%"
        {...getOverrideProps(overrides, "Vector472058986")}
      ></Icon>
      <View
        width="46px"
        height="46px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        position="absolute"
        top="0%"
        bottom="0%"
        left="0%"
        right="0%"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame")}
      >
        <View
          padding="0px 0px 0px 0px"
          width="46.01px"
          height="46px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          position="absolute"
          top="0%"
          bottom="0%"
          left="0%"
          right="-0.02%"
          {...getOverrideProps(overrides, "Group 58")}
        >
          <Icon
            width="17.35px"
            height="37.35px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 17.35009765625,
              height: 37.3499755859375,
            }}
            paths={[
              {
                d: "M14.84 6.12L0.110001 20.9L0 21.01L0 28.7C0 33.46 3.86 37.35 8.58 37.35L8.77 37.35C13.49 37.35 17.35 33.46 17.35 28.7L17.35 0C17.35 2.21 16.51 4.43 14.82 6.12L14.84 6.12Z",
                fill: "rgba(137,166,169,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="18.8%"
            bottom="0%"
            left="62.23%"
            right="0.07%"
            {...getOverrideProps(overrides, "Vector489159973")}
          ></Icon>
          <Icon
            width="17.37px"
            height="29.66px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 17.3701171875,
              height: 29.6600341796875,
            }}
            paths={[
              {
                d: "M17.36 8.65C17.36 3.89 13.5 0 8.78 0L8.59 0C5.85 0 3.4 1.31 1.83 3.34C0.69 4.81 0 6.65 0 8.65L0 29.66L0.110001 29.55L14.84 14.77C16.52 13.08 17.37 10.87 17.37 8.65L17.36 8.65Z",
                fill: "rgba(71,42,60,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="35.52%"
            left="62.25%"
            right="0%"
            {...getOverrideProps(overrides, "Vector489159974")}
          ></Icon>
          <Icon
            width="17.35px"
            height="37.35px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 17.35009765625,
              height: 37.3499755859375,
            }}
            paths={[
              {
                d: "M2.51 6.12L17.24 20.9L17.35 21.01L17.35 28.7C17.35 33.46 13.49 37.35 8.77 37.35L8.58 37.35C3.86 37.35 0 33.46 0 28.7L0 0C0 2.21 0.84 4.43 2.53 6.12L2.51 6.12Z",
                fill: "rgba(137,166,169,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="18.8%"
            bottom="0%"
            left="0.04%"
            right="62.25%"
            {...getOverrideProps(overrides, "Vector489159975")}
          ></Icon>
          <Icon
            width="14.92px"
            height="31.95px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 14.919921875,
              height: 31.949951171875,
            }}
            paths={[
              {
                d: "M7.47 31.95L1.83 26.32L1.83 5.31C1.83 3.31 1.14 1.47 0 0C2.02 2.23 4.7 3.35 7.46 3.35C10.22 3.35 12.9 2.23 14.92 0C13.78 1.47 13.09 3.32 13.09 5.32L13.09 26.32L7.45 31.95L7.47 31.95Z",
                fill: "rgba(243,129,131,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="7.26%"
            bottom="23.28%"
            left="33.75%"
            right="33.82%"
            {...getOverrideProps(overrides, "Vector489159976")}
          ></Icon>
          <Icon
            width="17.35px"
            height="29.66px"
            viewBox={{
              minX: 0,
              minY: 0,
              width: 17.35009765625,
              height: 29.6600341796875,
            }}
            paths={[
              {
                d: "M0 8.65C0 3.89 3.86 0 8.58 0L8.77 0C11.51 0 13.95 1.31 15.52 3.34C16.66 4.81 17.35 6.65 17.35 8.65L17.35 29.66L17.24 29.55L2.53 14.77C0.84 13.08 0 10.87 0 8.65Z",
                fill: "rgba(71,42,60,1)",
                fillRule: "nonzero",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0%"
            bottom="35.52%"
            left="0%"
            right="62.29%"
            {...getOverrideProps(overrides, "Vector489159977")}
          ></Icon>
        </View>
      </View>
    </View>
  );
}
